













import * as d3 from 'd3';
import Vue, { PropType } from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';

// グラフに必要な配列の中の要素
type d3Item = {
  key: string;
  value: { count: number; day: number; indexNum: number; time: number }[];
};

type d3ItemValue = {
  count: number;
  day: number;
  indexNum: number;
  time: number;
};

export default Vue.extend({
  name: 'DashboardVoiceXBarAndLineGraph',
  props: {
    beforeFlg: {
      type: Boolean,
    },
    chartData: {
      type: Array,
      default: () => [],
    },
    graphWidth: {
      type: Number,
      default: undefined,
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    // x1Data: {
    //   type: Array as PropType<string[]>,
    //   default: () => [],
    // },
  },
  data(): {
    barMaxHeight: number;
    colorBlue: string;
    colorBlue2: string;
    colorGrey1: string;
    colorGrey2: string;
    colorGrey3: string;
    colorGrey4: string;
    colorPink: string;
    colorPink2: string;
    svg: any;
    svgLegend: any;
    xAxis: any;
    y1Axis: any;
    y2Axis: any;
    y1Label: string;
    y2Label: string;
    width: number;
    height: number;
    y2LabelCheck: boolean;
    svgLine: any;
    svgLine2: any;
    svgDot1: any;
    svgDot2: any;
    voiceXChartData: d3Item[][];
    // x1DataArray: any[];
    svgG: any;
    svgGG: any;
    svgPath: any;
    svgPathBefore: any;
    xScale: any;
    y1Scale: any;
    y2Scale: any;
    y1AxisEl: any;
    y2AxisEl: any;
    xAxisEl: any;
    beforePeriodFlg: boolean;
    d3MaxY1Length: number;
    d3MaxY2Length: number;
    xLabel: string;
    xDateClickFlg: boolean;
    hoverCountBefore: number;
    hoverCountCurrent: number;
  } {
    return {
      barMaxHeight: 0,
      beforePeriodFlg: false,
      colorBlue: '#669BFF',
      colorBlue2: '#B1CBE1',
      colorGrey1: '#AAAAAA',
      colorGrey2: '#CCCCCC',
      colorGrey3: '#EAEAEA',
      colorGrey4: '#e6e6e6',
      colorPink: '#FF72B8',
      colorPink2: '#FFE4E1',
      d3MaxY1Length: 0,
      d3MaxY2Length: 0,
      height: 350,
      hoverCountBefore: 0,
      hoverCountCurrent: 0,
      svg: null,
      svgDot1: null,
      svgDot2: null,
      svgG: null,
      svgGG: null,
      svgLegend: null,
      svgLine: null,
      svgLine2: null,
      svgPath: null,
      svgPathBefore: null,
      voiceXChartData: [],
      width: 1810,
      // x1DataArray: [],
      xAxis: null,
      xAxisEl: null,
      xDateClickFlg: false,
      xLabel: '',
      xScale: null,
      y1Axis: null,
      y1AxisEl: null,
      y1Label: '(分)',
      y1Scale: null,
      y2Axis: null,
      y2AxisEl: null,
      y2Label: '(件)',
      y2LabelCheck: false,
      y2Scale: null,
    };
  },
  computed: {},
  watch: {
    beforeFlg(newValue) {
      this.beforePeriodFlg = newValue;
      // this.firstChart(1323);
      this.firstChart(1810);
    },
    chartData: {
      handler(newValue) {
        if (newValue) {
          this.voiceXChartData = newValue;
          // this.firstChart(1323);
          this.firstChart(1810);
        }
      },
      deep: true,
    },
    graphWidth(newValue) {
      if (newValue) {
        this.width = newValue;
      }
    },
    selectedPeriod(newValue) {
      if (newValue) {
        // this.firstChart(1323);
      }
    },
  },
  mounted() {
    // this.firstChart(this.graphWidth);
    // this.firstChart(1323);
    this.svg = d3
      .select('.eee')
      .append('svg')
      .attr('id', 'svg-graph')
      // .attr('viewBox', `0 0 ${1323} ${this.height}`);
      .attr('viewBox', `0 0 ${1810} ${this.height}`);
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    bar2(x: number, y: number, w: number, h: number, r: number, f: any) {
      // console.log('bar2 x', x);
      // console.log('bar2 y', y);
      // console.log('bar2 w', w);
      // console.log('bar2 h', h);
      // console.log('bar2 r', r);
      // Flag for sweep:
      let f1: number;
      if (f === undefined) {
        f1 = 1;
      } else {
        f1 = f;
      }
      // x coordinates of top of arcs
      const x0 = x + r;
      const x1 = x + w - r;
      // y coordinates of bottom of arcs
      const y0 = y - h + r;
      // just for convenience (slightly different than above):
      const l = 'L';
      const a = 'A';
      let parts: (string | number)[];
      if (h > 0) {
        parts = [
          'M',
          x,
          y,
          l,
          x,
          y0,
          a,
          r,
          r,
          0,
          0,
          f1,
          x0,
          y - h,
          l,
          x1,
          y - h,
          a,
          r,
          r,
          0,
          0,
          f1,
          x + w,
          y0,
          l,
          x + w,
          y,
          'Z',
        ];
      } else {
        const newx0 = x + w;
        const newy0 = y - h;
        parts = ['M', x, y, l, x, newy0, l, newx0, y - h, l, x + w, y, 'Z'];
      }
      return parts.join(' ');
    },
    firstChart(width: number) {
      const self = this;
      // const width;
      if (self.voiceXChartData.length > 0) {
        if (self.svg) {
          d3.select('#svg-graph').remove();
          d3.select('#bar-and-line-chart-legend').remove();
        }
        self.svg = d3
          .select('.eee')
          .append('svg')
          .attr('id', 'svg-graph')
          .attr('viewBox', `0 0 ${width} ${self.height}`);
        // .attr('viewBox', `0 0 ${2000} ${self.height}`);
        if (self.beforePeriodFlg === false) {
          // legendを追加
          self.svgLegend = d3
            .select('#bar-and-line-legend')
            .append('svg')
            .attr('id', 'bar-and-line-chart-legend')
            .style('padding-left', 0)
            .attr('viewBox', `0 0 ${1800} ${20}`);
          // .attr('viewBox', `0 0 ${2000} ${20}`);
          let period: string;
          if (self.selectedPeriod === 'day') {
            period = '日';
          } else if (self.selectedPeriod === 'week') {
            period = '週';
          } else if (self.selectedPeriod === 'month') {
            period = '月';
          } else {
            period = '年';
          }
          const legendVals = [`今${period}の応答件数`, `今${period}の通話時間`];
          // legend設置
          const legend = self.svgLegend
            .selectAll('legend')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function fn(_: any, i: number) {
              return `bar-and-line-legend-${i}`;
            });
          d3.select('.bar-and-line-legend-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorPink);
          d3.select('.bar-and-line-legend-0')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            .attr('fill', self.colorPink);
          d3.select('.bar-and-line-legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorBlue);
          legend
            .append('text')
            .attr('x', 55)
            .attr('y', 13)
            .attr('dy', '.15em')
            .text(function a(d: string) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15);
          const padding = 25;
          legend.attr('transform', function a(_: null, i: number) {
            return `translate(${
              d3.sum(legendVals, function b(e: string, j: number) {
                if (j < i) {
                  return legend.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          const data = this.voiceXChartData[0];
          self.svgG = self.svg.append('g').attr('transform', `translate(${0}, ${0})`);
          const x0Domain: string[] = data.map(function a(d: d3Item) {
            return d.value[0].indexNum.toString();
          });
          self.xScale = d3.scaleBand().domain(x0Domain);
          let d3MaxY1: number | undefined = d3.max(data, function a(d: d3Item): number | undefined {
            return d3.max(d.value, function b(d1: d3ItemValue): number | undefined {
              return d1.time;
            });
          });
          if (d3MaxY1 !== undefined) {
            d3MaxY1 /= 1000;
            if (d3MaxY1 < 60) {
              self.y1Label = '(秒)';
            } else if (d3MaxY1 >= 60 && d3MaxY1 < 3600) {
              self.y1Label = '(分)';
              const callRem = Math.floor(d3MaxY1 % 60) / 60;
              d3MaxY1 = Math.floor((d3MaxY1 % 3600) / 60) + callRem;
            } else if (d3MaxY1 >= 3600) {
              self.y1Label = '(時間)';
              const callRem = Math.floor(d3MaxY1 % 60) / 60 / 360;
              const callMin = Math.floor((d3MaxY1 % 3600) / 60) / 60;
              d3MaxY1 = Math.floor(d3MaxY1 / 3600) + callMin + callRem;
            }
            // グラフの左側の軸の幅を取得
            d3MaxY1 = Math.ceil(d3MaxY1);
            if (d3MaxY1 === 0) {
              d3MaxY1 = 1;
            }
            const d3MaxY1Length = d3MaxY1.toString().length === 1 ? 2 : d3MaxY1.toString().length;
            self.d3MaxY1Length = d3MaxY1Length * 8 + 30;
            self.y1Scale = d3
              .scaleLinear()
              .range([self.height - 35, 20])
              .domain([0, d3MaxY1]);
          }
          let d3MaxY2: number | undefined = d3.max(data, function a(d: d3Item): number | undefined {
            return d3.max(d.value, function b(d1: d3ItemValue): number | undefined {
              return d1.count;
            });
          });
          if (d3MaxY2 !== undefined) {
            if (d3MaxY2 < 10) {
              d3MaxY2 = 10;
            }
            // グラフの右側の軸の幅を取得
            self.d3MaxY2Length = d3MaxY2.toString().length * 8 + 25;
            // X軸の左側、と長さを設定
            self.xScale.range([self.d3MaxY1Length, self.width - 100]);
            self.y2Scale = d3
              .scaleLinear()
              .range([self.height - 35, 20])
              .domain([0, d3MaxY2]);
          }
          self.xAxis = d3.axisBottom(self.xScale);
          self.y1Axis = d3
            .axisLeft(self.y1Scale)
            .ticks(5)
            .tickSize(-width + 18 + self.d3MaxY1Length + self.d3MaxY2Length); /* 横線の長さ変更 */
          self.y2Axis = d3.axisRight(self.y2Scale).ticks(5).tickSize(0);
          self.y1AxisEl = self.svgG.append('g').attr('class', 'y1 axis').call(self.y1Axis);
          // y1AxisEl transform
          self.y1AxisEl.attr('transform', `translate(${self.d3MaxY1Length}, 10)`);
          // append text
          self.y1AxisEl
            .append('text')
            .attr('x', -25)
            .attr('y', 5)
            .attr('fill', self.colorGrey1)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // 横線少しずらす
          d3.select('.y1.axis')
            .selectAll('.tick')
            .select('line')
            .attr('transform', 'translate(10,0)');
          self.y2AxisEl = self.svgG.append('g').attr('class', 'y2 axis').call(self.y2Axis);
          self.xAxisEl = self.svgG
            .append('g')
            .attr('class', 'x axis')
            // 変更点
            .attr('transform', `translate(30,${self.height - 25})`);
          // y1 axis 枠線消す
          d3.select('.y1.axis').select('.domain').remove();
          // y1 axis color change
          d3.select('.y1.axis').selectAll('.tick').select('line').style('stroke', 'grey');
          // y2 axis 枠線消す
          d3.select('.y2.axis').select('.domain').remove();
          self.svgGG = self.svgG.selectAll(null).data(data).enter().append('g');
          self.svgPath = self.svgGG
            .selectAll('path')
            .data(function a(d: d3Item) {
              return d.value;
            })
            .enter()
            .append('path')
            .attr('d', function a(d: d3ItemValue) {
              self.barMaxHeight = self.y1Scale(0);
              // ここの処理微妙（あとできちんと確認する必要あり）
              let second = d.time / 1000;
              if (self.y1Label === '(分)') {
                const callRem = Math.floor(second % 60) / 60;
                second = Math.floor((second % 3600) / 60) + callRem;
              } else if (self.y1Label === '(時間)') {
                const callRem = Math.floor(second % 60) / 60 / 360;
                const callMin = Math.floor((second % 3600) / 60) / 60;
                second = Math.floor(second / 3600) + callMin + callRem;
              }
              const barHeight = self.barMaxHeight - self.y1Scale(second);
              return self.bar2(
                // 変更点
                self.xScale(d.indexNum.toString()) + self.xScale.bandwidth() / 4 + 30,
                self.barMaxHeight + 10,
                self.xScale.bandwidth() / 2,
                barHeight,
                10,
                1
              );
            })
            .attr('fill', function a() {
              return self.colorBlue;
            })
            .attr('class', function a(d: d3ItemValue) {
              return `voicex-bar-chart-current-${d.indexNum} voicex-bar-chart-current`;
            });
          self.svgLine = self.svgG.append('path');
          self.svgDot1 = self.svgG.selectAll('myCircles').data(data).enter().append('circle');
          self.updateChart(width);
        } else {
          /* legend関連 */
          self.svgLegend = d3
            .select('#bar-and-line-legend')
            .append('svg')
            .attr('id', 'bar-and-line-chart-legend')
            .style('padding-left', 0)
            // .attr('viewBox', `0 0 ${1300} ${20}`);
            .attr('viewBox', `0 0 ${1800} ${20}`);
          // .style('padding-left', 30)
          // .attr('width', 800)
          // .attr('height', 20);
          let period: string;
          if (self.selectedPeriod === 'day') {
            period = '日';
          } else if (self.selectedPeriod === 'week') {
            period = '週';
          } else if (self.selectedPeriod === 'month') {
            period = '月';
          } else {
            period = '年';
          }
          const legendVals = [
            `今${period}の応答件数`,
            `今${period}の通話時間`,
            `前${period}の応答件数`,
            `前${period}の通話時間`,
          ];
          /* legend設置 */
          const legend = self.svgLegend
            .selectAll('legend')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function a(_: null, i: number) {
              return `bar-and-line-legend-${i}`;
            });
          /* 現在の通話件数 */
          d3.select('.bar-and-line-legend-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorPink);
          d3.select('.bar-and-line-legend-0')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            .attr('fill', self.colorPink);
          /* 現在の通話時間 */
          d3.select('.bar-and-line-legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorBlue);
          /* 前回の通話件数 */
          d3.select('.bar-and-line-legend-2')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorGrey1);
          d3.select('.bar-and-line-legend-2')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            .attr('fill', self.colorGrey1);
          /* 前回の通話時間 */
          d3.select('.bar-and-line-legend-3')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            .attr('fill', self.colorGrey1);
          legend
            .append('text')
            .attr('x', 55)
            .attr('y', 13)
            .attr('dy', '.15em')
            .text(function a(d: string) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15);
          const padding = 25;
          legend.attr('transform', function a(_: null, i: number) {
            return `translate(${
              d3.sum(legendVals, function b(e: string, j: number) {
                if (j < i) {
                  return legend.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });

          const data = this.voiceXChartData;
          self.svgG = self.svg.append('g').attr('transform', `translate(${0}, ${0})`);
          const x0Domain1: string[] = data[0].map(function a(d: d3Item) {
            return d.value[0].indexNum.toString();
          });
          const x0Domain2: string[] = data[1].map(function a(d: d3Item) {
            return d.value[0].indexNum.toString();
          });
          let x0Domain: string[];
          if (x0Domain1.length > x0Domain2.length) {
            x0Domain = x0Domain1;
          } else {
            x0Domain = x0Domain2;
          }
          self.xScale = d3.scaleBand().domain(x0Domain);
          let d3MaxY1: number | undefined = d3.max(data, function a(d: d3Item[]):
            | number
            | undefined {
            return d3.max(d, function b(d1: d3Item): number | undefined {
              return d3.max(d1.value, function c(d2: d3ItemValue): number {
                return d2.time;
              });
            });
          });
          if (d3MaxY1 !== undefined) {
            d3MaxY1 /= 1000;
            if (d3MaxY1 < 60) {
              self.y1Label = '(秒)';
            } else if (d3MaxY1 >= 60 && d3MaxY1 < 3600) {
              self.y1Label = '(分)';
              const callRem = Math.floor(d3MaxY1 % 60) / 60;
              d3MaxY1 = Math.floor((d3MaxY1 % 3600) / 60) + callRem;
            } else if (d3MaxY1 >= 3600) {
              self.y1Label = '(時間)';
              const callRem = Math.floor(d3MaxY1 % 60) / 60 / 360;
              const callMin = Math.floor((d3MaxY1 % 3600) / 60) / 60;
              d3MaxY1 = Math.floor(d3MaxY1 / 3600) + callMin + callRem;
            }
            d3MaxY1 = Math.ceil(d3MaxY1);
            if (d3MaxY1 === 0) {
              d3MaxY1 = 1;
            }
            const d3MaxY1Length = d3MaxY1.toString().length === 1 ? 2 : d3MaxY1.toString().length;
            self.d3MaxY1Length = d3MaxY1Length * 8 + 30;
            self.y1Scale = d3
              .scaleLinear()
              .range([self.height - 35, 20])
              .domain([0, d3MaxY1]);
          }
          let d3MaxY2: number | undefined = d3.max(data, function a(d: d3Item[]):
            | number
            | undefined {
            return d3.max(d, function b(d1: d3Item): number | undefined {
              return d3.max(d1.value, function c(d2: d3ItemValue): number {
                return d2.count;
              });
            });
          });
          if (d3MaxY2 !== undefined) {
            if (d3MaxY2 < 10) {
              d3MaxY2 = 10;
            }
            self.d3MaxY2Length = d3MaxY2.toString().length * 8 + 25;
            // X軸の左側、と長さを設定
            self.xScale.range([self.d3MaxY1Length, self.width - 100]);
            self.y2Scale = d3
              .scaleLinear()
              .range([self.height - 35, 20])
              .domain([0, d3MaxY2]);
          }
          self.xAxis = d3.axisBottom(self.xScale);
          self.y1Axis = d3
            .axisLeft(self.y1Scale)
            .ticks(5)
            .tickSize(-width + 18 + self.d3MaxY1Length + self.d3MaxY2Length); /* 横線の長さ変更 */
          self.y2Axis = d3.axisRight(self.y2Scale).ticks(5).tickSize(0);
          self.y1AxisEl = self.svgG.append('g').attr('class', 'y1 axis').call(self.y1Axis);
          self.y1AxisEl.attr('transform', `translate(${self.d3MaxY1Length}, 10)`);
          self.y1AxisEl
            .append('text')
            .attr('x', -25)
            .attr('y', 5)
            .attr('fill', self.colorGrey1)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // 横線少しずらす
          d3.select('.y1.axis')
            .selectAll('.tick')
            .select('line')
            .attr('transform', 'translate(10,0)');
          //
          self.y2AxisEl = self.svgG.append('g').attr('class', 'y2 axis').call(self.y2Axis);
          self.xAxisEl = self.svgG
            .append('g')
            .attr('class', 'x axis')
            .attr('transform', `translate(30,${self.height - 25})`);
          const data1 = data[0];
          const data2 = data[1];
          // y1 axis 枠線消す
          d3.select('.y1.axis').select('.domain').remove();
          //
          // y1 axis color change
          d3.select('.y1.axis').selectAll('.tick').select('line').style('stroke', 'grey');
          // y2 axis 枠線消す
          d3.select('.y2.axis').select('.domain').remove();
          self.svgGG = self.svgG.selectAll(null).data(data1).enter().append('g');
          self.svgPath = self.svgGG
            .selectAll('path')
            .data(function a(d: d3Item) {
              return d.value;
            })
            .enter()
            .append('path')
            .attr('d', function a(d: d3ItemValue) {
              self.barMaxHeight = self.y1Scale(0);
              let second = d.time / 1000;
              if (self.y1Label === '(分)') {
                const callRem = Math.floor(second % 60) / 60;
                second = Math.floor((second % 3600) / 60) + callRem;
              } else if (self.y1Label === '(時間)') {
                const callRem = Math.floor(second % 60) / 60 / 360;
                const callMin = Math.floor((second % 3600) / 60) / 60;
                second = Math.floor(second / 3600) + callMin + callRem;
              }
              const barHeight = self.barMaxHeight - self.y1Scale(second);
              return self.bar2(
                // self.xScale(d.indexNum),
                // self.xScale.bandwidth() / 2,
                self.xScale(d.indexNum.toString()) + 5 + 30,
                self.barMaxHeight + 10,
                self.xScale.bandwidth() / 2 - 5,
                barHeight,
                // 10,
                8,
                1
                // 1
              );
            })
            .attr('fill', function a() {
              return self.colorBlue;
            })
            .attr('class', function a(d: d3ItemValue) {
              return `voicex-bar-chart-current-${d.indexNum} voicex-bar-chart-current`;
            });
          self.svgPathBefore = self.svgGG;
          self.svgGG = self.svgG.selectAll(null).data(data2).enter().append('g');
          self.svgPath = self.svgGG
            .selectAll('path')
            .data(function a(d: d3Item) {
              return d.value;
            })
            .enter()
            .append('path')
            .attr('d', function a(d: d3ItemValue) {
              self.barMaxHeight = self.y1Scale(0);
              // ここの処理微妙（あとできちんと確認する必要あり）
              let second = d.time / 1000;
              if (self.y1Label === '(分)') {
                const callRem = Math.floor(second % 60) / 60;
                second = Math.floor((second % 3600) / 60) + callRem;
              } else if (self.y1Label === '(時間)') {
                const callRem = Math.floor(second % 60) / 60 / 360;
                const callMin = Math.floor((second % 3600) / 60) / 60;
                second = Math.floor(second / 3600) + callMin + callRem;
              }
              const barHeight = self.barMaxHeight - self.y1Scale(second);
              return self.bar2(
                self.xScale(d.indexNum.toString()) + self.xScale.bandwidth() / 2 + 30,
                self.barMaxHeight + 10,
                self.xScale.bandwidth() / 2 - 5,
                barHeight,
                // 10,
                8,
                1
              );
            })
            .attr('fill', function a() {
              // return 'grey';
              return self.colorGrey2;
            })
            .attr('class', function a(d: d3ItemValue) {
              return `voicex-bar-chart-before-${d.indexNum} voicex-bar-chart-before`;
            });
          self.svgLine = self.svgG.append('path');
          self.svgLine2 = self.svgG.append('path');
          self.svgDot1 = self.svgG.selectAll('myCircles').data(data1).enter().append('circle');
          self.svgDot2 = self.svgG.selectAll('myCircles').data(data2).enter().append('circle');
          self.updateChart(self.width);
        }
      }
    },
    updateChart(width: number) {
      const self = this;
      let longerDataNum = 0;
      if (self.beforePeriodFlg === false) {
        longerDataNum = 0;
      } else {
        const array = this.voiceXChartData;
        const arrayA = array[0];
        const arrayB = array[1];
        if (arrayA.length > arrayB.length) {
          longerDataNum = 0;
        } else {
          longerDataNum = 1;
        }
      }
      const data = JSON.parse(JSON.stringify(this.voiceXChartData));
      self.y2AxisEl
        .attr('transform', `translate(${width - self.d3MaxY2Length}, 10)`)
        .append('text')
        .attr('x', 0)
        .attr('y', 5)
        .attr('fill', self.colorGrey1)
        .attr('text-anchor', 'start')
        .attr('font-size', '12px')
        .text(self.y2Label);
      self.y2LabelCheck = true;
      self.xAxis = d3.axisBottom(self.xScale);
      self.xAxisEl.call(self.xAxis);
      // x axis 必要ないの消す
      d3.select('.x.axis').select('.domain').remove();
      d3.select('.x.axis').selectAll('text').remove();
      // line設定
      const line1 = d3
        .line()
        .x(function a(d: any) {
          return self.xScale(d.value[0].indexNum.toString()) + self.xScale.bandwidth() / 2 + 30;
        })
        .y(function a(d: any) {
          return self.y2Scale(d.value[0].count) + 10;
        });
      self.svgLine
        .attr('fill', 'none')
        .attr('stroke', self.colorPink)
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 3)
        .attr('d', line1(data[0]));
      // svgLine2 設定
      if (self.svgLine2) {
        self.svgLine2
          .attr('fill', 'none')
          .attr('stroke', self.colorGrey1)
          .attr('stroke-miterlimit', 1)
          .attr('stroke-width', 3)
          .attr('d', line1(data[1]));
      }
      // svgDot1設定
      self.svgDot1
        .attr('fill', self.colorPink)
        .attr('stroke', 'none')
        .attr('cx', function a(d: d3Item) {
          // 変更点
          return self.xScale(d.value[0].indexNum.toString()) + self.xScale.bandwidth() / 2 + 30;
        })
        .attr('cy', function a(d: d3Item) {
          return self.y2Scale(d.value[0].count) + 10;
        })
        .attr('r', 8)
        .attr('class', function a(d: d3Item) {
          return `voicex-dot-count-current-${d.value[0].indexNum} voicex-dot-current`;
        })
        .on('mousemove', function a(event: MouseEvent, value: d3Item) {
          d3.select('#voicex-graph-tooltip-current')
            .transition()
            .duration(0)
            .style('left', `${event.offsetX - 10}px`)
            .style('top', `${event.offsetY + 90}px`)
            .style('opacity', 1);
          self.hoverCountCurrent = value.value[0].count;
        })
        .on('mouseout', function a() {
          d3.select('#voicex-graph-tooltip-current').style('opacity', 0);
        });
      // svgDot2設定
      if (self.svgDot2) {
        self.svgDot2
          // .attr('fill', 'grey')
          .attr('fill', self.colorGrey1)
          .attr('stroke', 'none')
          .attr('cx', function a(d: d3Item) {
            return self.xScale(d.value[0].indexNum.toString()) + self.xScale.bandwidth() / 2 + 30;
          })
          .attr('cy', function a(d: d3Item) {
            return self.y2Scale(d.value[0].count) + 10;
          })
          .attr('r', 8)
          .attr('class', function a(d: d3Item) {
            return `voicex-dot-count-before-${d.value[0].indexNum.toString()} voicex-dot-before`;
          })
          // .on('mouseover', function a(event: MouseEvent, value: d3Item) {
          .on('mousemove', function a(event: MouseEvent, value: d3Item) {
            d3.select('#voicex-graph-tooltip-before')
              .transition()
              .duration(0)
              .style('left', `${event.offsetX - 10}px`)
              .style('top', `${event.offsetY + 90}px`)
              .style('opacity', 1);
            self.hoverCountBefore = value.value[0].count;
          })
          .on('mouseout', function a() {
            d3.select('#voicex-graph-tooltip-before').style('opacity', 0);
          });
        self.svgDot2 = null;
      }
      if (self.selectedPeriod === 'day') {
        self.xLabel = '(時)';
      } else if (self.selectedPeriod === 'week' || self.selectedPeriod === 'month') {
        self.xLabel = '(日)';
      } else if (self.selectedPeriod === 'year') {
        self.xLabel = '(月)';
      }
      // xLabelText
      this.svgG
        .append('text')
        // .attr('x', 1250)
        .attr('x', 1750)
        .attr('y', 342)
        .attr('fill', self.colorGrey2)
        .attr('text-anchor', 'start')
        .attr('font-size', '12px')
        .text(self.xLabel);
      // xtext追加
      this.svgG
        .select('.x.axis')
        .selectAll('.tick')
        .data(data[longerDataNum])
        .append('rect')
        .attr('width', 20)
        .attr('height', 20)
        .attr('x', -10)
        .attr('y', 5)
        .attr('rx', 5)
        .attr('fill', 'white')
        .attr('class', function a(d: d3Item) {
          return `x-label-rect-${d.value[0].indexNum}`;
        });
      // text追加
      this.svgG
        .select('.x.axis')
        .selectAll('.tick')
        .data(data[longerDataNum])
        // .data(longerData[0])
        .append('text')
        .attr('y', 18)
        .attr('fill', 'black')
        .text(function a(d: d3Item) {
          return d.value[0].indexNum;
        })
        .attr('class', function a(d: d3Item) {
          return `x-label-text-${d.value[0].indexNum}`;
        });
      // clickイベント追加
      d3.selectAll('.x .tick').on('click', function a(this: any, event: Event, value: any): void {
        const index = value.value[0].indexNum;
        if (self.xDateClickFlg) {
          d3.selectAll('.voicex-bar-chart-current').style('fill', self.colorBlue);
          // dotの色変更
          d3.selectAll('.voicex-dot-current').style('fill', self.colorPink);
          // 線の色変える
          self.svgLine.attr('stroke', self.colorPink);
          // 選択したlabelの色変更
          d3.select(`.x-label-rect-${index}`).style('fill', 'white');
          d3.select(`.x-label-text-${index}`).style('fill', 'black');
          if (self.beforePeriodFlg === true) {
            d3.selectAll('.voicex-bar-chart-before').style('fill', self.colorGrey2);
            d3.selectAll('.voicex-dot-before').style('fill', self.colorGrey1);
            self.svgLine2.attr('stroke', self.colorGrey1);
          }
          self.xDateClickFlg = false;
        } else {
          // barchartの色変更
          d3.selectAll('.voicex-bar-chart-current').style('fill', self.colorBlue2);
          d3.select(`.voicex-bar-chart-current-${index}`).style('fill', self.colorBlue);
          // dotの色変更
          d3.selectAll('.voicex-dot-current').style('fill', self.colorPink2);
          // 選択したdotの色元通り
          d3.select(`.voicex-dot-count-current-${index}`).style('fill', self.colorPink);
          // 線の色変える
          self.svgLine.attr('stroke', self.colorPink2);
          // 選択したlabelの色変更
          d3.select(`.x-label-rect-${index}`).style('fill', 'black');
          d3.select(`.x-label-text-${index}`).style('fill', 'white');
          if (self.beforePeriodFlg === true) {
            d3.selectAll('.voicex-bar-chart-before').style('fill', self.colorGrey3);
            d3.select(`.voicex-bar-chart-before-${index}`).style('fill', self.colorGrey2);
            d3.selectAll('.voicex-dot-before').style('fill', self.colorGrey4);
            d3.select(`.voicex-dot-count-before-${index}`).style('fill', self.colorGrey1);
            self.svgLine2.attr('stroke', self.colorGrey4);
          }
          self.xDateClickFlg = true;
        }
      });
      this.setNavigating({ navigating: false });
    },
  },
});
