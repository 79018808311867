








































































































































































































































import {
  mdiSortAscending,
  mdiSortDescending,
  mdiTrendingDown,
  mdiTrendingUp,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js';
import dayjs from 'dayjs';
import Vue from 'vue';
import CategoryPieGraph from '@/components/specific/CategoryPieGraph.vue';
import MainAppHorizontalBarGraph from '@/components/specific/MainAppHorizontalBarGraph.vue';
import MainAppPieGraph from '@/components/specific/MainAppPieGraph.vue';
import MainAppStackBarGraph from '@/components/specific/MainAppStackBarGraph.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const DashboardService = ServiceFactory.get('dashboard');

type graphDataArray = {
  aggregate: {
    appliCount: number;
    appliName: string;
    category: string;
    indexNum: number;
    keyCount: number;
  }[];
  term: {
    endAt: string;
    startAt: string;
  };
}[];

export default Vue.extend({
  name: 'ScAgentDashboard',
  components: {
    CategoryPieGraph,
    MainAppHorizontalBarGraph,
    MainAppPieGraph,
    MainAppStackBarGraph,
  },
  props: {
    divisionNum: {
      type: Number,
      default: 0,
    },
    selectedBeforeDate: {
      type: String,
      default: '',
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    selectedUserLists: {
      type: Array,
      default: () => [],
    },
    updateFlg: {
      type: Boolean,
    },
  },
  data(): {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SCAgentData: graphDataArray;
    SCAgentCategoryData: any;
    SCAgentCategoryDataMax: number;
    SCAgentUsageData: any;
    SCAgentUsageDataMax: any;
    date: string;
    // headers: { align: string; sortable: boolean; text: string; value: string }[];
    graphMaxTime: number;
    mainAppArray: any[];
    selectedChartDate: string;
    selectedChartPeriod: string;
    SCAgentUsageStatusData: any;
    SCAgentMainDataArray: any;
    SCAgentBreakdownData: any[];
    SCAgentBreakdownDataMax: number;
    applicationList: any[];
    sortIcon: string;
    sortIcon2: string;
    sortDesc: boolean;
    totalAppliTime: number;
    totalKeyBordNum: number;
    averageAppliTime: number;
    averageKeyBordNum: number;
    trendingUpIcon: string;
    trendingDownIcon: string;
    totalKeyBordNumIconFlg: boolean;
    averageKeyBordNumIconFlg: boolean;
    totalAppliTimeIconFlg: boolean;
    averageAppliTimeIconFlg: boolean;
    appliTimePageNum: number;
    rightIcon: string;
    leftIcon: string;
  } {
    return {
      SCAgentBreakdownData: [],
      SCAgentBreakdownDataMax: 0,
      SCAgentCategoryData: [],
      SCAgentCategoryDataMax: 0,
      SCAgentData: [],
      SCAgentMainDataArray: [],
      SCAgentUsageData: [],
      SCAgentUsageDataMax: 0,
      SCAgentUsageStatusData: [],
      appliTimePageNum: 1,
      applicationList: [],
      averageAppliTime: 0,
      averageAppliTimeIconFlg: true,
      averageKeyBordNum: 0,
      averageKeyBordNumIconFlg: true,
      date: '',
      graphMaxTime: 0,
      // headers: [
      //   { align: 'start', sortable: false, text: '', value: 'appliName' },
      //   { align: 'end', sortable: true, text: '並べ替え', value: 'appliCount' },
      // ],
      leftIcon: mdiChevronLeft,
      mainAppArray: [],
      rightIcon: mdiChevronRight,
      selectedChartDate: '',
      selectedChartPeriod: '',
      sortDesc: true,
      sortIcon: mdiSortAscending,
      sortIcon2: mdiSortDescending,
      totalAppliTime: 0,
      totalAppliTimeIconFlg: true,
      totalKeyBordNum: 0,
      totalKeyBordNumIconFlg: true,
      trendingDownIcon: mdiTrendingDown,
      trendingUpIcon: mdiTrendingUp,
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    returnAppliTime() {
      return function fn(time: number) {
        const callTime = time * 30;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        return `${callTime}秒`;
      };
    },
    returnDividePeriod(): string {
      if (this.selectedPeriod === 'year') {
        return '(/月)';
      }
      if (this.selectedPeriod === 'month' || this.selectedPeriod === 'week') {
        return '(/日)';
      }
      return '(/時間)';
    },
    returnFilterSCAgentBreakdownData(this: any): any[] {
      let copyArray = JSON.parse(JSON.stringify(this.SCAgentBreakdownData));
      // 並べ替え
      if (this.sortDesc) {
        copyArray = copyArray.sort(function f(a: any, b: any) {
          return a.appliCount > b.appliCount ? -1 : 1;
        });
      } else {
        copyArray = copyArray.sort(function f(a: any, b: any) {
          return a.appliCount < b.appliCount ? -1 : 1;
        });
      }
      return copyArray.slice((this.appliTimePageNum - 1) * 10, this.appliTimePageNum * 10);
    },
  },
  watch: {
    selectedDate(newValue) {
      if (newValue) {
        this.selectedChartDate = newValue;
        this.getGraphDataMainApp();
        this.getGraphDataUsageStatus();
        this.getSCAgentBreakdown();
      }
    },
    selectedPeriod(newValue) {
      if (newValue) {
        this.selectedChartPeriod = newValue;
        this.getGraphDataMainApp();
        this.getGraphDataUsageStatus();
        this.getSCAgentBreakdown();
      }
    },
    selectedTarget: {
      handler(newValue) {
        if (newValue) {
          this.getGraphDataMainApp();
          this.getGraphDataUsageStatus();
          this.getSCAgentBreakdown();
        }
      },
      deep: true,
    },
    updateFlg(newValue) {
      if (newValue) {
        this.getGraphDataMainApp();
        this.getGraphDataUsageStatus();
        this.getSCAgentBreakdown();
        this.$emit('parent-update-flg', false);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.getElementHeight1);
    window.addEventListener('resize', this.getElementHeight2);
    this.getGraphDataMainApp();
    this.getGraphDataUsageStatus();
    this.getSCAgentBreakdown();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getElementHeight1);
    window.removeEventListener('resize', this.getElementHeight2);
  },
  updated() {
    this.getElementHeight2();
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    beforeRanking() {
      this.appliTimePageNum -= 1;
    },
    getElementHeight1() {
      const element = document.getElementById('actlogger-mainapp-usage-time')!;
      const size = element.getBoundingClientRect();
      const elementScroll = document.querySelector<HTMLElement>('.main-app-pc-usage .ps')!;
      if (this.selectedTarget.selectedGroup !== 'userId') {
        elementScroll.style.height = `${size.height - 63}px`;
      } else {
        const pieElement = document.getElementById('pie-chart-main-container')!;
        pieElement.style.height = `${size.height - 62}px`;
      }
    },
    getElementHeight2() {
      const checkElement = document.getElementById('category-pie');
      const changeElements = document.querySelectorAll<HTMLElement>('.actlogger-appli-time');
      const changeElementsFooter = document.querySelector<HTMLElement>(
        '.actlogger-appli-time-footer'
      );
      if (checkElement && changeElements) {
        const size = checkElement.getBoundingClientRect();
        let rankingHeight = 0;
        if (this.SCAgentBreakdownData.length > 10) {
          rankingHeight = (size.height - 59) / 11;
          if (changeElementsFooter) {
            for (let i = 0; i < changeElements.length; i += 1) {
              changeElements[i].style.height = `${rankingHeight}px`;
              changeElementsFooter.style.height = `${rankingHeight}px`;
            }
          }
        } else {
          rankingHeight = (size.height - 59) / 10;
          for (let i = 0; i < changeElements.length; i += 1) {
            changeElements[i].style.height = `${rankingHeight}px`;
          }
        }
      }
    },
    getGraphDataCategoryChart() {
      const self = this;
      const data = JSON.parse(JSON.stringify(self.SCAgentUsageStatusData));
      self.SCAgentCategoryDataMax = 0;
      const categoryObject: any = {};
      const categoryDetailObject: any = {};
      if (self.selectedTarget.selectedGroup === 'userId') {
        for (let i = 0; i < data.length; i += 1) {
          self.SCAgentCategoryDataMax += data[i].appliCount;
          if (categoryObject[data[i].category]) {
            categoryObject[data[i].category] += data[i].appliCount;
          } else {
            categoryObject[data[i].category] = data[i].appliCount;
          }
        }
        // キーを含んだ配列に変換
        let arrayMainAppli = Object.keys(categoryObject).map((k) => ({
          key: k,
          value: categoryObject[k],
        }));
        // 並べ替え
        arrayMainAppli = arrayMainAppli.sort(function fn(a: any, b: any) {
          return a.value > b.value ? -1 : 1;
        });
        if (arrayMainAppli.length > 0) {
          let otherItem: any = '';
          const otherItemObject: {
            key: string;
            value: number;
          } = {
            key: 'その他',
            value: 0,
          };
          for (let i = 0; i < arrayMainAppli.length; i += 1) {
            if (arrayMainAppli[i].key === 'その他') {
              otherItem = arrayMainAppli.splice(i, 1);
              otherItemObject.value += otherItem[0].value;
              i -= 1;
            }
          }
          arrayMainAppli.push(otherItemObject);
        }
        self.SCAgentCategoryData = arrayMainAppli;
      } else {
        for (let i = 0; i < data.length; i += 1) {
          for (let j = 0; j < data[i].aggregates.length; j += 1) {
            self.SCAgentCategoryDataMax += data[i].aggregates[j].appliCount;
            if (categoryObject[data[i].aggregates[j].category]) {
              categoryObject[data[i].aggregates[j].category] += data[i].aggregates[j].appliCount;
              categoryDetailObject[data[i].aggregates[j].category].value.push(
                data[i].aggregates[j]
              );
            } else {
              categoryObject[data[i].aggregates[j].category] = data[i].aggregates[j].appliCount;
              categoryDetailObject[data[i].aggregates[j].category] = {
                value: [data[i].aggregates[j]],
              };
            }
          }
        }
        // キーを含んだ配列に変換
        let arrayMainAppli: any = Object.keys(categoryObject).map(function a(k) {
          return {
            key: k,
            value: categoryObject[k],
          };
        });
        // 並べ替え arrayMainAppli
        arrayMainAppli = arrayMainAppli.sort(function f(a: any, b: any) {
          return a.value > b.value ? -1 : 1;
        });
        if (arrayMainAppli.length > 0) {
          let otherItem: any = '';
          const otherItemObject: {
            key: string;
            value: number;
          } = {
            key: 'その他',
            value: 0,
          };
          for (let i = 0; i < arrayMainAppli.length; i += 1) {
            if (arrayMainAppli[i].key === 'その他') {
              otherItem = arrayMainAppli.splice(i, 1);
              otherItemObject.value += otherItem[0].value;
              i -= 1;
            }
          }
          arrayMainAppli.push(otherItemObject);
          // editobject
          for (let i = 0; i < arrayMainAppli.length; i += 1) {
            if (arrayMainAppli[i].value > 0) {
              arrayMainAppli[i].detail = categoryDetailObject[arrayMainAppli[i].key].value;
            }
          }
        }
        self.SCAgentCategoryData = arrayMainAppli;
      }
    },
    async getGraphDataMainApp() {
      const self = this;
      self.SCAgentData = [];
      this.setNavigating({ navigating: true });
      const { workspaceId } = this.userAttributes;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      // self.SCAgentData = [0];
      self.SCAgentData = [];
      // 最大値をリセット
      self.graphMaxTime = 0;
      let period = '';
      if (self.selectedChartPeriod) {
        period = self.selectedChartPeriod;
      } else {
        period = self.selectedPeriod;
      }
      // MainAppPieChart用のデータ取得
      let dataObject = [];
      // 前日のデータ
      let beforeDateObject = [];
      if (self.selectedTarget.selectedId === 'none') {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        beforeDateObject = await DashboardService.getSCAgentData({
          target: {
            projectId: 'main',
          },
          term: {
            date: self.selectedBeforeDate,
            kind: period,
          },
          workspaceId,
        });
      } else if (Object.keys(self.selectedTarget.selectedUser).length) {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            userId: self.selectedTarget.selectedUser.userId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        beforeDateObject = await DashboardService.getSCAgentData({
          target: {
            userId: self.selectedTarget.selectedUser.userId,
          },
          term: {
            date: self.selectedBeforeDate,
            kind: period,
          },
          workspaceId,
        });
      } else {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        beforeDateObject = await DashboardService.getSCAgentData({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.selectedBeforeDate,
            kind: period,
          },
          workspaceId,
        });
      }
      /// //////////////////////////////////////////////////////////////
      const mainAppObject: any = {};
      const dataObjectCopy2 = JSON.parse(JSON.stringify(dataObject));
      // 主要アプリを取得
      for (let i = 0; i < dataObjectCopy2.length; i += 1) {
        const { aggregate } = dataObjectCopy2[i];
        for (let j = 0; j < aggregate.length; j += 1) {
          if (mainAppObject[aggregate[j].appliName]) {
            mainAppObject[aggregate[j].appliName] += aggregate[j].appliCount;
          } else {
            mainAppObject[aggregate[j].appliName] = aggregate[j].appliCount;
          }
        }
      }
      // mainAppObjectを配列に
      const mainAppArray = Object.entries(mainAppObject).map(([key, value]) => ({ key, value }));
      // 並べ替え
      const sortMainAppArray = mainAppArray.sort(function (a: any, b: any) {
        return a.value > b.value ? -1 : 1;
      });
      // その他を取り除き
      let otherFlg = false;
      const filterMainAppArray = sortMainAppArray.filter(function (element: any) {
        if (element.key === 'その他') {
          otherFlg = true;
        }
        return element.key !== 'その他';
      });
      // 上位Top4を取得（ない場合そのまま）
      let returnMainAppArray: any[] = [];
      if (filterMainAppArray.length > 4 && otherFlg) {
        returnMainAppArray = filterMainAppArray.splice(0, 4);
        returnMainAppArray.push({ key: 'その他', value: true });
      } else if (filterMainAppArray.length > 4 && !otherFlg) {
        returnMainAppArray = filterMainAppArray.splice(0, 5);
      } else if (filterMainAppArray.length <= 4 && otherFlg) {
        returnMainAppArray = filterMainAppArray;
        returnMainAppArray.push({ key: 'その他', value: true });
      } else {
        returnMainAppArray = filterMainAppArray;
      }
      // 上位Top4をpropsへ
      // オブジェクトに戻す
      const returnMainAppObj = returnMainAppArray.reduce(
        (obj, item) => ({ ...obj, [item.key]: item.value }),
        {}
      );
      self.mainAppArray = returnMainAppArray;
      const data = JSON.parse(JSON.stringify(dataObject));
      // new Index計算
      const indexNumArray = [];
      const day = dayjs(self.date).day();
      const item = dayjs(self.date).subtract(day, 'day').format('YYYYMMDD');
      for (let i = 0; i < dataObjectCopy2.length; i += 1) {
        if (self.selectedChartPeriod === 'week') {
          const pushItem = dayjs(item).add(i, 'day').date();
          indexNumArray.push(pushItem);
        } else if (self.selectedChartPeriod === 'month' || self.selectedChartPeriod === 'year') {
          indexNumArray.push(i + 1);
        } else {
          indexNumArray.push(i);
        }
      }
      /// /////////////////////////////////////////////////////////////
      // グラフに渡すデータに加工
      for (let i = 0; i < data.length; i += 1) {
        let { aggregate } = data[i];
        const totalNum = aggregate.reduce(function (sum: number, element: any) {
          return sum + element.appliCount;
        }, 0);
        if (totalNum > self.graphMaxTime) {
          self.graphMaxTime = totalNum;
        }
        aggregate = aggregate.sort(function (a: any, b: any) {
          return a.appliCount > b.appliCount ? -1 : 1;
        });
        let otherItem: any = '';
        const otherItemObject: {
          appliCount: number;
          appliName: string;
          category: string;
          keyCount: number;
          // userIdNum: number;
          indexNum: number;
        } = {
          appliCount: 0,
          appliName: 'その他',
          category: 'その他',
          // indexNum: i + indexNum,
          indexNum: indexNumArray[i],
          keyCount: 0,
          // indexNum: i,
        };
        for (let j = 0; j < aggregate.length; j += 1) {
          // x軸のindex設定
          // aggregate[j].indexNum = i + indexNum;
          aggregate[j].indexNum = indexNumArray[i];
          // その他関連を取り除いてotherItemObjectにいれる
          if (aggregate[j].appliName === 'その他' || !returnMainAppObj[aggregate[j].appliName]) {
            otherItem = aggregate.splice(j, 1);
            otherItemObject.appliCount += otherItem[0].appliCount;
            otherItemObject.keyCount += otherItem[0].keyCount;
            j -= 1;
          }
        }
        if (aggregate.length > 4 && otherItem) {
          // その他を結合
          aggregate.push(otherItemObject);
        } else if (aggregate.length > 4 && !otherItem) {
          // その他を結合
          aggregate.push(otherItemObject);
        } else if (aggregate.length <= 3 && otherItem) {
          aggregate.push(otherItemObject);
        } else {
          aggregate.push(otherItemObject);
        }
      }
      // 主要アプリ利用時間用のデータ
      self.SCAgentData = data;
      // 前日比取得
      const dateObjectCopy = JSON.parse(JSON.stringify(dataObject));
      const beforeObjectCopy = JSON.parse(JSON.stringify(beforeDateObject));
      const totalObject = { key: 0, time: 0 };
      const beforeTotalObject = { key: 0, time: 0 };
      for (let i = 0; i < dateObjectCopy.length; i += 1) {
        for (let j = 0; j < dateObjectCopy[i].aggregate.length; j += 1) {
          totalObject.time += dateObjectCopy[i].aggregate[j].appliCount;
          totalObject.key += dateObjectCopy[i].aggregate[j].keyCount;
        }
      }
      for (let i = 0; i < beforeObjectCopy.length; i += 1) {
        for (let j = 0; j < beforeObjectCopy[i].aggregate.length; j += 1) {
          beforeTotalObject.time += beforeObjectCopy[i].aggregate[j].appliCount;
          beforeTotalObject.key += beforeObjectCopy[i].aggregate[j].keyCount;
        }
      }
      self.totalKeyBordNum = totalObject.key;
      self.totalAppliTime = totalObject.time;
      const beforeTotalKeyBordNum = beforeTotalObject.key;
      const beforeTotalAppliTime = beforeTotalObject.time;
      // self.averageKeyBordNum = Math.floor(this.totalKeyBordNum / self.divisionNum);
      self.averageKeyBordNum = Math.floor(this.totalKeyBordNum / indexNumArray.length);
      // self.averageAppliTime = Math.floor(totalObject.time / self.divisionNum);
      self.averageAppliTime = Math.floor(totalObject.time / indexNumArray.length);
      const averageBeforeKeyBordNum = Math.floor(beforeTotalKeyBordNum / self.divisionNum);
      const averageBeforeAppliTime = Math.floor(beforeTotalAppliTime / self.divisionNum);
      // 合計打鍵数のFlg
      if (this.totalKeyBordNum > beforeTotalKeyBordNum) {
        self.totalKeyBordNumIconFlg = true;
      } else {
        self.totalKeyBordNumIconFlg = false;
      }
      // 平均キーボード打鍵数Flg
      if (this.averageKeyBordNum > averageBeforeKeyBordNum) {
        self.averageKeyBordNumIconFlg = true;
      } else {
        self.averageKeyBordNumIconFlg = false;
      }
      // 合計アプリ稼働時間
      if (this.totalAppliTime > beforeTotalAppliTime) {
        self.totalAppliTimeIconFlg = true;
      } else {
        self.totalAppliTimeIconFlg = false;
      }
      // 平均アプリ稼働時間
      if (this.averageAppliTime > averageBeforeAppliTime) {
        self.averageAppliTimeIconFlg = true;
      } else {
        self.averageAppliTimeIconFlg = false;
      }
      // this.getElementHeight();
      setTimeout(this.getElementHeight1, 0);
    },
    getGraphDataMainChart() {
      const self = this;
      const data = JSON.parse(JSON.stringify(self.SCAgentUsageStatusData));
      self.SCAgentUsageData = [];
      self.SCAgentUsageDataMax = 0;
      if (self.selectedTarget.selectedGroup === 'userId') {
        // appliNameがその他のアイテムを取得する
        const otherItemObject = {
          appliCount: 0,
          appliName: 'その他',
          category: 'その他',
          keyCount: 0,
        };
        for (let i = 0; i < data.length; i += 1) {
          if (data[i].appliName === 'その他') {
            const otherItem = data.splice(i, 1);
            otherItemObject.appliCount += otherItem[0].appliCount;
            otherItemObject.keyCount += otherItem[0].keyCount;
            i -= 1;
          }
        }
        // その他以外のアプリをAppliCountで並べ替え
        let sortData = [];
        if (data.length > 0) {
          sortData = data.sort(function fn(a: any, b: any) {
            return a.appliCount > b.appliCount ? -1 : 1;
          });
        }
        // sortDataが4つ以上、かつその他がある場合
        if (
          sortData.length > 4 &&
          (otherItemObject.appliCount > 0 || otherItemObject.keyCount > 0)
        ) {
          // 先頭の４つ取得
          const mainData = sortData.splice(0, 4);
          // それ以外はその他に足していく
          for (let j = 0; j < sortData.length; j += 1) {
            otherItemObject.appliCount += sortData[j].appliCount;
            otherItemObject.keyCount += sortData[j].keyCount;
          }
          // その他を結合
          mainData.push(otherItemObject);
          self.SCAgentUsageData = mainData;
          // sortDataが4つ以上、かつその他がない場合
        } else if (
          sortData.length > 4 &&
          otherItemObject.appliCount === 0 &&
          otherItemObject.keyCount === 0
        ) {
          // 先頭から４つ取得
          const mainData = sortData.splice(0, 4);
          // 5つ目移行はその他として換算
          for (let j = 0; j < sortData.length; j += 1) {
            otherItemObject.appliCount += sortData[j].appliCount;
            otherItemObject.keyCount += sortData[j].keyCount;
          }
          // その他を結合
          mainData.push(otherItemObject);
          self.SCAgentUsageData = mainData;
        } else if (
          (otherItemObject.keyCount > 0 || otherItemObject.appliCount > 0) &&
          sortData.length <= 4
        ) {
          sortData.push(otherItemObject);
          self.SCAgentUsageData = sortData;
        } else {
          self.SCAgentUsageData = sortData;
        }
        // 円グラフ用のデータ取得（appliCountの合計値）
        self.SCAgentUsageDataMax = self.SCAgentUsageStatusData.reduce(function a(
          sum: any,
          element: any
        ) {
          return sum + element.appliCount;
        },
        0);
      } else {
        const mainAppObject: any = {};
        const dataCopy = JSON.parse(JSON.stringify(data));
        self.SCAgentUsageDataMax = 0;
        // 主要アプリを取得
        for (let i = 0; i < dataCopy.length; i += 1) {
          const { aggregates } = dataCopy[i];
          for (let j = 0; j < aggregates.length; j += 1) {
            if (mainAppObject[aggregates[j].appliName]) {
              mainAppObject[aggregates[j].appliName] += aggregates[j].appliCount;
            } else {
              mainAppObject[aggregates[j].appliName] = aggregates[j].appliCount;
            }
          }
        }
        // mainAppObjectを配列に
        const mainAppArray = Object.entries(mainAppObject).map(([key, value]) => ({ key, value }));
        // 並べ替え
        const sortMainAppArray = mainAppArray.sort(function (a: any, b: any) {
          return a.value > b.value ? -1 : 1;
        });
        // その他を取り除き
        let otherFlg = false;
        const filterMainAppArray = sortMainAppArray.filter(function (element: any) {
          if (element.key === 'その他') {
            otherFlg = true;
          }
          return element.key !== 'その他';
        });
        // 上位Top4を取得（ない場合そのまま）
        let returnMainAppArray: any[] = [];
        if (filterMainAppArray.length > 4) {
          returnMainAppArray = filterMainAppArray.splice(0, 4);
        } else {
          returnMainAppArray = filterMainAppArray;
        }
        if (otherFlg) {
          returnMainAppArray.push({ otherFlg: true });
        }
        // 上位Top4をpropsへ
        self.SCAgentMainDataArray = returnMainAppArray;
        // オブジェクトに戻す
        const returnMainAppObj = returnMainAppArray.reduce(
          (obj, item) => ({ ...obj, [item.key]: item.value }),
          {}
        );
        // グラフに渡すデータに加工
        // if (self.selectedTarget.selectedGroup !== 'none') {
        if (self.selectedTarget.selectedId !== 'none') {
          for (let i = 0; i < data.length; i += 1) {
            // for (let i = 0; i < dataCopy.length; i += 1) {
            let { aggregates } = data[i];
            // let { aggregates } = dataCopy[i];
            const { userId } = data[i];
            // const { userId } = dataCopy[i];
            const totalNum = aggregates.reduce(function a(sum: number, element: any) {
              return sum + element.appliCount;
            }, 0);
            data[i].totalNum = totalNum;
            if (totalNum > self.SCAgentUsageDataMax) {
              self.SCAgentUsageDataMax = totalNum;
            }
            aggregates = aggregates.sort(function fn(a: any, b: any) {
              return a.appliCount > b.appliCount ? -1 : 1;
            });
            let otherItem: any = '';
            const otherItemObject: {
              appliCount: number;
              appliName: string;
              category: string;
              keyCount: number;
              userIdNum: number;
            } = {
              appliCount: 0,
              appliName: 'その他',
              category: 'その他',
              keyCount: 0,
              userIdNum: userId,
            };
            for (let j = 0; j < aggregates.length; j += 1) {
              aggregates[j].userIdNum = userId;
              // その他関連を取り除いてotherItemObjectにいれる
              if (
                (aggregates[j].appliName === 'その他' ||
                  !returnMainAppObj[aggregates[j].appliName]) &&
                aggregates[j].userIdNum >= 0
              ) {
                otherItem = aggregates.splice(j, 1);
                otherItemObject.appliCount += otherItem[0].appliCount;
                otherItemObject.keyCount += otherItem[0].keyCount;
                j -= 1;
              }
            }
            if (aggregates.length > 4 && otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length > 4 && !otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length <= 3 && otherItem) {
              aggregates.push(otherItemObject);
            } else {
              aggregates.push(otherItemObject);
            }
          }
          // } else if (self.selectedTarget.selectedGroup === 'none' && self.selectedTarget.gFlg === 0) {
        } else if (self.selectedTarget.selectedId === 'none' && self.selectedTarget.gFlg === 0) {
          for (let i = 0; i < data.length; i += 1) {
            let { aggregates } = data[i];
            const { projectId } = data[i];
            const totalNum = aggregates.reduce(function (sum: number, element: any) {
              return sum + element.appliCount;
            }, 0);
            data[i].totalNum = totalNum;
            if (totalNum > self.SCAgentUsageDataMax) {
              self.SCAgentUsageDataMax = totalNum;
            }
            aggregates = aggregates.sort(function (a: any, b: any) {
              return a.appliCount > b.appliCount ? -1 : 1;
            });
            let otherItem: any = '';
            const otherItemObject: {
              appliCount: number;
              appliName: string;
              category: string;
              keyCount: number;
              projectId: string;
            } = {
              appliCount: 0,
              appliName: 'その他',
              category: 'その他',
              keyCount: 0,
              projectId,
            };
            for (let j = 0; j < aggregates.length; j += 1) {
              aggregates[j].projectId = projectId;
              // その他関連を取り除いてotherItemObjectにいれる
              if (
                (aggregates[j].appliName === 'その他' ||
                  !returnMainAppObj[aggregates[j].appliName]) &&
                aggregates[j].projectId
                // aggregates[j].projectId >= 0
              ) {
                otherItem = aggregates.splice(j, 1);
                otherItemObject.appliCount += otherItem[0].appliCount;
                otherItemObject.keyCount += otherItem[0].keyCount;
                j -= 1;
              }
            }
            if (aggregates.length > 4 && otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length > 4 && !otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length <= 3 && otherItem) {
              aggregates.push(otherItemObject);
            } else {
              aggregates.push(otherItemObject);
            }
          }
          // } else if (self.selectedTarget.selectedGroup === 'none' && self.selectedTarget.gFlg === 1) {
        } else if (self.selectedTarget.selectedId === 'none' && self.selectedTarget.gFlg === 1) {
          for (let i = 0; i < data.length; i += 1) {
            let { aggregates } = data[i];
            const { departmentId } = data[i];
            const totalNum = aggregates.reduce(function (sum: number, element: any) {
              return sum + element.appliCount;
            }, 0);
            data[i].totalNum = totalNum;
            if (totalNum > self.SCAgentUsageDataMax) {
              self.SCAgentUsageDataMax = totalNum;
            }
            aggregates = aggregates.sort(function (a: any, b: any) {
              return a.appliCount > b.appliCount ? -1 : 1;
            });
            let otherItem: any = '';
            const otherItemObject: {
              appliCount: number;
              appliName: string;
              category: string;
              keyCount: number;
              departmentId: string;
            } = {
              appliCount: 0,
              appliName: 'その他',
              category: 'その他',
              departmentId,
              keyCount: 0,
            };
            for (let j = 0; j < aggregates.length; j += 1) {
              aggregates[j].departmentId = departmentId;
              // その他関連を取り除いてotherItemObjectにいれる
              if (
                (aggregates[j].appliName === 'その他' ||
                  !returnMainAppObj[aggregates[j].appliName]) &&
                aggregates[j].departmentId
                // aggregates[j].projectId >= 0
              ) {
                otherItem = aggregates.splice(j, 1);
                otherItemObject.appliCount += otherItem[0].appliCount;
                otherItemObject.keyCount += otherItem[0].keyCount;
                j -= 1;
              }
            }
            if (aggregates.length > 4 && otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length > 4 && !otherItem) {
              // その他を結合
              aggregates.push(otherItemObject);
              // data[i].aggregates = mainData;
            } else if (aggregates.length <= 3 && otherItem) {
              aggregates.push(otherItemObject);
            } else {
              aggregates.push(otherItemObject);
            }
          }
        }
        const sortData = data.sort(function fn(a: any, b: any) {
          return a.totalNum > b.totalNum ? -1 : 1;
        });
        for (let j = 0; j < sortData.length; j += 1) {
          sortData[j].indexNum = j;
        }
        self.SCAgentUsageData = sortData;
      }
    },
    async getGraphDataUsageStatus() {
      const self = this;
      const { workspaceId } = this.userAttributes;
      let period = '';
      if (self.selectedChartPeriod) {
        period = self.selectedChartPeriod;
      } else {
        period = self.selectedPeriod;
      }
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      if (self.selectedTarget.selectedId === 'none') {
        self.SCAgentUsageStatusData = await DashboardService.getSCAgentUsageStatus({
          gFlg: self.selectedTarget.gFlg,
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else if (Object.keys(self.selectedTarget.selectedUser).length) {
        self.SCAgentUsageStatusData = await DashboardService.getSCAgentUsageStatus({
          gFlg: self.selectedTarget.gFlg,
          target: {
            userId: self.selectedTarget.selectedUser.userId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        self.SCAgentUsageStatusData = await DashboardService.getSCAgentUsageStatus({
          gFlg: self.selectedTarget.gFlg,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      }
      this.getGraphDataMainChart();
      this.getGraphDataCategoryChart();
    },
    async getSCAgentBreakdown() {
      const self = this;
      const { workspaceId } = this.userAttributes;
      let period = '';
      if (self.selectedChartPeriod) {
        period = self.selectedChartPeriod;
      } else {
        period = self.selectedPeriod;
      }
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      let scagentBreakdownData;
      let scagentBreakdownBeforeData;
      if (Object.keys(self.selectedTarget.selectedUser).length) {
        scagentBreakdownData = await DashboardService.getSCAgentBreakDown({
          target: {
            userId: self.selectedTarget.selectedUser.userId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        scagentBreakdownBeforeData = await DashboardService.getSCAgentBreakDown({
          target: {
            userId: self.selectedTarget.selectedUser.userId,
          },
          term: {
            date: self.selectedBeforeDate,
            kind: period,
          },
          workspaceId,
        });
      } else {
        scagentBreakdownData = await DashboardService.getSCAgentBreakDown({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        scagentBreakdownBeforeData = await DashboardService.getSCAgentBreakDown({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.selectedBeforeDate,
            kind: period,
          },
          workspaceId,
        });
      }
      self.applicationList = await DashboardService.getApplicationList({
        workspaceId,
      });
      // imageを追加
      for (let i = 0; i < scagentBreakdownData.length; i += 1) {
        const appliName = scagentBreakdownData[i].appliName
          ? scagentBreakdownData[i].appliName
          : 'null';
        const index = self.applicationList.findIndex(function a(element) {
          return element.name === appliName;
        });
        if (index > 0) {
          scagentBreakdownData[i].image = self.applicationList[index].image;
        }
      }
      // beforeDate比を格納
      for (let i = 0; i < scagentBreakdownData.length; i += 1) {
        for (let j = 0; j < scagentBreakdownBeforeData.length; j += 1) {
          if (scagentBreakdownData[i].appliName === scagentBreakdownBeforeData[j].appliName) {
            scagentBreakdownData[i].upFlg =
              scagentBreakdownData[i].appliCount > scagentBreakdownBeforeData[j].appliCount;
          }
        }
      }
      self.SCAgentBreakdownDataMax = Math.floor(scagentBreakdownData.length / 10) + 1;
      self.SCAgentBreakdownData = scagentBreakdownData;
      // setTimeout(this.getElementHeight2, 0);
    },
    nextRanking() {
      this.appliTimePageNum += 1;
    },
    toggleOrder() {
      this.sortDesc = !this.sortDesc;
    },
  },
});
