

















import * as d3 from 'd3';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'CategoryPieGraph',
  props: {
    categoryData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    categoryDataMax: {
      type: Number,
      default: 0,
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
  },
  data(): {
    width: number;
    height: number;
    radius: any;
    svg: any;
    svgLegend: any;
    path: any;
    imageCommunication: string;
    imageCreative: string;
    imageDocument: string;
    imageInternet: string;
    imageOther: string;
    imagePresentation: string;
    imageWebConference: string;
    imageVoicex: string;
    color: any;
    pie: any;
    arcHover: any;
    arcGenerator: any;
    current: any;
    categoryChartData: any;
    categoryChartDataMax: any;
    outerArc: any;
    labelArc: any;
    textArc: any;
    sliceOrigin: any;
    hoverCategory: string;
    hoverCategoryTime: number;
    hoverCategoryDetail: any[];
    categoryDataFlg: boolean;
    categoryDataMaxFlg: boolean;
    mouseoverFlg: boolean;
  } {
    return {
      arcGenerator: null,
      arcHover: null,
      categoryChartData: [],
      categoryChartDataMax: 0,
      categoryDataFlg: false,
      categoryDataMaxFlg: false,
      color: null,
      current: null,
      height: 480,
      hoverCategory: '',
      hoverCategoryDetail: [],
      hoverCategoryTime: 0,
      imageCommunication: 'https://pict.dev.shineconnect.jp/1002/1114',
      imageCreative: 'https://pict.dev.shineconnect.jp/1002/1116',
      imageDocument: 'https://pict.dev.shineconnect.jp/1002/1115',
      imageInternet: 'https://pict.dev.shineconnect.jp/1002/1111',
      imageOther: 'https://pict.dev.shineconnect.jp/1002/1110',
      imagePresentation: 'https://pict.dev.shineconnect.jp/1002/1112',
      imageVoicex:
        '<path xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" d="M18.1803 2.2396C18.1803 3.11639 17.3714 3.82716 16.3737 3.82716C15.376 3.82716 14.5672 3.11639 14.5672 2.2396C14.5672 1.36281 15.376 0.652039 16.3737 0.652039C17.3714 0.652039 18.1803 1.36281 18.1803 2.2396ZM4.53283 4.53445C3.70074 5.26553 3.88645 5.79768 5.71521 7.92056C6.60944 8.95867 7.34109 9.9482 7.34109 10.1195C7.34109 10.2908 6.45137 11.4484 5.36384 12.692C4.2763 13.9355 3.33799 15.0868 3.27892 15.2505C2.98011 16.0781 3.85755 17.004 4.94057 17.004C5.68901 17.004 6.06802 16.6869 7.65687 14.7318C8.2776 13.9679 8.90772 13.3016 9.0573 13.2513C9.67026 13.0446 10.1755 13.3902 11.3155 14.7955C12.9164 16.7695 13.2051 17.004 14.0338 17.004C14.5736 17.004 14.8133 16.902 15.1834 16.5155C16.0078 15.6547 15.9431 15.5096 13.4398 12.6015C12.2955 11.2722 11.6316 10.3586 11.6316 10.1131C11.6316 9.87072 12.1803 9.10298 13.0967 8.06281C14.6915 6.25315 14.9873 5.75497 14.8182 5.16313C14.5895 4.36189 13.2804 3.88911 12.4164 4.29553C12.2401 4.3784 11.6717 4.98532 11.1534 5.64416C9.70006 7.49176 9.25276 7.554 8.0339 6.07756C6.77131 4.54811 6.25952 4.14471 5.58116 4.14471C5.17975 4.14471 4.82693 4.27584 4.53283 4.53445ZM2.64406 20.3378C3.64178 20.3378 4.4506 19.627 4.4506 18.7502C4.4506 17.8734 3.64178 17.1627 2.64406 17.1627C1.64634 17.1627 0.837524 17.8734 0.837524 18.7502C0.837524 19.627 1.64634 20.3378 2.64406 20.3378Z" fill="#1E1D27"/>',
      imageWebConference: 'https://pict.dev.shineconnect.jp/1002/1113',
      labelArc: null,
      mouseoverFlg: false,
      outerArc: null,
      path: null,
      pie: null,
      radius: null,
      sliceOrigin: null,
      svg: null,
      svgLegend: null,
      textArc: null,
      width: 853,
    };
  },
  computed: {
    returnAppliTime() {
      return function fn(time: number) {
        const callTime = time * 30;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        return `${callTime}秒`;
      };
    },
  },
  watch: {
    categoryData: {
      handler(newValue) {
        this.categoryChartData = newValue;
        // this.drawGraph();
        this.categoryDataFlg = true;
        this.firstGraph();
      },
      deep: true,
    },
    categoryDataMax: {
      handler(newValue) {
        this.categoryChartDataMax = newValue;
        // this.drawGraph();
        this.categoryDataMaxFlg = true;
        this.firstGraph();
      },
    },
  },
  mounted() {
    this.svg = d3
      .select('#pie-chart')
      .append('svg')
      .attr('id', 'category-pie-graph')
      .attr('viewBox', `${-this.width / 2} ${-this.height / 2} ${this.width} ${this.height}`);
  },
  methods: {
    arcTween(a: any) {
      const self = this;
      const i = d3.interpolate(self.current, a);
      self.current = i(0);
      return function fn(t: any) {
        return self.arcGenerator(i(t));
      };
    },
    drawGraph() {
      const self = this;
      if (this.categoryDataFlg && this.categoryDataMaxFlg) {
        const enterClockwise = {
          endAngle: 0,
          startAngle: 0,
        };
        // legend設置
        const legend = self.svgLegend
          .selectAll('legend')
          .data(self.categoryChartData)
          .enter()
          .append('g');
        legend
          .append('rect')
          .attr('width', 20)
          .attr('height', 14)
          .attr('y', 1)
          .style('fill', function a(d: any, i: number) {
            if (d.key === 'その他') {
              return '#E6E6E7';
            }
            const number: string = i.toString();
            return self.color(number);
          });
        // legendText
        legend
          .append('text')
          .attr('x', 23)
          .attr('y', 14)
          // .attr('dy', '.15em')
          .attr('font-size', '15px')
          .text(function a(d: any) {
            if (d.otherFlg === true) {
              return 'その他';
            }
            return d.key;
          });
        // width array
        const widthArray: any = [];
        for (let i = 0; i < self.categoryChartData.length; i += 1) {
          widthArray.push(legend.nodes()[i].getBBox().width);
        }
        // const parentWidth = 800 - 30;
        const parentWidth = 853 - 20;
        let legendWidth = 0;
        let legendy = 0;
        let legentHeight = 1;
        legend.attr('transform', function fn(d: any, i: number) {
          if (i === 0) {
            return 'translate(0, 0)';
          }
          legendWidth += widthArray[i - 1] + 10;
          if (parentWidth < legendWidth || parentWidth < legendWidth + widthArray[i]) {
            legendy += 20;
            legendWidth = 0;
            legentHeight += 1;
          }
          return `translate(${legendWidth},${legendy})`;
        });
        self.svgLegend.attr('viewBox', `0 0 ${self.width} ${legentHeight * 20}`);
        self.path = self.sliceOrigin.selectAll('.pie').data(this.pie(self.categoryChartData));
        self.path.exit().transition().duration(750).attrTween('d', self.arcTween).remove();
        self.path
          .enter()
          .append('g')
          .append('path')
          .attr('class', function a(d: any) {
            return `category-pie-${d.index}`;
          })
          .attr('fill', function a(d: any, i: number) {
            if (d.data.key === 'その他') {
              return '#E6E6E7';
            }
            const number: string = i.toString();
            return self.color(number);
          })
          .attr('d', self.arcGenerator(enterClockwise))
          .on('mousemove', function f(this: any, event: any, data: any) {
            d3.select(this).transition().attr('d', self.arcHover).duration(10);
            self.hoverCategory = data.data.key;
            self.hoverCategoryTime = data.data.value;
            self.hoverCategoryDetail = [];
            // Adding tooltip
            d3.select('#tooltip')
              .transition()
              .duration(0)
              .style('left', `${event.offsetX + 10}px`)
              // .style('top', `${event.offsetY - 30}px`)
              .style('opacity', 1);
            if (!self.mouseoverFlg && self.selectedTarget.selectedGroup !== 'userId') {
              let detailArray: any[] = [];
              for (let i = 0; i < data.data.detail.length; i += 1) {
                const indexNum = detailArray.findIndex(function fn(element) {
                  return element.appliName === data.data.detail[i].appliName;
                });
                const { appliCount } = data.data.detail[i];
                if (indexNum !== -1) {
                  detailArray[indexNum].appliCount += appliCount;
                } else {
                  detailArray.push(JSON.parse(JSON.stringify(data.data.detail[i])));
                }
              }
              // detail sort
              detailArray = detailArray.sort(function fn(a: any, b: any) {
                return a.appliCount < b.appliCount ? 1 : -1;
              });
              if (detailArray.length > 4) {
                const otherItem = {
                  appliCount: 0,
                  appliName: 'その他',
                  category: 'その他',
                  keyCount: 0,
                };
                const mainData = detailArray.splice(0, 4);
                for (let i = 0; i < detailArray.length; i += 1) {
                  otherItem.appliCount = detailArray[i].appliCount;
                  otherItem.keyCount = detailArray[i].keyCount;
                }
                mainData.push(otherItem);
                self.hoverCategoryDetail = mainData;
                d3.select('#tooltip').style('top', `${event.offsetY - 30}px`);
              } else {
                self.hoverCategoryDetail = detailArray;
                d3.select('#tooltip').style(
                  'top',
                  `${event.offsetY + 45 - detailArray.length * 18}px`
                );
              }
            } else {
              d3.select('#tooltip').style('top', `${event.offsetY - 30}px`);
            }
            this.mouseoverFlg = true;
          })
          // Handling mouse out
          .on('mouseout', function (this: any) {
            // d3.select('#tooltip').style('opacity', 0);
            d3.select(this).transition().attr('d', self.arcGenerator).duration(100);
            this.mouseoverFlg = false;
          })
          .transition()
          .duration(750)
          .attrTween('d', self.arcTween);
        const pieChartLabel = this.svg
          .selectAll('slices')
          .data(this.pie(this.categoryChartData))
          .enter()
          .append('text')
          .html(function a(d: any) {
            if (d.data.key === 'null') {
              return '名称無し';
            }
            if (d.data.key === 'コミュニケーション') {
              return `<tspan x="0em" y="0em">コミュニ</tspan><tspan x="0em" y="1em">ケーション</tspan>`;
            }
            if (d.data.key === 'プレゼンテーション') {
              return `<tspan x="0em" y="0em">プレゼン</tspan><tspan x="0em" y="1em">テーション</tspan>`;
            }
            return d.data.key;
          })
          .attr('transform', function (d: any) {
            return `translate(${self.textArc.centroid(d)[0]},${self.textArc.centroid(d)[1] + 5})`;
          })
          .style('text-anchor', 'middle')
          .style('font-size', 14)
          .on('mouseover', function (this: any, event: any, data: any) {
            d3.select(`.category-pie-${data.index}`)
              .transition()
              .attr('d', self.arcHover)
              .duration(200);
            // Adding tooltip
            d3.select('#tooltip')
              .transition()
              .duration(0)
              .style('left', `${event.offsetX}px`)
              .style('top', `${event.offsetY}px`)
              .style('opacity', 1);
            self.hoverCategory = data.data.key;
            self.hoverCategoryTime = data.data.value;
          });
        pieChartLabel.attr('fill', function a(d: any, i: number) {
          const textWidth = pieChartLabel.nodes()[i].getBBox().width;
          let checkLabelHeight = 0;
          if (d.data.key === 'インターネット' || d.data.key === 'プレゼンテーション') {
            checkLabelHeight = 75;
          } else {
            checkLabelHeight = 50;
          }
          if (self.textAppear(d.data) > textWidth && self.textAppear(d.data) > checkLabelHeight) {
            return 'black';
          }
          return 'none';
        });
        const svgImage = this.svg
          .selectAll('slices')
          .data(this.pie(this.categoryChartData))
          .enter()
          .append('g')
          .attr('transform', function (d: any) {
            return `translate(${
              self.textArc.centroid(d)[0] - 5
            },${self.textArc.centroid(d)[1] - 25})`;
          })
          // .append('svg:image')
          .append(function a(d: any) {
            if (d.data.key !== 'Voice X') {
              return document.createElementNS('http://www.w3.org/2000/svg', 'svg:image');
            }
            return document.createElementNS('http://www.w3.org/2000/svg', 'g');
          })
          .attr('xlink:href', function (d: any) {
            if (d.data.key === '書類') {
              return self.imageDocument;
            }
            if (d.data.key === 'インターネット') {
              return self.imageInternet;
            }
            if (d.data.key === 'Web会議') {
              return self.imageWebConference;
            }
            if (d.data.key === 'コミュニケーション') {
              return self.imageCommunication;
            }
            if (d.data.key === 'クリエイティブ') {
              return self.imageCreative;
            }
            if (d.data.key === 'プレゼンテーション') {
              return self.imagePresentation;
            }
            if (d.data.key === 'その他') {
              return self.imageOther;
            }
            return self.imageOther;
          })
          .attr('width', 20)
          .attr('height', 20)
          .attr('y', -5);
        // VoiceXの場合のアイコン追加
        const svgImageVoiceX = this.svg
          .selectAll('slices')
          .data(this.pie(this.categoryChartData))
          .enter()
          .append('g')
          .attr('transform', function a(d: any) {
            return `translate(${
              self.textArc.centroid(d)[0] - 5
            },${self.textArc.centroid(d)[1] - 25})`;
          })
          .html(function a(d: any) {
            if (d.data.key === 'Voice X') {
              return self.imageVoicex;
            }
            return '';
          });
        // displayを設定
        svgImage.attr('display', function a(d: any, i: number) {
          const textWidth = pieChartLabel.nodes()[i].getBBox().width;
          let checkLabelHeight = 0;
          if (d.data.key === 'インターネット' || d.data.key === 'プレゼンテーション') {
            checkLabelHeight = 75;
          } else {
            checkLabelHeight = 50;
          }
          if (self.textAppear(d.data) > textWidth && self.textAppear(d.data) > checkLabelHeight) {
            return '';
          }
          return 'none';
        });
        // voiceXのDisplayを設定
        svgImageVoiceX.attr('display', function fn(d: any, i: number) {
          const textWidth = pieChartLabel.nodes()[i].getBBox().width;
          const checkLabelHeight = 50;
          if (self.textAppear(d.data) > textWidth && self.textAppear(d.data) > checkLabelHeight) {
            return '';
          }
          return 'none';
        });
      }
      self.categoryDataFlg = false;
      self.categoryDataMaxFlg = false;
    },
    firstGraph() {
      const self = this;
      if (self.categoryDataFlg && self.categoryDataMaxFlg) {
        if (self.svg) {
          d3.select('#category-pie-graph').remove();
          d3.select('#category-legend').remove();
        }
        this.color = d3
          .scaleOrdinal()
          .range([
            '#FFC3CE',
            '#F8BCE6',
            '#F5CDF5',
            '#F8E0F8',
            '#E1DDFA',
            '#F6F5FC',
            '#F1F1F2',
            'E6E6E7',
          ]);
        this.radius = Math.min(this.width, this.height) / 2;
        this.pie = d3
          .pie()
          .sort(null)
          .value(function a(d: any) {
            return d.value;
          });
        this.arcHover = d3
          .arc()
          .innerRadius(0)
          .outerRadius(this.radius - 25);
        // Building arcs
        this.arcGenerator = d3
          .arc()
          .innerRadius(0)
          .outerRadius(this.radius - 30);
        this.svg = d3
          .select('#pie-chart')
          .append('svg')
          .attr('id', 'category-pie-graph')
          .attr('viewBox', `${-this.width / 2} ${-this.height / 2} ${this.width} ${this.height}`)
          .on('mousemove', function a() {
            d3.select('#tooltip').style('opacity', 0);
          });
        self.svgLegend = d3
          .select('#category-pie-chart-legend')
          .append('svg')
          .attr('id', 'category-legend')
          .style('padding-left', 15)
          .style('padding-top', 10);
        self.sliceOrigin = self.svg.append('g').attr('class', 'slices');
        this.svg.append('g').attr('class', 'labels');
        this.svg.append('g').attr('class', 'lines');
        self.outerArc = d3
          .arc()
          .outerRadius(this.radius * 0.9)
          .innerRadius(this.radius * 0.9);
        self.labelArc = d3.arc().outerRadius(170).innerRadius(170);
        self.textArc = d3
          .arc()
          .outerRadius(this.radius - this.radius / 3)
          .innerRadius(this.radius - this.radius / 3);
        this.drawGraph();
      }
    },
    // },
    // midAngle(d: any) {
    //   return d.startAngle + (d.endAngle - d.startAngle) / 2;
    // },
    textAppear(data: any) {
      return Math.floor(1.4 * this.radius * 3.14 * (data.value / this.categoryChartDataMax));
      // return Math.floor(1.4 * this.radius * 3.14 * (data.value.value / this.categoryChartDataMax));
    },
  },
});
