
































import * as d3 from 'd3';
import svgz from 'svg-z-order';
import Vue, { PropType } from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'TotalStackedBarAndLineGraph',
  props: {
    chartX: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    divisionNumSc: {
      type: Number,
      default: 0,
    },
    divisionNumVoicex: {
      type: Number,
      default: 0,
    },
    finishFlg: {
      type: Boolean,
    },
    mainAppArray: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    scagentData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    scagentDataMax: {
      type: Number,
      default: 0,
    },
    scagentTimeMax: {
      type: Number,
      default: 0,
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    voicexCountMax: {
      type: Number,
      default: 0,
    },
    voicexData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    voicexDataMax: {
      type: Number,
      default: 0,
    },
    voicexTimeMax: {
      type: Number,
      default: 0,
    },
  },
  data(): {
    colorBlue: string;
    colorBlue2: string;
    colorBlue3: string;
    colorBlue4: string;
    colorGrey1: string;
    colorGrey2: string;
    colorGrey3: string;
    // colorGrey4: string;
    colorPink: string;
    colorPink2: string;
    svg: any;
    svgG: any;
    svgGG: any;
    svgPath: any;
    xAxis: any;
    xAxisEl: any;
    xScale: any;
    y1Axis: any;
    y1AxisEl: any;
    y2Axis: any;
    y1Scale: any;
    y2Scale: any;
    y2AxisEl: any;
    y2LabelCheck: boolean;
    margin: any;
    height: number;
    width: number;
    mainAppChartArray: any[];
    barMaxHeight: number;
    barHeightNum: number;
    SCAgentData: any[];
    SCAgentDataMax: number;
    usageGraphX: any[];
    VoiceXData: any[];
    VoiceXDataMax: number;
    svgLine1: any;
    svgLine2: any;
    svgDot1: any;
    svgDot2: any;
    usageGraphXFlg: boolean;
    SCAgentDataFlg: boolean;
    // SCAgentDataMaxFlg: boolean;
    VoiceXDataFlg: boolean;
    // VoiceXDataMaxFlg: boolean;
    d3Y1MaxLength: number;
    d3Y2MaxLength: number;
    y1Label: string;
    y2Label: string;
    graphClickFlg: boolean;
    xLabel: string;
    hoverTotalNum: number;
    hoverMainDataNum: number;
    hoverOtherDataNum: number;
    hoverTotalCount: number;
    hoverTotalTime: number;
  } {
    return {
      SCAgentData: [],
      SCAgentDataFlg: false,
      SCAgentDataMax: 0,
      // SCAgentDataMaxFlg: false,
      VoiceXData: [],
      VoiceXDataFlg: false,
      VoiceXDataMax: 0,
      // VoiceXDataMaxFlg: false,
      barHeightNum: 0,
      barMaxHeight: 0,
      colorBlue: '#8EC7F2',
      colorBlue2: '#B1CBE1',
      colorBlue3: '#669BFF',
      colorBlue4: '#A6CFED',
      // colorGrey1: '#AAAAAA',
      colorGrey1: '#CCCCCC',
      colorGrey2: '#AAAAAA',
      colorGrey3: '#EAEAEA',
      // colorGrey4: '#e6e6e6',
      colorPink: '#FF72B8',
      colorPink2: '#FFE4E1',
      d3Y1MaxLength: 0,
      d3Y2MaxLength: 0,
      graphClickFlg: false,
      height: 413,
      hoverMainDataNum: 0,
      hoverOtherDataNum: 0,
      hoverTotalCount: 0,
      hoverTotalNum: 0,
      hoverTotalTime: 0,
      mainAppChartArray: [],
      margin: {},
      svg: null,
      svgDot1: null,
      svgDot2: null,
      svgG: null,
      svgGG: null,
      svgLine1: null,
      svgLine2: null,
      svgPath: null,
      usageGraphX: [],
      usageGraphXFlg: false,
      width: 853,
      xAxis: null,
      xAxisEl: null,
      xLabel: '',
      xScale: null,
      y1Axis: null,
      y1AxisEl: null,
      y1Label: '',
      y1Scale: null,
      y2Axis: null,
      y2AxisEl: null,
      y2Label: '(件)',
      y2LabelCheck: false,
      y2Scale: null,
    };
  },
  computed: {
    returnAppliTime() {
      return function a(this: any, time: number, type: string) {
        let callTime = 0;
        if (type === 'tel') {
          callTime = time / 1000 / this.divisionNumVoicex;
        } else {
          callTime = (time * 30) / this.divisionNumSc;
        }
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        return `${Math.round(callTime * 100) / 100}秒`;
      };
    },
  },
  watch: {
    chartX(newValue) {
      if (newValue) {
        this.usageGraphX = newValue;
        // this.usageGraphXFlg = true;
        // this.drawChart();
      }
    },
    finishFlg(newValue) {
      if (newValue) {
        this.drawChart();
      }
    },
    mainAppArray(newValue) {
      if (newValue) {
        this.mainAppChartArray = newValue;
      }
    },
    scagentData(newValue) {
      if (newValue) {
        this.SCAgentData = newValue;
        // this.SCAgentDataFlg = true;
        // this.drawChart();
      }
    },
    scagentDataMax(newValue) {
      if (newValue) {
        this.SCAgentDataMax = newValue;
        // this.SCAgentDataMaxFlg = true;
        // this.drawChart();
      }
    },
    // selectedTarget: {
    //   async handler(newValue) {
    //     if (newValue) {
    //       this.drawChart();
    //     }
    //   },
    //   deep: true,
    // },
    voicexData(newValue) {
      if (newValue) {
        this.VoiceXData = newValue;
        // this.VoiceXDataFlg = true;
        // this.drawChart();
      }
    },
    voicexDataMax(newValue) {
      if (newValue) {
        this.VoiceXDataMax = newValue;
        // this.VoiceXDataMaxFlg = true;
        // this.drawChart();
      }
    },
  },
  mounted() {
    // this.drawChart();
    this.svg = d3
      .select('.iii')
      .append('svg')
      .attr('id', 'total-app-stack-bar-and-line')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('id', 'total-stacked-bar-and-line');
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    drawBar(x: number, y: number, w: number, h: number, r: number, f: any) {
      // console.log('x', x);
      // console.log('y', y);
      // console.log('w', w);
      // console.log('h', h);
      // console.log('r', r);
      // Flag for sweep:
      let f1: number;
      if (f === undefined) {
        f1 = 1;
      } else {
        f1 = f;
      }
      const x0 = x + r;
      const x1 = x + w - r;
      const y0 = y - h + r;
      // just for convenience (slightly different than above):
      const l = 'L';
      const a = 'A';
      let parts: any[];
      if (h > 0) {
        parts = [
          'M',
          x,
          y,
          l,
          x,
          y0,
          a,
          r,
          r,
          0,
          0,
          f1,
          x0,
          y - h,
          l,
          x1,
          y - h,
          a,
          r,
          r,
          0,
          0,
          f1,
          x + w,
          y0,
          l,
          x + w,
          y,
          'Z',
        ];
      } else {
        const newx0 = x + w;
        const newy0 = y - h;
        parts = ['M', x, y, l, x, newy0, l, newx0, y - h, l, x + w, y, 'Z'];
      }
      return parts.join(' ');
    },
    drawChart() {
      const self = this;
      // if (this.usageGraphXFlg && this.SCAgentDataFlg && this.VoiceXDataFlg) {
      if (this.finishFlg) {
        if (self.selectedTarget.selectedGroup !== 'userId') {
          self.margin = { bottom: 20, left: 30, right: 30, top: 10 };
          self.height = 413;
          self.width = 853;
          if (self.svg) {
            d3.select('#total-stacked-bar-and-line').remove();
            d3.select('#stacked-bar-legend-1').remove();
            d3.select('#stacked-bar-legend-2').remove();
          }
          self.svg = d3
            .select('.iii')
            .append('svg')
            .attr('id', 'total-app-stack-bar-and-line')
            .attr('viewBox', `0 0 ${self.width} ${self.height}`)
            .attr('id', 'total-stacked-bar-and-line')
            // .on('mousemove', function fn(this: any, event: any, data: any) {
            .on('mousemove', function fn() {
              d3.select('#total-stack-tooltip').style('opacity', 0);
            });
          this.svgG = this.svg.append('g').attr('transform', `translate(${0},${0})`);
          const x0Domain: string[] = this.usageGraphX;
          // 追加分
          let d3Y1Max = 0;
          // if (self.voicexTimeMax / 1000 >= self.scagentTimeMax * 30) {
          if (
            self.voicexTimeMax / 1000 / this.divisionNumVoicex >=
            (self.scagentTimeMax * 30) / this.divisionNumSc
          ) {
            d3Y1Max = self.voicexTimeMax / 1000 / this.divisionNumVoicex;
          } else {
            d3Y1Max = (self.scagentTimeMax * 30) / this.divisionNumSc;
          }
          // d3Y1Max /= this.divisionNumVoicex;
          if (d3Y1Max < 1) {
            d3Y1Max = 1;
          }
          self.y1Label = '';
          if (d3Y1Max < 60) {
            self.y1Label = '(秒)';
          } else if (d3Y1Max >= 60 && d3Y1Max < 3600) {
            self.y1Label = '(分)';
            const callRem = Math.floor(d3Y1Max % 60) / 60;
            d3Y1Max = Math.floor((d3Y1Max % 3600) / 60) + callRem;
          } else if (d3Y1Max >= 3600) {
            self.y1Label = '(時間)';
            const callRem = Math.floor(d3Y1Max % 60) / 60 / 360;
            const callMin = Math.floor((d3Y1Max % 3600) / 60) / 60;
            d3Y1Max = Math.floor(d3Y1Max / 3600) + callMin + callRem;
          }
          // Max時間の小数点繰り上げ
          d3Y1Max = Math.ceil(d3Y1Max);
          // グラフの左側の軸の幅を取得
          self.d3Y1MaxLength = d3Y1Max.toString().length === 1 ? 2 : d3Y1Max.toString().length;
          self.d3Y1MaxLength = self.d3Y1MaxLength * 8 + 15;
          //
          let countMax = self.voicexCountMax / self.divisionNumVoicex;
          if (countMax < 5) {
            countMax = 5;
          }
          self.d3Y2MaxLength = countMax.toString().length === 1 ? 2 : countMax.toString().length;
          self.d3Y2MaxLength = self.d3Y2MaxLength * 8 + 13;
          self.y1Scale = d3
            .scaleLinear()
            .range([self.height - 40, 15])
            // 追加
            .domain([0, d3Y1Max]);
          self.y2Scale = d3
            .scaleLinear()
            .range([self.height - 40, 15])
            // 追加
            .domain([0, countMax]);
          // 軸設定
          self.xScale = d3
            .scaleBand()
            // .range([0, self.width - 80])
            .range([self.d3Y1MaxLength, self.width - self.d3Y2MaxLength - 20])
            .domain(x0Domain);
          self.xAxis = d3.axisBottom(self.xScale);
          self.y1Axis = d3
            .axisLeft(self.y1Scale)
            .ticks(5)
            // 追加
            .tickSize(-self.width + self.d3Y1MaxLength + self.d3Y2MaxLength + 15);
          self.y2Axis = d3.axisRight(self.y2Scale).ticks(5).tickSize(0);
          self.y1AxisEl = self.svgG.append('g').attr('class', 'y1 axis stack').call(self.y1Axis);
          // 追加
          self.y1AxisEl.attr('transform', `translate(${self.d3Y1MaxLength}, 10)`);
          self.y1AxisEl
            .append('text')
            .attr('x', -18)
            .attr('y', 5)
            .attr('fill', self.colorGrey2)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // y1 axis 枠線消す
          d3.select('.y1.axis.stack').select('.domain').remove();
          // y1 axis color change
          d3.select('.y1.axis.stack')
            .selectAll('.tick')
            .select('line')
            .style('stroke', this.colorGrey1);
          // 横線少しずらす
          d3.select('.y1.axis')
            .selectAll('.tick')
            .select('line')
            .attr('transform', 'translate(10,0)');
          self.y2AxisEl = self.svgG.append('g').attr('class', 'y2 axis stack').call(self.y2Axis);
          // y2 axis 枠線消す
          d3.select('.y2.axis.stack').select('.domain').remove();
          self.xAxisEl = self.svgG
            .append('g')
            .attr('class', 'x axis stack')
            .attr('transform', `translate(10,${self.height - 30})`);
          // data設定
          self.svgGG = self.svgG.selectAll(null).data(self.SCAgentData).enter().append('g');
          self.svgPath = self.svgGG
            .selectAll('path')
            .data(function a(d: any) {
              return d.mainAndOther;
            })
            .enter()
            .append('path')
            .attr('d', function a(d: any, i: number) {
              self.barMaxHeight = self.y1Scale(0);
              // 追加分
              let barData = (d.value * 30) / self.divisionNumSc;
              if (self.y1Label === '(分)') {
                const callRem = Math.floor(barData % 60) / 60;
                barData = Math.floor((barData % 3600) / 60) + callRem;
              } else if (self.y1Label === '(時間)') {
                const callRem = Math.floor(barData % 60) / 60 / 360;
                const callMin = Math.floor((barData % 3600) / 60) / 60;
                barData = Math.floor(barData / 3600) + callMin + callRem;
              }
              if (i === 0) {
                self.barHeightNum = self.barMaxHeight - self.y1Scale(barData);
              } else {
                self.barHeightNum += self.barMaxHeight - self.y1Scale(barData);
              }
              // 棒グラフの丸み
              let r = 5;
              if (self.barHeightNum < 5) {
                r = self.barHeightNum * 0.9;
              }
              return self.drawBar(
                self.xScale(d.indexNum) + self.xScale.bandwidth() / 4 + 10,
                self.barMaxHeight + 10,
                self.xScale.bandwidth() / 2,
                self.barHeightNum,
                r,
                1
              );
            })
            .attr('fill', function a(d: any, i: number) {
              if (i === 0) {
                // return 'steelblue';
                return self.colorBlue;
              }
              // return 'grey';
              return self.colorGrey1;
            })
            .attr('class', function a(d: any, i: number) {
              return `total-stacked-bar-${d.indexNum}-${i} stacked-bar-chart-${i}`;
            })
            .on('mousemove', function a(this: any, event: any, data: any) {
              const mainData: any = d3.select(`.total-stacked-bar-${data.indexNum}-0`).datum();
              const otherData: any = d3.select(`.total-stacked-bar-${data.indexNum}-1`).datum();
              self.hoverTotalNum = mainData.totalNum;
              self.hoverMainDataNum = mainData.value;
              self.hoverOtherDataNum = otherData.value;
              d3.select('#total-stack-tooltip')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY}px`)
                .style('opacity', 1);
            })
            .on('mouseout', function f(this: any, event: any, data: any) {
              d3.select('#total-stack-tooltip').style('opacity', 0);
            });
          for (let i = 0; i < x0Domain.length; i += 1) {
            for (let j = 1; j < 2; j += 1) {
              if (document.querySelector(`.total-stacked-bar-${i}-${j}`)) {
                const hoge = d3.select(`.total-stacked-bar-${i}-${j}`);
                svgz.element(hoge.node()).toBottom();
              }
            }
          }
          self.svgLine1 = self.svgG.append('path');
          self.svgLine2 = self.svgG.append('path');
          self.svgDot1 = self.svgG
            .selectAll('myCircles')
            .data(self.VoiceXData)
            .enter()
            .append('circle')
            // .attr('class', function fn(d: any, i: number) {
            .attr('class', function fn(d: any) {
              return `total-dot-count-${d.indexNum} total-dot-count`;
            })
            .on('mouseover', function fn(this: any, event: any, data: any) {
              self.hoverTotalCount =
                Math.round((data.aggregate.count / self.divisionNumVoicex) * 100) / 100;
              d3.select('#total-stack-tooltip-count')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY + 50}px`)
                .style('opacity', 1);
            })
            // .on('mouseout', function (this: any, event: any, data: any) {
            .on('mouseout', function fn() {
              d3.select('#total-stack-tooltip-count').style('opacity', 0);
            });
          self.svgDot2 = self.svgG
            .selectAll('myCircles2')
            .data(self.VoiceXData)
            .enter()
            .append('circle')
            .attr('class', function (d: any, i: number) {
              return `total-dot-time-${d.indexNum} total-dot-time`;
            })
            .on('mouseover', function (this: any, event: any, data: any) {
              self.hoverTotalTime = data.aggregate.time;
              d3.select('#total-stack-tooltip-time')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY + 50}px`)
                .style('opacity', 1);
            })
            .on('mouseout', function (this: any, event: any, data: any) {
              d3.select('#total-stack-tooltip-time').style('opacity', 0);
            });
          // legend1
          let legendVals: string[] = [];
          if (self.divisionNumVoicex === 1) {
            legendVals = ['通話件数', '通話時間'];
          } else {
            legendVals = ['平均通話件数', '平均通話時間'];
          }
          // const legendVals = ['平均通話件数', '平均通話時間'];
          const legend = d3
            .select('.total-stacked-bar-and-line-label1')
            .append('svg')
            // .attr('height', 30)
            // .attr('width', 500)
            // .style('padding-left', 10)
            .style('padding-top', 5)
            .attr('id', 'stacked-bar-legend-1')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legendG = legend
            .selectAll('hoge')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function (_: any, i: number) {
              return `total-stacked-bar-and-line-legend-${i}`;
            });
          d3.select('.total-stacked-bar-and-line-legend-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', '#E75685');
            .attr('fill', self.colorPink);
          d3.select('.total-stacked-bar-and-line-legend-0')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            // .attr('fill', '#E75685');
            .attr('fill', self.colorPink);
          d3.select('.total-stacked-bar-and-line-legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue3);
          d3.select('.total-stacked-bar-and-line-legend-1')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue3);
          legendG
            .append('text')
            .attr('x', 50)
            .attr('y', 13)
            // .attr('dy', '.15em')
            .text(function fn(d: any, i: number) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15)
            .style('font-family', 'Noto Sans JP');
          const padding = 20;
          legendG.attr('transform', function (d: any, i: number) {
            return `translate(${
              d3.sum(legendVals, function (e: any, j: number) {
                if (j < i) {
                  return legendG.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          // legend2
          const legend2 = d3
            .select('.total-stacked-bar-and-line-label2')
            .append('svg')
            // .attr('height', 30)
            // .attr('width', 500)
            // .style('padding-left', 10)
            .attr('id', 'stacked-bar-legend-2')
            .attr('viewBox', `0 0 ${840} ${20}`);
          // legendVals2
          let legendVals2: string[] = [];
          if (this.divisionNumSc === 1) {
            legendVals2 = ['主要アプリ稼働時間', 'PC稼働時間'];
          } else {
            legendVals2 = ['平均主要アプリ稼働時間', 'PC平均稼働時間'];
          }
          // const legendVals2 = ['平均主要アプリ稼働時間', 'PC平均稼働時間'];
          const legendG2 = legend2
            .selectAll('hoge')
            .data(legendVals2)
            .enter()
            .append('g')
            .attr('class', function fn(_: any, i: number) {
              return `total-stacked-bar-and-line-legend-2-${i}`;
            });
          d3.select('.total-stacked-bar-and-line-legend-2-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue);
          d3.select('.total-stacked-bar-and-line-legend-2-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'grey');
            .attr('fill', self.colorGrey1);
          legendG2
            .append('text')
            .attr('x', 50)
            .attr('y', 13)
            // .attr('dy', '.15em')
            .text(function fn(d: any, i: number) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15)
            .style('font-family', 'Noto Sans JP');
          legendG2.attr('transform', function fn(d: any, i: number) {
            return `translate(${
              d3.sum(legendVals2, function fn1(e: any, j: number) {
                if (j < i) {
                  return legendG2.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          this.updateChart(self.width);
        } else {
          self.margin = { bottom: 20, left: 30, right: 30, top: 10 };
          self.height = 413;
          self.width = 853;
          if (self.svg) {
            d3.select('#total-stacked-bar-and-line').remove();
            d3.select('#stacked-bar-legend-1').remove();
            d3.select('#stacked-bar-legend-2').remove();
          }
          self.svg = d3
            .select('.iii')
            .append('svg')
            .attr('id', 'total-app-stack-bar-and-line')
            // .attr('width', '100%')
            // .attr('height', 400)
            .attr('viewBox', `0 0 ${self.width} ${self.height}`)
            .attr('id', 'total-stacked-bar-and-line')
            .on('mousemove', function fn(this: any, event: any, data: any) {
              d3.select('#total-stack-tooltip').style('opacity', 0);
              // d3.select('#total-stack-tooltip-count').style('opacity', 0);
              // d3.select('#total-stack-tooltip-time').style('opacity', 0);
            });
          this.svgG = this.svg.append('g').attr('transform', `translate(${0},${0})`);
          const x0Domain: string[] = this.usageGraphX;
          // 追加分
          let d3Y1Max = 0;
          if (self.voicexTimeMax / 1000 >= self.scagentTimeMax * 30) {
            d3Y1Max = self.voicexTimeMax / 1000;
          } else {
            d3Y1Max = self.scagentTimeMax * 30;
          }
          if (d3Y1Max < 1) {
            d3Y1Max = 1;
          }
          // d3Y1Max /= this.divisionNum;
          self.y1Label = '';
          if (d3Y1Max < 60) {
            self.y1Label = '(秒)';
          } else if (d3Y1Max >= 60 && d3Y1Max < 3600) {
            self.y1Label = '(分)';
            const callRem = Math.floor(d3Y1Max % 60) / 60;
            d3Y1Max = Math.floor((d3Y1Max % 3600) / 60) + callRem;
          } else if (d3Y1Max >= 3600) {
            self.y1Label = '(時間)';
            const callRem = Math.floor(d3Y1Max % 60) / 60 / 360;
            const callMin = Math.floor((d3Y1Max % 3600) / 60) / 60;
            d3Y1Max = Math.floor(d3Y1Max / 3600) + callMin + callRem;
          }
          // Max時間の小数点繰り上げ
          d3Y1Max = Math.ceil(d3Y1Max);
          // グラフの左側の軸の幅を取得
          self.d3Y1MaxLength = d3Y1Max.toString().length === 1 ? 2 : d3Y1Max.toString().length;
          self.d3Y1MaxLength = self.d3Y1MaxLength * 8 + 15;
          //
          let countMax = self.voicexCountMax / self.divisionNumVoicex;
          if (countMax < 5) {
            countMax = 5;
          }
          self.d3Y2MaxLength = countMax.toString().length === 1 ? 2 : countMax.toString().length;
          self.d3Y2MaxLength = self.d3Y2MaxLength * 8 + 13;
          self.y1Scale = d3
            .scaleLinear()
            .range([self.height - 40, 15])
            // 追加
            .domain([0, d3Y1Max]);
          self.y2Scale = d3
            .scaleLinear()
            .range([self.height - 40, 15])
            // 追加
            .domain([0, countMax]);
          // 軸設定
          self.xScale = d3
            .scaleBand()
            // .range([0, self.width - 80])
            .range([self.d3Y1MaxLength, self.width - self.d3Y2MaxLength - 20])
            .domain(x0Domain);
          self.xAxis = d3.axisBottom(self.xScale);
          self.y1Axis = d3
            .axisLeft(self.y1Scale)
            .ticks(5)
            // 追加
            .tickSize(-self.width + self.d3Y1MaxLength + self.d3Y2MaxLength + 15);
          self.y2Axis = d3.axisRight(self.y2Scale).ticks(5).tickSize(0);
          self.y1AxisEl = self.svgG.append('g').attr('class', 'y1 axis stack').call(self.y1Axis);
          // 追加
          self.y1AxisEl.attr('transform', `translate(${self.d3Y1MaxLength}, 10)`);
          self.y1AxisEl
            .append('text')
            .attr('x', -18)
            .attr('y', 5)
            .attr('fill', self.colorGrey2)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // y1 axis 枠線消す
          d3.select('.y1.axis.stack').select('.domain').remove();
          // y1 axis color change
          // d3.select('.y1.axis.stack').selectAll('.tick').select('line').style('stroke', 'grey');
          d3.select('.y1.axis.stack')
            .selectAll('.tick')
            .select('line')
            .style('stroke', self.colorGrey1);
          // 横線少しずらす
          d3.select('.y1.axis')
            .selectAll('.tick')
            .select('line')
            .attr('transform', 'translate(10,0)');
          self.y2AxisEl = self.svgG.append('g').attr('class', 'y2 axis stack').call(self.y2Axis);
          // y2 axis 枠線消す
          d3.select('.y2.axis.stack').select('.domain').remove();
          self.xAxisEl = self.svgG
            .append('g')
            .attr('class', 'x axis stack')
            .attr('transform', `translate(10,${self.height - 30})`);
          // data設定
          self.svgGG = self.svgG.selectAll(null).data(self.SCAgentData).enter().append('g');
          self.svgPath = self.svgGG
            .selectAll('path')
            .data(function a(d: any) {
              return d.mainAndOther;
            })
            .enter()
            .append('path')
            .attr('d', function a(d: any, i: number) {
              self.barMaxHeight = self.y1Scale(0);
              // 追加分
              // let barData = (d.value * 30) / self.divisionNum;
              let barData = d.value * 30;
              if (self.y1Label === '(分)') {
                const callRem = Math.floor(barData % 60) / 60;
                barData = Math.floor((barData % 3600) / 60) + callRem;
              } else if (self.y1Label === '(時間)') {
                const callRem = Math.floor(barData % 60) / 60 / 360;
                const callMin = Math.floor((barData % 3600) / 60) / 60;
                barData = Math.floor(barData / 3600) + callMin + callRem;
              }
              if (i === 0) {
                self.barHeightNum = self.barMaxHeight - self.y1Scale(barData);
              } else {
                self.barHeightNum += self.barMaxHeight - self.y1Scale(barData);
              }
              // 棒グラフの丸み
              let r = 5;
              if (self.barHeightNum < 5) {
                r = self.barHeightNum * 0.9;
              }
              return self.drawBar(
                self.xScale(d.indexNum) + self.xScale.bandwidth() / 4 + 10,
                self.barMaxHeight + 10,
                self.xScale.bandwidth() / 2,
                self.barHeightNum,
                r,
                1
              );
            })
            .attr('fill', function (d: any, i: number) {
              if (i === 0) {
                // return 'steelblue';
                return self.colorBlue;
              }
              // return 'grey';
              return self.colorGrey1;
            })
            .attr('class', function (d: any, i: number) {
              return `total-stacked-bar-${d.indexNum}-${i} stacked-bar-chart-${i}`;
            })
            .on('mousemove', function (this: any, event: any, data: any) {
              const mainData: any = d3.select(`.total-stacked-bar-${data.indexNum}-0`).datum();
              const otherData: any = d3.select(`.total-stacked-bar-${data.indexNum}-1`).datum();
              self.hoverTotalNum = mainData.totalNum;
              self.hoverMainDataNum = mainData.value;
              self.hoverOtherDataNum = otherData.value;
              d3.select('#total-stack-tooltip')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY}px`)
                .style('opacity', 1);
            })
            .on('mouseout', function (this: any, event: any, data: any) {
              d3.select('#total-stack-tooltip').style('opacity', 0);
            });
          for (let i = 0; i < x0Domain.length; i += 1) {
            for (let j = 1; j < 2; j += 1) {
              if (document.querySelector(`.total-stacked-bar-${x0Domain[i]}-${j}`)) {
                const hoge = d3.select(`.total-stacked-bar-${x0Domain[i]}-${j}`);
                svgz.element(hoge.node()).toBottom();
              }
            }
          }
          self.svgLine1 = self.svgG.append('path');
          self.svgLine2 = self.svgG.append('path');
          self.svgDot1 = self.svgG
            .selectAll('myCircles')
            .data(self.VoiceXData)
            .enter()
            .append('circle')
            // .attr('class', function fn(d: any, i: number) {
            .attr('class', function fn(d: any) {
              return `total-dot-count-${d.indexNum} total-dot-count`;
            })
            .on('mouseover', function fn(this: any, event: any, data: any) {
              self.hoverTotalCount =
                Math.round((data.aggregate.count / self.divisionNumVoicex) * 100) / 100;
              d3.select('#total-stack-tooltip-count')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY + 50}px`)
                .style('opacity', 1);
            })
            // .on('mouseout', function fn(this: any, event: any, data: any) {
            .on('mouseout', function fn() {
              d3.select('#total-stack-tooltip-count').style('opacity', 0);
            });
          self.svgDot2 = self.svgG
            .selectAll('myCircles2')
            .data(self.VoiceXData)
            .enter()
            .append('circle')
            .attr('class', function fn(d: any) {
              return `total-dot-time-${d.indexNum} total-dot-time`;
            })
            .on('mouseover', function fn(this: any, event: any, data: any) {
              self.hoverTotalTime = data.aggregate.time;
              d3.select('#total-stack-tooltip-time')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX}px`)
                .style('top', `${event.offsetY + 50}px`)
                .style('opacity', 1);
            })
            // .on('mouseout', function fn(this: any, event: any, data: any) {
            .on('mouseout', function fn(this: any) {
              d3.select('#total-stack-tooltip-time').style('opacity', 0);
            });
          // legend1
          const legendVals = ['件数', '通話時間'];
          const legend = d3
            .select('.total-stacked-bar-and-line-label1')
            .append('svg')
            // .attr('height', 30)
            // .attr('width', 500)
            // .style('padding-left', 10)
            .style('padding-top', 5)
            .attr('id', 'stacked-bar-legend-1')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legendG = legend
            .selectAll('hoge')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function fn1(_: any, i: number) {
              return `total-stacked-bar-and-line-legend-${i}`;
            });
          d3.select('.total-stacked-bar-and-line-legend-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', '#E75685');
            .attr('fill', self.colorPink);
          d3.select('.total-stacked-bar-and-line-legend-0')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            // .attr('fill', '#E75685');
            .attr('fill', self.colorPink);
          d3.select('.total-stacked-bar-and-line-legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue3);
          d3.select('.total-stacked-bar-and-line-legend-1')
            .append('circle')
            .attr('cx', 35)
            .attr('cy', 8)
            .attr('r', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue3);
          legendG
            .append('text')
            .attr('x', 50)
            .attr('y', 13)
            // .attr('dy', '.15em')
            // .text(function (d: any, i: number) {
            .text(function fn(d: any) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', '15px');
          const padding = 20;
          legendG.attr('transform', function fn(d: any, i: number) {
            return `translate(${
              d3.sum(legendVals, function fn1(e: any, j: number) {
                if (j < i) {
                  return legendG.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          // legend2
          const legend2 = d3
            .select('.total-stacked-bar-and-line-label2')
            .append('svg')
            // .attr('height', 30)
            // .attr('width', 500)
            // .style('padding-left', 10)
            .attr('id', 'stacked-bar-legend-2')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legendVals2 = ['主要アプリ稼働時間', 'PC稼働時間'];
          const legendG2 = legend2
            .selectAll('hoge')
            .data(legendVals2)
            .enter()
            .append('g')
            .attr('class', function fn(_: any, i: number) {
              return `total-stacked-bar-and-line-legend-2-${i}`;
            });
          d3.select('.total-stacked-bar-and-line-legend-2-0')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'blue');
            .attr('fill', self.colorBlue);
          d3.select('.total-stacked-bar-and-line-legend-2-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', 20)
            .attr('y', 4)
            .attr('width', 30)
            .attr('height', 8)
            // .attr('fill', 'grey');
            .attr('fill', self.colorGrey1);
          legendG2
            .append('text')
            .attr('x', 50)
            .attr('y', 13)
            // .attr('dy', '.15em')
            .text(function fn(d: any, i: number) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15);
          legendG2.attr('transform', function fn(d: any, i: number) {
            return `translate(${
              d3.sum(legendVals2, function fn1(e: any, j: number) {
                if (j < i) {
                  return legendG2.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          this.updateChart(self.width);
        }
      }
    },
    updateChart(width: number) {
      const self = this;
      self.xAxisEl.call(self.xAxis);
      // x axis 枠線消す
      d3.select('.x.axis.stack').select('.domain').remove();
      // x軸のクリックイベント追加
      d3.selectAll('.x .tick').on('click', function fn(this: any, d: any, value: any) {
        const index = value.mainAndOther[0].indexNum;
        if (self.graphClickFlg === false) {
          // barchartの色変更
          d3.selectAll('.stacked-bar-chart-0').style('fill', self.colorBlue2);
          d3.selectAll('.stacked-bar-chart-1').style('fill', self.colorGrey3);
          d3.select(`.total-stacked-bar-${index}-0`).style('fill', self.colorBlue);
          d3.select(`.total-stacked-bar-${index}-1`).style('fill', self.colorGrey1);
          // dotの色変更
          d3.selectAll('.total-dot-time').style('fill', self.colorBlue4);
          d3.selectAll('.total-dot-count').style('fill', self.colorPink2);
          // 選択したdotの色元通り
          d3.select(`.total-dot-time-${index}`).style('fill', self.colorBlue3);
          d3.select(`.total-dot-count-${index}`).style('fill', self.colorPink);
          // 線の色変える
          self.svgLine1.attr('stroke', self.colorPink2);
          self.svgLine2.attr('stroke', self.colorBlue4);
          // 選択したlabelの色変更
          d3.select(`.x-label-rect-${index}`).style('fill', 'black');
          d3.select(`.x-label-text-${index}`).style('fill', 'white');
          self.graphClickFlg = true;
        } else {
          d3.selectAll('.stacked-bar-chart-0').style('fill', self.colorBlue);
          d3.selectAll('.stacked-bar-chart-1').style('fill', self.colorGrey1);
          // dotの色変更
          d3.selectAll('.total-dot-time').style('fill', self.colorBlue3);
          d3.selectAll('.total-dot-count').style('fill', self.colorPink);
          // 線の色変える
          self.svgLine1.attr('stroke', self.colorPink);
          self.svgLine2.attr('stroke', self.colorBlue3);
          // 選択したlabelの色変更
          d3.select(`.x-label-rect-${index}`).style('fill', 'white');
          d3.select(`.x-label-text-${index}`).style('fill', 'black');
          self.graphClickFlg = false;
        }
      });
      // if (self.y2LabelCheck === false) {
      self.y2AxisEl
        // .attr('transform', `translate(${width - self.margin.right - self.margin.left}, 0)`)
        .attr('transform', `translate(${width - self.d3Y2MaxLength}, 10)`)
        .append('text')
        .attr('x', self.margin.right - 8)
        .attr('y', 5)
        .attr('fill', self.colorGrey2)
        .attr('text-anchor', 'end')
        .attr('font-size', '12px')
        .text(self.y2Label);
      self.y2LabelCheck = true;
      // } else {
      //   self.y2AxisEl.attr('transform', `translate(${width - self.margin.right}, 0)`);
      // }
      const line1: any = d3
        .line()
        .x(function fn(d: any, i: number) {
          return self.xScale(d.indexNum) + self.xScale.bandwidth() / 2 + 10;
        })
        .y(function fn(d: any) {
          return self.y2Scale(d.aggregate.count / self.divisionNumVoicex) + 10;
        });
      const line2: any = d3
        .line()
        .x(function fn(d: any, i: number) {
          return self.xScale(d.indexNum) + self.xScale.bandwidth() / 2 + 10;
        })
        .y(function fn(d: any) {
          // ここでtimeをグラフ用に算出
          // 追加分
          let barData = d.aggregate.time / 1000;
          if (self.selectedTarget.selectedGroup !== 'userId') {
            barData /= self.divisionNumVoicex;
          }
          if (self.y1Label === '(分)') {
            const callRem = Math.floor(barData % 60) / 60;
            barData = Math.floor((barData % 3600) / 60) + callRem;
          } else if (self.y1Label === '(時間)') {
            const callRem = Math.floor(barData % 60) / 60 / 360;
            const callMin = Math.floor((barData % 3600) / 60) / 60;
            barData = Math.floor(barData / 3600) + callMin + callRem;
          }
          return self.y1Scale(barData) + 10;
        });
      this.svgLine1
        .attr('fill', 'none')
        // .attr('stroke', '#E75685')
        .attr('stroke', self.colorPink)
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 3)
        .attr('d', line1(self.VoiceXData));
      this.svgLine2
        .attr('fill', 'none')
        // .attr('stroke', 'blue')
        .attr('stroke', self.colorBlue3)
        .attr('stroke-miterlimit', 1)
        .attr('stroke-width', 3)
        .attr('d', line2(self.VoiceXData));
      this.svgDot1
        // .attr('fill', '#E75685')
        .attr('fill', self.colorPink)
        .attr('stroke', 'none')
        .attr('cx', function fn(d: any, i: number) {
          return self.xScale(d.indexNum) + self.xScale.bandwidth() / 2 + 10;
        })
        .attr('cy', function fn(d: any) {
          return self.y2Scale(d.aggregate.count / self.divisionNumVoicex) + 10;
        })
        .attr('r', 8);
      this.svgDot2
        // .attr('fill', 'blue')
        .attr('fill', self.colorBlue3)
        .attr('stroke', 'none')
        .attr('cx', function fn(d: any, i: number) {
          return self.xScale(d.indexNum) + self.xScale.bandwidth() / 2 + 10;
        })
        .attr('cy', function fn(d: any) {
          // ここでtimeをグラフ用に算出
          // 追加分
          let barData = d.aggregate.time / 1000;
          if (self.selectedTarget.selectedGroup !== 'userId') {
            barData /= self.divisionNumVoicex;
          }
          if (self.y1Label === '(分)') {
            const callRem = Math.floor(barData % 60) / 60;
            barData = Math.floor((barData % 3600) / 60) + callRem;
          } else if (self.y1Label === '(時間)') {
            const callRem = Math.floor(barData % 60) / 60 / 360;
            const callMin = Math.floor((barData % 3600) / 60) / 60;
            barData = Math.floor(barData / 3600) + callMin + callRem;
          }
          return self.y1Scale(barData) + 10;
        })
        .attr('r', 8);
      this.svgG.select('.x.axis').selectAll('text').remove();
      this.svgG.select('.x.axis').selectAll('path').remove();
      this.svgG.select('.x.axis').selectAll('line').remove();
      const rect = this.svgG
        .select('.x.axis')
        .selectAll('.tick')
        .data(self.SCAgentData)
        .append('rect')
        .attr('width', 20)
        .attr('height', 20)
        .attr('x', -10)
        .attr('y', 5)
        .attr('rx', 5)
        .attr('fill', 'white')
        .attr('class', function a(d: any) {
          return `x-label-rect-${d.mainAndOther[0].indexNum}`;
        });
      const text = this.svgG
        .select('.x.axis')
        .selectAll('.tick')
        .data(self.SCAgentData)
        .append('text')
        .attr('y', 18)
        .attr('fill', 'black')
        .text(function fn(d: any, i: number) {
          return d.mainAndOther[0].indexNum;
        })
        .attr('class', function fn(d: any) {
          return `x-label-text-${d.mainAndOther[0].indexNum}`;
        });
      if (self.selectedPeriod === 'day') {
        self.xLabel = '(時)';
      } else if (self.selectedPeriod === 'week' || self.selectedPeriod === 'month') {
        self.xLabel = '(日)';
      } else if (self.selectedPeriod === 'year') {
        self.xLabel = '(月)';
      }
      const xLabelText = this.svgG
        .append('text')
        .attr('x', 810)
        .attr('y', 400)
        .attr('fill', self.colorGrey2)
        .attr('text-anchor', 'start')
        .attr('font-size', '12px')
        .text(self.xLabel);
      this.VoiceXDataFlg = false;
      // this.VoiceXDataMaxFlg = false;
      this.SCAgentDataFlg = false;
      // this.SCAgentDataMaxFlg = false;
      this.usageGraphXFlg = false;
    },
  },
});
