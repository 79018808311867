










































































































































































































































































































































































































































































































































































































































































































































































































































import {
  mdiPhoneIncoming,
  mdiPhoneMissed,
  mdiPhoneRemove,
  mdiAccountMultiple,
  mdiPhoneOutgoing,
  mdiAccountMultipleRemoveOutline,
  mdiMenuRightOutline,
  mdiTrendingDown,
  mdiTrendingUp,
} from '@mdi/js';
// import * as d3 from 'd3';
import dayjs from 'dayjs';
import Vue from 'vue';
import BarAndLineChart from '@/components/specific/DashboardVoiceXBarAndLineGraph.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const DashboardService = ServiceFactory.get('dashboard');

type chartItem = {
  key: string;
  value: {
    count: number;
    day: number;
    indexNum: number;
    time: number;
  }[];
};

type chartItemValue = {
  count: number;
  day: number;
  indexNum: number;
  time: number;
}[];

type responseChartData = {
  aggregate: {
    count: number;
    time: number;
  };
  term: {
    endAt: string;
    startAt: string;
  };
};

type responseTableData = {
  kind: string;
  time: string;
  transmit: {
    userId: string;
    userName: string;
  };
  arrive: {
    userId: string;
    userName: string;
  };
  duration: number;
  Linkedid: string;
};

// type responseRankingData = {
//   earlierPercent: number;
//   time: number;
//   user: {
//     userId: string;
//     userName: string;
//     image?: string;
//   };
// };

type earlierData = {
  arrive: {
    count: number;
    answerCount: number;
    answerRate: number;
    noanswerRate: number;
    avgAnswerTime: number;
    duration: number;
  };
  transmit: {
    count: number;
    answerCount: number;
    answerRate: number;
    noanswerRate: number;
    avgAnswerTime: number;
    duration: number;
  };
  extension: {
    count: number;
    answerCount: number;
    answerRate: number;
    noanswerRate: number;
    avgAnswerTime: number;
    duration: number;
  };
}[];

type earlierDataItem = {
  count: number;
  answerCount: number;
  answerRate: number;
  noanswerRate: number;
  avgAnswerTime: number;
  duration: number;
};

export default Vue.extend({
  name: 'VoiceXDashboard',
  components: {
    BarAndLineChart,
  },
  props: {
    selectedBeforeDate: {
      type: String,
      default: '',
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    selectedPhoneType: {
      type: String,
      default: '',
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    updateFlg: {
      type: Boolean,
    },
  },
  data(): {
    // calendarDate: any;
    chartData: chartItem[][];
    date: string;
    beforeDate: string;
    graphType: number;
    graphWidth: number;
    headers: (
      | {
          text: string;
          value: string;
          align: string;
        }
      | {
          text: string;
          value: string;
        }
    )[];
    headers2: {
      align: string;
      text: string;
      value: string;
    }[];
    headers3: {
      align: string;
      text: string;
      value: string;
    }[];
    // period: string;
    responseChartData: responseChartData[];
    responseChartDataBefore: responseChartData[];
    responseTableData: responseTableData[];
    responseUsageTableData: {
      did: any[];
      ext: any[];
    };
    search: string;
    searchUsage: string;
    switchBeforeDay: boolean;
    transmissionIcon: string;
    incomingIcon: string;
    innerLineIcon: string;
    phoneTotalCount: number;
    phoneTotalTime: number;
    phoneMissedIcon: string;
    phoneRemoveIcon: string;
    notInnerLineIcon: string;
    // rankingData: responseRankingData[];
    selectedChartDate: string;
    selectedChartPeriod: string;
    tableFilter: string;
    usageTableTab: string;
    innerLineData: responseChartData[];
    innerLineDataBefore: responseChartData[];
    incomingData: responseChartData[];
    incomingDataBefore: responseChartData[];
    transmissionData: responseChartData[];
    transmissionDataBefore: responseChartData[];
    totalIncomingNum: number;
    totalTransmissionNum: number;
    totalInnerLineNum: number;
    playbackIcon: string;
    totalIncomingIconFlg: boolean;
    totalTransmissionIconFlg: boolean;
    totalInnerLineIconFlg: boolean;
    phoneTotalCountIconFlg: boolean;
    phoneTotalTimeIconFlg: boolean;
    trendingDownIcon: string;
    trendingUpIcon: string;
    earlierArriveArray: {
      count?: number;
      answerCount?: number;
      answerRate?: number;
      noanswerRate?: number;
      avgAnswerTime?: number;
      duration?: number;
      upFlg: string;
    }[];
    earlierTotalArray: {
      totalCount?: number;
      upFlg: string;
      arriveCount?: number;
      transmitCount?: number;
      extensionCount?: number;
      totalDuration?: number;
    }[];
    earlierTransmitArray: {
      count?: number;
      answerCount?: number;
      answerRate?: number;
      noanswerRate?: number;
      avgAnswerTime?: number;
      duration?: number;
      upFlg: string;
    }[];
    earlierExtensionArray: {
      count?: number;
      answerCount?: number;
      answerRate?: number;
      noanswerRate?: number;
      avgAnswerTime?: number;
      duration?: number;
      upFlg: string;
    }[];
    // earlierArriveCount: number;
    // earlier
    // earlierTotalCallNum: number;
    // earlierTotalExtentionNum: number;
    // earlierTotalTransmitNum: number;
  } {
    return {
      beforeDate: '',
      // calendarDate: '',
      chartData: [],
      // date: ['2022-01-24'],
      date: '',
      earlierArriveArray: [
        { count: 0, upFlg: 'false' },
        { answerCount: 0, upFlg: 'false' },
        { answerRate: 0, upFlg: 'false' },
        { noanswerRate: 0, upFlg: 'false' },
        { avgAnswerTime: 0, upFlg: 'false' },
        { duration: 0, upFlg: 'false' },
      ],
      earlierExtensionArray: [
        { count: 0, upFlg: 'false' },
        { answerCount: 0, upFlg: 'false' },
        { answerRate: 0, upFlg: 'false' },
        { noanswerRate: 0, upFlg: 'false' },
        { avgAnswerTime: 0, upFlg: 'false' },
        { duration: 0, upFlg: 'false' },
      ],
      earlierTotalArray: [
        { totalCount: 0, upFlg: 'false' },
        { arriveCount: 0, upFlg: 'false' },
        { transmitCount: 0, upFlg: 'false' },
        { extensionCount: 0, upFlg: 'false' },
        { totalDuration: 0, upFlg: 'false' },
      ],
      earlierTransmitArray: [
        { count: 0, upFlg: 'false' },
        { answerCount: 0, upFlg: 'false' },
        { answerRate: 0, upFlg: 'false' },
        { noanswerRate: 0, upFlg: 'false' },
        { avgAnswerTime: 0, upFlg: 'false' },
        { duration: 0, upFlg: 'false' },
      ],
      graphType: 0,
      graphWidth: 1320,
      headers: [
        { align: 'center', text: '', value: 'kind' },
        { text: '日時', value: 'time' },
        { text: '発信', value: 'transmit' },
        { text: '着信', value: 'arrive' },
        { text: '通話時間', value: 'duration' },
        { text: '録音', value: 'Linkedid' },
      ],
      headers2: [
        { align: 'center', text: '内線番号', value: 'no' },
        { align: 'center', text: '発信平均通話時間', value: 'avgTransmitTime' },
        { align: 'center', text: '着信平均通話時間', value: 'avgArriveTime' },
        { align: 'center', text: '平均通話時間', value: 'avgTime' },
        { align: 'center', text: '合計通話時間', value: 'totalTime' },
      ],
      headers3: [
        { align: 'center', text: '外線番号', value: 'no' },
        { align: 'center', text: '発信平均通話時間', value: 'avgTransmitTime' },
        { align: 'center', text: '着信平均通話時間', value: 'avgArriveTime' },
        { align: 'center', text: '平均通話時間', value: 'avgTime' },
        { align: 'center', text: '合計通話時間', value: 'totalTime' },
      ],
      incomingData: [],
      incomingDataBefore: [],
      incomingIcon: mdiPhoneIncoming,
      innerLineData: [],
      innerLineDataBefore: [],
      innerLineIcon: mdiAccountMultiple,
      notInnerLineIcon: mdiAccountMultipleRemoveOutline,
      // period: 'day',
      phoneMissedIcon: mdiPhoneMissed,
      phoneRemoveIcon: mdiPhoneRemove,
      phoneTotalCount: 0,
      phoneTotalCountIconFlg: true,
      phoneTotalTime: 0,
      phoneTotalTimeIconFlg: true,
      playbackIcon: mdiMenuRightOutline,
      // rankingData: [],
      responseChartData: [],
      responseChartDataBefore: [],
      responseTableData: [],
      responseUsageTableData: {
        did: [],
        ext: [],
      },
      search: '',
      searchUsage: '',
      selectedChartDate: '',
      selectedChartPeriod: 'day',
      switchBeforeDay: false,
      tableFilter: '全て',
      totalIncomingIconFlg: true,
      totalIncomingNum: 0,
      totalInnerLineIconFlg: true,
      totalInnerLineNum: 0,
      totalTransmissionIconFlg: true,
      totalTransmissionNum: 0,
      transmissionData: [],
      transmissionDataBefore: [],
      transmissionIcon: mdiPhoneOutgoing,
      trendingDownIcon: mdiTrendingDown,
      trendingUpIcon: mdiTrendingUp,
      usageTableTab: '内線',
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    filterTableItem(): responseTableData[] {
      const self = this;
      if (self.tableFilter !== '全て')
        return this.responseTableData.filter(function fn(element: responseTableData) {
          return element.kind === self.tableFilter;
        });
      return this.responseTableData;
    },
    filterUsageTableItem(): any[] {
      const self = this;
      if (self.usageTableTab === '内線') {
        return self.responseUsageTableData.ext;
      }
      return self.responseUsageTableData.did;
    },
    returnDurationTime() {
      return function fn(time: number) {
        const callTime = time / 1000;
        const callSecond = Math.floor(time / 1000);
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分 ${callRem}秒`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        // return `${callTime}秒`;
        return `${callSecond}秒`;
      };
    },
    returnPeriod() {
      const self = this;
      if (self.selectedPeriod === 'day') {
        return '日';
      }
      if (self.selectedPeriod === 'week') {
        return '週';
      }
      if (self.selectedPeriod === 'month') {
        return '月';
      }
      if (self.selectedPeriod === 'year') {
        return '年';
      }
      return '';
    },
    returnPhoneTotalTime() {
      return function fn(phoneTotalTime: number | undefined): string {
        if (phoneTotalTime !== undefined) {
          let callTime = phoneTotalTime / 1000;
          const callHour = Math.floor(callTime / 3600);
          const callMin = Math.floor((callTime % 3600) / 60);
          const callRem = Math.floor(callTime % 60);
          if (callHour > 0) {
            return `<span class="notosansjp-bold-mirage-18px">${callHour}</span> 時間<span class="notosansjp-bold-mirage-18px">${callMin}</span> 分<span class="notosansjp-bold-mirage-18px">${callRem}</span> 秒`;
          }
          if (callHour === 0 && callMin > 0) {
            return `<span class="notosansjp-bold-mirage-18px">${callMin}</span> 分<span class="notosansjp-bold-mirage-18px">${callRem}</span> 秒`;
          }
          callTime = Math.floor(callTime);
          return `<span class="notosansjp-bold-mirage-18px">${callTime}</span> 秒`;
        }
        return '';
      };
    },
  },
  watch: {
    selectedDate(newValue) {
      if (newValue) {
        this.selectedChartDate = newValue;
        this.getGraphData(this.graphType);
        this.getTableData();
        this.getUsagestatus();
        // this.getRankingData();
      }
    },
    selectedPeriod(newValue) {
      if (newValue) {
        this.selectedChartPeriod = newValue;
        this.getGraphData(this.graphType);
        this.getTableData();
        this.getUsagestatus();
        // this.getRankingData();
      }
    },
    selectedTarget: {
      handler(newValue) {
        if (newValue) {
          this.getGraphData(this.graphType);
          this.getTableData();
          this.getUsagestatus();
          // this.getRankingData();
        }
      },
      deep: true,
    },
    updateFlg(newValue) {
      if (newValue) {
        this.getGraphData(this.graphType);
        this.getTableData();
        this.getUsagestatus();
        // this.getRankingData();
        this.$emit('parent-update-flg', false);
      }
    },
  },
  mounted() {
    this.getGraphData(this.graphType);
    this.getTableData();
    this.getUsagestatus();
    // this.getRankingData();
  },
  // updated() {},
  // beforeDestroy() {},
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    async changeGraphInfo(value: number) {
      const self = this;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      self.beforeDate = dayjs(self.selectedBeforeDate).format('YYYYMMDD');
      self.graphType = value;
      let totalArray;
      let totalArrayBefore;
      let beforePhoneTotalTime;
      if (self.graphType === 0) {
        totalArray = this.responseChartData;
        totalArrayBefore = this.responseChartDataBefore;
      } else if (self.graphType === 1) {
        totalArray = this.innerLineData;
        totalArrayBefore = this.innerLineDataBefore;
      } else if (self.graphType === 2) {
        totalArray = this.incomingData;
        totalArrayBefore = this.incomingDataBefore;
      } else if (self.graphType === 3) {
        totalArray = this.transmissionData;
        totalArrayBefore = this.transmissionDataBefore;
      }
      if (totalArray && totalArrayBefore) {
        // 合計件数
        self.phoneTotalCount = totalArray.reduce(function fn(
          sum: number,
          element: responseChartData
        ) {
          return sum + element.aggregate.count;
        },
        0);
        // 合計通話時間
        self.phoneTotalTime = totalArray.reduce(function fn(
          sum: number,
          element: responseChartData
        ) {
          return sum + element.aggregate.time;
        },
        0);
        beforePhoneTotalTime = totalArrayBefore.reduce(function fn(
          sum: number,
          element: responseChartData
        ) {
          return sum + element.aggregate.time;
        },
        0);
        // 合計通話時間アイコン
        // 合計通話時間のアイコンチェック
        if (this.phoneTotalTime > beforePhoneTotalTime) {
          this.phoneTotalTimeIconFlg = true;
        } else {
          this.phoneTotalTimeIconFlg = false;
        }
      }
      const chartArray: chartItem[] = [];
      const chartArrayBefore: chartItem[] = [];
      const displayArray = JSON.parse(JSON.stringify(totalArray));
      const displayArrayBefore = JSON.parse(JSON.stringify(totalArrayBefore));
      // Index計算
      const indexNumArray = [];
      const day = dayjs(self.date).day();
      const item = dayjs(self.date).subtract(day, 'day').format('YYYYMMDD');
      for (let i = 0; i < displayArray.length; i += 1) {
        // if (self.selectedChartPeriod === 'week') {
        if (self.selectedPeriod === 'week') {
          const pushItem = dayjs(item).add(i, 'day').date();
          indexNumArray.push(pushItem);
          // } else if (self.selectedChartPeriod === 'month' || self.selectedChartPeriod === 'year') {
        } else if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArray.push(i + 1);
        } else {
          indexNumArray.push(i);
        }
      }
      const indexNumArrayBefore = [];
      for (let i = 0; i < displayArray.length; i += 1) {
        // if (self.selectedChartPeriod === 'month' || self.selectedChartPeriod === 'year') {
        if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArrayBefore.push(i + 1);
        } else {
          indexNumArrayBefore.push(i);
        }
      }
      // それぞれに必要なデータを追加
      for (let i = 0; i < displayArray.length; i += 1) {
        const valueItem = displayArray[i].aggregate;
        valueItem.day = i;
        valueItem.indexNum = indexNumArray[i];
        chartArray.push({
          key: i.toString(),
          value: [valueItem],
        });
      }
      for (let i = 0; i < displayArrayBefore.length; i += 1) {
        const valueItem = displayArrayBefore[i].aggregate;
        valueItem.day = i;
        // if (self.selectedChartPeriod === 'week') {
        if (self.selectedPeriod === 'week') {
          valueItem.indexNum = indexNumArray[i];
        } else {
          valueItem.indexNum = indexNumArrayBefore[i];
        }
        chartArrayBefore.push({
          key: i.toString(),
          value: [valueItem],
        });
      }
      this.chartData = [chartArray, chartArrayBefore];
    },
    customSearch(value: number | { userId: string; userName: string } | string, search: string) {
      const searchArray = search.split(/,|、/);
      let checkValue: string | { userId: string; userName: string };
      if (typeof value === 'number') {
        const callTime = value / 1000;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          checkValue = `${callHour}時間 ${callMin}分 ${callRem}秒`;
        } else if (callHour === 0 && callMin > 0) {
          checkValue = `${callMin}分 ${callRem}秒`;
        } else {
          checkValue = `${callTime}秒`;
        }
      } else {
        checkValue = value;
      }
      const checker = searchArray.some((element) => {
        if (element !== '') {
          if (typeof checkValue !== 'object') {
            return checkValue.indexOf(element) !== -1;
          }
          if (typeof checkValue === 'object' && Object.keys(value).length > 0) {
            const checkArray = [];
            if (typeof value === 'object' && value.userId !== undefined) {
              checkArray.push(checkValue.userId.indexOf(element) !== -1);
            }
            if (typeof value === 'object' && value.userName !== undefined) {
              checkArray.push(checkValue.userName.indexOf(element) !== -1);
            }
            return checkArray.indexOf(true) !== -1;
          }
          if (typeof checkValue === 'object' && Object.keys(value).length === 0) {
            return false;
          }
        }
        return false;
      });
      return value != null && search != null && checker;
    },
    customSearchUsage(
      value: number | { userId: string; userName: string } | string,
      search: string
    ) {
      const searchArray = search.split(/,|、/);
      let checkValue: string | { userId: string; userName: string };
      if (typeof value === 'number') {
        const callTime = value / 1000;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          checkValue = `${callHour}時間 ${callMin}分 ${callRem}秒`;
        } else if (callHour === 0 && callMin > 0) {
          checkValue = `${callMin}分 ${callRem}秒`;
        } else {
          checkValue = `${callTime}秒`;
        }
      } else {
        checkValue = value;
      }
      const checker = searchArray.some((element) => {
        if (element !== '') {
          if (typeof checkValue !== 'object') {
            return checkValue.indexOf(element) !== -1;
          }
          if (typeof checkValue === 'object' && Object.keys(value).length > 0) {
            const checkArray = [];
            if (typeof value === 'object' && value.userId !== undefined) {
              checkArray.push(checkValue.userId.indexOf(element) !== -1);
            }
            if (typeof value === 'object' && value.userName !== undefined) {
              checkArray.push(checkValue.userName.indexOf(element) !== -1);
            }
            return checkArray.indexOf(true) !== -1;
          }
          if (typeof checkValue === 'object' && Object.keys(value).length === 0) {
            return false;
          }
        }
        return false;
      });
      return value != null && search != null && checker;
    },
    async getGraphData(value: number) {
      this.setNavigating({ navigating: true });
      const self = this;
      const { workspaceId } = this.userAttributes;
      self.graphType = value;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      self.beforeDate = dayjs(self.selectedBeforeDate).format('YYYYMMDD');
      if (self.selectedTarget.selectedId === 'none') {
        this.responseChartData = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.responseChartDataBefore = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineData = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineDataBefore = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingData = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingDataBefore = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionData = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionDataBefore = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        // }
      } else if (
        Object.keys(self.selectedTarget.selectedUser).length ||
        self.selectedTarget.selectedDid
      ) {
        this.responseChartData = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.responseChartDataBefore = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineData = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineDataBefore = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingData = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingDataBefore = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionData = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionDataBefore = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
      } else {
        this.responseChartData = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.responseChartDataBefore = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineData = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.innerLineDataBefore = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingData = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.incomingDataBefore = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionData = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
        this.transmissionDataBefore = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.beforeDate,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
      }
      let earlierDate;
      if (Object.keys(self.selectedTarget.selectedUser).length) {
        earlierDate = await DashboardService.getVoiceXEarlier({
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
      } else {
        earlierDate = await DashboardService.getVoiceXEarlier({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: self.selectedPeriod,
          },
          workspaceId,
        });
      }
      // 合計着信件数
      this.totalIncomingNum = earlierDate[1].arrive.count;
      // 合計発信件数
      this.totalTransmissionNum = earlierDate[1].transmit.count;
      // 合計内線件数
      this.totalInnerLineNum = earlierDate[1].extension.count;
      // 合計件数
      this.phoneTotalCount =
        this.totalIncomingNum + this.totalTransmissionNum + this.totalInnerLineNum;
      // 合計通話時間
      this.phoneTotalTime =
        earlierDate[1].arrive.duration +
        earlierDate[1].transmit.duration +
        earlierDate[1].extension.duration;
      // 前の合計着信件数
      const beforeTotalIncomingNum = earlierDate[0].arrive.count;
      // 前の合計発信件数
      const beforeTotalTransmissionNum = earlierDate[0].transmit.count;
      // 前の合計内線件数
      const beforeTotalInnerLineNum = earlierDate[0].extension.count;
      // 前の合計件数
      const beforePhoneTotalCount =
        beforeTotalIncomingNum + beforeTotalTransmissionNum + beforeTotalInnerLineNum;
      // 前の合計通話時間
      const beforePhoneTotalTime =
        earlierDate[0].arrive.duration +
        earlierDate[0].transmit.duration +
        earlierDate[0].extension.duration;
      // 合計着信件数のアイコンチェック
      if (this.totalIncomingNum > beforeTotalIncomingNum) {
        this.totalIncomingIconFlg = true;
      } else {
        this.totalIncomingIconFlg = false;
      }
      // 合計発信件数のアイコンチェック
      if (this.totalTransmissionNum > beforeTotalTransmissionNum) {
        this.totalTransmissionIconFlg = true;
      } else {
        this.totalTransmissionIconFlg = false;
      }
      // 合計内線件数のアイコンチェック
      if (this.totalInnerLineNum > beforeTotalInnerLineNum) {
        this.totalInnerLineIconFlg = true;
      } else {
        this.totalInnerLineIconFlg = false;
      }
      // 合計件数のアイコンチェック
      if (this.phoneTotalCount > beforePhoneTotalCount) {
        this.phoneTotalCountIconFlg = true;
      } else {
        this.phoneTotalCountIconFlg = false;
      }
      // 合計通話時間のアイコンチェック
      if (this.phoneTotalTime > beforePhoneTotalTime) {
        this.phoneTotalTimeIconFlg = true;
      } else {
        this.phoneTotalTimeIconFlg = false;
      }
      /// ////////////////////////////////////////////////////////////////////////////
      this.earlierTotalArray = this.makeArrayEarlierTotal(earlierDate);
      this.earlierArriveArray = this.makeArrayEarlier(earlierDate[0].arrive, earlierDate[1].arrive);
      this.earlierTransmitArray = this.makeArrayEarlier(
        earlierDate[0].transmit,
        earlierDate[1].transmit
      );
      this.earlierExtensionArray = this.makeArrayEarlier(
        earlierDate[0].extension,
        earlierDate[1].extension
      );
      /// ////////////////////////////////////////////////////////////////////////////
      //
      // グラフに表示するデータ
      let totalArray;
      let totalArrayBefore;
      if (self.graphType === 0) {
        totalArray = this.responseChartData;
        totalArrayBefore = this.responseChartDataBefore;
      } else if (self.graphType === 1) {
        totalArray = this.innerLineData;
        totalArrayBefore = this.innerLineDataBefore;
      } else if (self.graphType === 2) {
        totalArray = this.incomingData;
        totalArrayBefore = this.incomingDataBefore;
      } else if (self.graphType === 3) {
        totalArray = this.transmissionData;
        totalArrayBefore = this.transmissionDataBefore;
      }
      const chartArray: {
        key: string;
        // value: any[];
        value: chartItemValue;
      }[] = [];
      const chartArrayBefore: {
        key: string;
        // value: any[];
        value: chartItemValue;
      }[] = [];
      const displayArray = JSON.parse(JSON.stringify(totalArray));
      const displayArrayBefore = JSON.parse(JSON.stringify(totalArrayBefore));
      // new Index計算
      // 今月
      const indexNumArray = [];
      const day = dayjs(self.date).day();
      const item = dayjs(self.date).subtract(day, 'day').format('YYYYMMDD');
      for (let i = 0; i < displayArray.length; i += 1) {
        if (self.selectedPeriod === 'week') {
          const pushItem = dayjs(item).add(i, 'day').date();
          indexNumArray.push(pushItem);
        } else if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArray.push(i + 1);
        } else {
          indexNumArray.push(i);
        }
      }
      const indexNumArrayBefore = [];
      for (let i = 0; i < displayArrayBefore.length; i += 1) {
        // if (self.selectedChartPeriod === 'month' || self.selectedChartPeriod === 'year') {
        if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArrayBefore.push(i + 1);
        } else {
          indexNumArrayBefore.push(i);
        }
      }
      /// ////////////////////////////////////////////////////
      // それぞれに必要なデータを追加
      for (let i = 0; i < displayArray.length; i += 1) {
        const valueItem = displayArray[i].aggregate;
        valueItem.day = i;
        // valueItem.indexNum = i + indexNum;
        valueItem.indexNum = indexNumArray[i];
        chartArray.push({
          key: i.toString(),
          value: [valueItem],
        });
      }
      for (let i = 0; i < displayArrayBefore.length; i += 1) {
        const valueItem = displayArrayBefore[i].aggregate;
        valueItem.day = i;
        // if (self.selectedChartPeriod === 'week') {
        if (self.selectedPeriod === 'week') {
          valueItem.indexNum = indexNumArray[i];
        } else {
          valueItem.indexNum = indexNumArrayBefore[i];
        }
        chartArrayBefore.push({
          key: i.toString(),
          value: [valueItem],
        });
      }
      self.chartData = [chartArray, chartArrayBefore];
    },
    // async getRankingData() {
    //   const self = this;
    //   const { workspaceId } = this.userAttributes;
    //   self.date = dayjs(self.selectedDate).format('YYYYMMDD');
    //   // let period = '';
    //   // if (self.selectedChartPeriod) {
    //   //   period = self.selectedChartPeriod;
    //   // } else {
    //   //   period = self.selectedPeriod;
    //   // }
    //   const period = self.selectedPeriod;
    //   let rankingData = [];
    //   if (self.selectedTarget.selectedId === 'none') {
    //     rankingData = await DashboardService.getVoiceXRanking({
    //       target: {
    //         projectId: 'main',
    //       },
    //       term: {
    //         date: self.date,
    //         kind: period,
    //       },
    //       workspaceId,
    //     });
    //   } else {
    //     rankingData = await DashboardService.getVoiceXRanking({
    //       target: {
    //         [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
    //       },
    //       term: {
    //         date: self.date,
    //         kind: period,
    //       },
    //       workspaceId,
    //     });
    //   }
    //   rankingData = rankingData.filter(function fn(element: responseRankingData) {
    //     return element.user.userId !== '';
    //   });
    //   const results = [];
    //   for (let i = 0; i < rankingData.length; i += 1) {
    //     if (rankingData[i].user.userId) {
    //       results.push(
    //         DashboardService.getVoiceXEarlier({
    //           target: {
    //             userId: rankingData[i].user.userId,
    //           },
    //           term: {
    //             date: self.date,
    //             kind: period,
    //           },
    //           workspaceId,
    //         })
    //       );
    //     }
    //   }
    //   // Promise all で結果を出す
    //   const items = await Promise.all(results);
    //   // rankingDataに追加 earlierは[0]が前データ、[1]が現在のデータ
    //   for (let i = 0; i < rankingData.length; i += 1) {
    //     let percent = 0;
    //     if (items[i][0].time !== 0) {
    //       percent = (items[i][1].time / items[i][0].time) * 100;
    //     }
    //     rankingData[i].earlierPercent = Math.round(percent * 10) / 10;
    //   }
    //   const sortArray = rankingData.sort(function fn(
    //     a: responseRankingData,
    //     b: responseRankingData
    //   ) {
    //     return a.earlierPercent > b.earlierPercent ? -1 : 1;
    //   });
    //   if (sortArray.length > 5) {
    //     self.rankingData = sortArray.splice(0, 5);
    //   } else {
    //     self.rankingData = sortArray;
    //   }
    // },
    async getTableData() {
      const self = this;
      const { workspaceId } = this.userAttributes;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      const period = self.selectedPeriod;
      // if (self.selectedChartPeriod) {
      //   period = self.selectedChartPeriod;
      // } else {
      //   period = self.selectedPeriod;
      // }
      if (self.selectedTarget.selectedId === 'none') {
        self.responseTableData = await DashboardService.getVoiceXBreakdown({
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        self.responseTableData = await DashboardService.getVoiceXBreakdown({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      }
    },
    async getUsagestatus() {
      const self = this;
      const { workspaceId } = this.userAttributes;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      const period = self.selectedPeriod;
      if (self.selectedTarget.selectedId === 'none') {
        self.responseUsageTableData = await DashboardService.getUsageStatus({
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else if (Object.keys(self.selectedTarget.selectedUser).length) {
        self.responseUsageTableData = await DashboardService.getUsageStatus({
          target: {
            phone: {
              kind: this.selectedPhoneType,
              no:
                this.selectedPhoneType === 'ext'
                  ? self.selectedTarget.selectedUser.voiceXs[0].phoneNo
                  : self.selectedTarget.selectedDid,
            },
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        self.responseUsageTableData = await DashboardService.getUsageStatus({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      }
    },
    makeArrayEarlier(arrive1: earlierDataItem, arrive2: earlierDataItem) {
      const countUpFlg = arrive1.count > arrive2.count ? 'true' : 'false';
      const answerCountUpFlg = arrive1.answerCount > arrive2.answerCount ? 'true' : 'false';
      const answerRateUpFlg = arrive1.answerRate > arrive2.answerRate ? 'true' : 'false';
      const noanswerRateUpFlg = arrive1.noanswerRate > arrive2.noanswerRate ? 'true' : 'false';
      const avgAnswerTimeUpFlg = arrive1.avgAnswerTime > arrive2.avgAnswerTime ? 'true' : 'false';
      const durationUpFlg = arrive1.duration > arrive2.duration ? 'true' : 'false';
      const arrive1Copy = JSON.parse(JSON.stringify(arrive1));
      const returnArray = [];
      returnArray[0] = { count: arrive1Copy.count, upFlg: countUpFlg };
      returnArray[1] = { answerCount: arrive1Copy.answerCount, upFlg: answerCountUpFlg };
      returnArray[2] = { answerRate: arrive1Copy.answerRate, upFlg: answerRateUpFlg };
      returnArray[3] = { noanswerRate: arrive1Copy.noanswerRate, upFlg: noanswerRateUpFlg };
      returnArray[4] = { avgAnswerTime: arrive1Copy.avgAnswerTime, upFlg: avgAnswerTimeUpFlg };
      returnArray[5] = { duration: arrive1Copy.duration, upFlg: durationUpFlg };
      return returnArray;
    },
    makeArrayEarlierTotal(
      earlierDataArray: earlierData
    ): {
      totalCount?: number;
      upFlg: string;
      arriveCount?: number;
      transmitCount?: number;
      extensionCount?: number;
      totalDuration?: number;
    }[] {
      const arriveCount = earlierDataArray[1].arrive.count;
      const arrvieCountBefore = earlierDataArray[0].arrive.count;
      const transmitCount = earlierDataArray[1].transmit.count;
      const transmitCountBefore = earlierDataArray[0].transmit.count;
      const extensionCount = earlierDataArray[1].extension.count;
      const extensionCountBefore = earlierDataArray[0].extension.count;

      const totalCount = arriveCount + transmitCount + extensionCount;
      const totalCountBefore = arrvieCountBefore + transmitCountBefore + extensionCountBefore;
      const totalUpFlg = totalCount > totalCountBefore ? 'true' : 'false';
      const arriveUpFlg = arriveCount > arrvieCountBefore ? 'true' : 'false';
      const transmitUpFlg = transmitCount > transmitCountBefore ? 'true' : 'false';
      const extensionUpFlg = extensionCount > extensionCountBefore ? 'true' : 'false';

      const totalDuration =
        earlierDataArray[1].arrive.duration +
        earlierDataArray[1].transmit.duration +
        earlierDataArray[1].extension.duration;
      const totalDurationBefore =
        earlierDataArray[0].arrive.duration +
        earlierDataArray[0].transmit.duration +
        earlierDataArray[0].extension.duration;
      const durationUpFlg = totalDuration > totalDurationBefore ? 'true' : 'false';

      return [
        { totalCount, upFlg: totalUpFlg },
        { arriveCount, upFlg: arriveUpFlg },
        { transmitCount, upFlg: transmitUpFlg },
        { extensionCount, upFlg: extensionUpFlg },
        { totalDuration, upFlg: durationUpFlg },
      ];
    },
    setTableFilter(value: string) {
      this.tableFilter = value;
    },
    setUsageTableFilter(value: string) {
      this.usageTableTab = value;
    },
  },
});
