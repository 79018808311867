

























































































import * as d3 from 'd3';
import dayjs from 'dayjs';
import Vue from 'vue';
// import LegendChart from '@/components/specific/LegendChart.vue';
import TotalBubbleAndLineGraph from '@/components/specific/TotalBubbleAndLineGraph.vue';
import TotalStackedBarAndLineGraph from '@/components/specific/TotalStackedBarAndLineGraph.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';

const DashboardService = ServiceFactory.get('dashboard');

export default Vue.extend({
  name: 'TotalInfoDashboard',
  components: {
    TotalBubbleAndLineGraph,
    TotalStackedBarAndLineGraph,
  },
  props: {
    divisionNumSc: {
      type: Number,
      default: 0,
    },
    divisionNumVoicex: {
      type: Number,
      default: 0,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    selectedPhoneType: {
      type: String,
      default: '',
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    selectedTargetUser: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    updateFlg: {
      type: Boolean,
    },
    // selectedUserListLength: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data(): {
    date: any;
    finishFlg: boolean;
    search: string;
    // windowEvents: any;
    // windowKey: 0;
    // graphWidthBarandline: number;
    // graphWidthBubbleandline: number;
    // mountedKey: any;
    SCAgentData: any[];
    VoiceXData: any[];
    totalGraphX: any[];
    ScAppliCountMax: number;
    SCTotalGraphMax: number;
    mainAppliArray: any[];
    VoiceXDataMax: number;
    VoiceXTimeMax: number;
    VoiceXCountMax: number;
    selectedChartDate: string;
    selectedChartPeriod: string;
    headers: any[];
    headers2: any[];
    // desserts: any[];
    innerLineData: any[];
    incomingData: any[];
    transmissionData: any[];
    totalTableData: any[];
  } {
    return {
      SCAgentData: [],
      SCTotalGraphMax: -1,
      ScAppliCountMax: 0,
      VoiceXCountMax: 0,
      VoiceXData: [],
      VoiceXDataMax: 0,
      VoiceXTimeMax: 0,
      date: '',
      // graphWidthBarandline: 874,
      // graphWidthBubbleandline: 874,
      finishFlg: false,
      headers: [
        {
          filterable: false,
          text: '期間',
          value: 'term',
        },
        { text: '通話時間', value: 'callTime' },
        { text: '合計通話件数', value: 'callCount' },
        { text: '合計着信件数', value: 'incoming' },
        { text: '合計発信件数', value: 'transmission' },
        { text: '内線件数', value: 'innerLine' },
        { text: 'PC全体稼働時間', value: 'workTime' },
        { text: '主要アプリ稼働時間', value: 'mainAppTime' },
        { text: 'キーボード打鍵数', value: 'keyBoardNum' },
      ],
      headers2: [
        {
          filterable: false,
          text: '期間',
          value: 'term',
        },
        { text: '平均通話時間', value: 'callTime' },
        { text: '合計通話件数', value: 'callCount' },
        { text: '合計着信件数', value: 'incoming' },
        { text: '合計発信件数', value: 'transmission' },
        { text: '内線件数', value: 'innerLine' },
        { text: 'PC全体平均稼働時間', value: 'workTime' },
        { text: '主要アプリ平均稼働時間', value: 'mainAppTime' },
        { text: 'キーボード平均打鍵数', value: 'keyBoardNum' },
      ],
      incomingData: [],
      innerLineData: [],
      mainAppliArray: [],
      // mountedKey: null,
      search: '',
      selectedChartDate: '',
      selectedChartPeriod: '',
      // totalGraphMax: 0,
      totalGraphX: [],
      totalTableData: [],
      transmissionData: [],
      // windowEvents: {},
      // windowKey: 0,
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
  },
  watch: {
    async selectedDate(newValue) {
      if (newValue) {
        this.selectedChartDate = newValue;
        await Promise.all([this.getSCAgentData(), this.getVoiceXData()]);
        await this.getTableData();
      }
    },
    async selectedPeriod(newValue) {
      if (newValue) {
        await Promise.all([this.getSCAgentData(), this.getVoiceXData()]);
        await this.getTableData();
      }
    },
    selectedTarget: {
      async handler(newValue) {
        if (newValue) {
          await Promise.all([this.getSCAgentData(), this.getVoiceXData()]);
          // await Promise.all([this.getVoiceXData()]);
          await this.getTableData();
        }
      },
      deep: true,
    },
    selectedTargetUser: {
      async handler(newValue) {
        if (newValue) {
          await Promise.all([this.getSCAgentData(), this.getVoiceXData()]);
          // await Promise.all([this.getSCAgentData()]);
          await this.getTableData();
        }
      },
      deep: true,
    },
    async updateFlg(newValue) {
      if (newValue) {
        await Promise.all([this.getSCAgentData(), this.getVoiceXData()]);
        await this.getTableData();
        this.$emit('parent-update-flg', false);
      }
    },
    // selectedTarget: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.getGraphDataMainApp();
    //       this.getGraphDataUsageStatus();
    //       this.getSCAgentBreakdown();
    //     }
    //   },
    //   deep: true,
    // },
  },
  async mounted() {
    const self = this;
    // this.mountedKey = this.windowHandler().addListener(
    //   window,
    //   'resize',
    //   self.checkGraphWidth,
    //   null
    // );

    window.addEventListener('resize', this.getElementHeight1);
    await Promise.all([self.getSCAgentData(), self.getVoiceXData()]);
    await self.getTableData();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getElementHeight1);
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    getElementHeight1() {
      // const self = this;
      const height1 = d3.select('#total-dashboard-container-barandline').style('height');
      const element = document.getElementById('total-dashboard-container-bubbleandline');
      if (element) {
        element.style.height = height1;
      }
      // const currentWidthBarAndLine = parseInt(
      //   d3.select('#total-dashboard-container-barandline').style('width'),
      //   10
      // );
      // self.graphWidthBarandline = currentWidthBarAndLine;
      // const currentWidthBubbleAndLine = parseInt(
      //   d3.select('#total-dashboard-container-bubbleandline').style('width'),
      //   10
      // );
      // self.graphWidthBubbleandline = currentWidthBubbleAndLine;
    },
    async getSCAgentData() {
      this.finishFlg = false;
      this.setNavigating({ navigating: true });
      const self = this;
      const { workspaceId } = this.userAttributes;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      self.totalGraphX = [];
      let period = '';
      let dataObject = [];
      if (self.selectedChartPeriod) {
        period = self.selectedChartPeriod;
      } else {
        period = self.selectedPeriod;
      }
      // MainAppPieChart用のデータ取得
      if (self.selectedTarget.selectedId === 'none') {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else if (Object.keys(self.selectedTargetUser.selectedUser).length) {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            userId: self.selectedTargetUser.selectedUser.userId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        dataObject = await DashboardService.getSCAgentData({
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      }
      const dataObjectCopy = JSON.parse(JSON.stringify(dataObject));
      // indexNum追加
      const indexNumArray = [];
      const day = dayjs(self.date).day();
      const item = dayjs(self.date).subtract(day, 'day').format('YYYYMMDD');
      for (let i = 0; i < dataObjectCopy.length; i += 1) {
        if (self.selectedPeriod === 'week') {
          const pushItem = dayjs(item).add(i, 'day').date();
          indexNumArray.push(pushItem);
        } else if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArray.push(i + 1);
        } else {
          indexNumArray.push(i);
        }
      }
      const countAppliNum: any = {};
      let maxNum = 0;
      let totalKeyCount = 0;
      self.ScAppliCountMax = 0;
      self.SCTotalGraphMax = 0;
      self.totalGraphX = indexNumArray;
      for (let i = 0; i < dataObjectCopy.length; i += 1) {
        // self.totalGraphX.push(i.toString());
        // 並べ替え
        dataObjectCopy[i].aggregate.sort(function fn(a: any, b: any) {
          return a.appliCount > b.appliCount ? -1 : 1;
        });
        maxNum = 0;
        for (let j = 0; j < dataObjectCopy[i].aggregate.length; j += 1) {
          const { appliName, appliCount, keyCount } = dataObjectCopy[i].aggregate[j];
          totalKeyCount += keyCount;
          // dataObjectCopy[i].aggregate[j].indexNum = i;
          dataObjectCopy[i].aggregate[j].indexNum = indexNumArray[i];
          maxNum += appliCount;
          if (countAppliNum[appliName]) {
            countAppliNum[appliName] += appliCount;
          } else {
            countAppliNum[appliName] = appliCount;
          }
          if (j === dataObjectCopy[i].aggregate.length - 1) {
            dataObjectCopy[i].totalKey = totalKeyCount;
            totalKeyCount = 0;
          }
        }
        if (self.SCTotalGraphMax < maxNum) {
          self.SCTotalGraphMax = maxNum;
          self.ScAppliCountMax = maxNum;
        }
        // self.SCTotalGraphMax = -1;
      }
      // キーを含んだ配列に変換
      let arrayMainAppli = Object.keys(countAppliNum).map((k) => ({
        key: k,
        value: countAppliNum[k],
      }));
      // 並べ替え
      arrayMainAppli = arrayMainAppli.sort(function (a: any, b: any) {
        return a.value > b.value ? -1 : 1;
      });
      // その他を取り出し
      let otherItem: any = '';
      const otherItemObject: {
        key: string;
        value: number;
      } = {
        key: 'その他',
        value: 0,
      };
      for (let i = 0; i < arrayMainAppli.length; i += 1) {
        if (arrayMainAppli[i].key === 'その他') {
          otherItem = arrayMainAppli.splice(i, 1);
          otherItemObject.value += otherItem[0].value;
          i -= 1;
        }
      }
      arrayMainAppli.push(otherItemObject);
      arrayMainAppli = arrayMainAppli.splice(0, 5);
      self.mainAppliArray = arrayMainAppli;
      for (let i = 0; i < dataObjectCopy.length; i += 1) {
        dataObjectCopy[i].mainAndOther = [
          { indexNum: indexNumArray[i], key: 'main', value: 0 },
          { indexNum: indexNumArray[i], key: 'other', value: 0 },
        ];
        let totalNum = 0;
        if (dataObjectCopy[i].aggregate.length > 0) {
          for (let j = 0; j < dataObjectCopy[i].aggregate.length; j += 1) {
            const findValue = self.mainAppliArray.find(
              (v) => v.key === dataObjectCopy[i].aggregate[j].appliName
            );
            if (findValue) {
              dataObjectCopy[i].mainAndOther[0].value += dataObjectCopy[i].aggregate[j].appliCount;
              totalNum += dataObjectCopy[i].aggregate[j].appliCount;
            } else {
              dataObjectCopy[i].mainAndOther[1].value += dataObjectCopy[i].aggregate[j].appliCount;
              totalNum += dataObjectCopy[i].aggregate[j].appliCount;
            }
          }
          dataObjectCopy[i].mainAndOther[0].totalNum = totalNum;
          dataObjectCopy[i].mainAndOther[1].totalNum = totalNum;
        }
      }
      self.SCAgentData = dataObjectCopy;
    },
    async getTableData() {
      const self = this;
      const voicexData = JSON.parse(JSON.stringify(self.VoiceXData));
      const scagentData = JSON.parse(JSON.stringify(self.SCAgentData));
      const innerLineData = JSON.parse(JSON.stringify(self.innerLineData));
      const incomingData = JSON.parse(JSON.stringify(self.incomingData));
      const transmissionData = JSON.parse(JSON.stringify(self.transmissionData));
      const tableData = [];
      if (voicexData.length === scagentData.length) {
        for (let i = 0; i < voicexData.length; i += 1) {
          const item: any = {};
          // 日付
          if (self.selectedPeriod === 'year') {
            item.term = dayjs(voicexData[i].term.startAt).format('YYYY年M月');
          } else if (self.selectedPeriod === 'month') {
            item.term = dayjs(voicexData[i].term.startAt).format('YYYY年M月D日');
          } else if (self.selectedPeriod === 'week') {
            item.term = dayjs(voicexData[i].term.startAt).format('YYYY年M月D日');
          } else if (self.selectedPeriod === 'day') {
            item.term = dayjs(voicexData[i].term.startAt).format('YYYY年M月D日 H:mm');
          }
          // 通話件数
          item.callCount = voicexData[i].aggregate.count;
          // 着信件数
          item.incoming = incomingData[i].aggregate.count;
          // 発信件数
          item.transmission = transmissionData[i].aggregate.count;
          // 内線件数
          item.innerLine = innerLineData[i].aggregate.count;
          // PC全体稼働時間 && 主要アプリ稼働時間 %% キーボード平均
          // [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          if (
            self.selectedTarget.selectedGroup === 'projectId' ||
            self.selectedTarget.selectedGroup === 'departmentId' ||
            self.selectedTarget.selectedGroup === 'none'
          ) {
            // const divideNum = this.selectedUserListLength;
            const divideNumVoiceX = this.divisionNumVoicex;
            const divideNumSc = this.divisionNumSc;
            // callTime
            let callTime = voicexData[i].aggregate.time / 1000 / divideNumVoiceX;
            if (callTime < 60) {
              // const second = Math.floor((callTime % 3600) / 60) / 60;
              callTime = Math.round(callTime * 10) / 10;
              item.callTime = `${Math.floor(callTime)} 秒`;
            } else if (callTime >= 60 && callTime < 3600) {
              const second = Math.floor((callTime % 3600) / 60) / 60;
              const minitue = Math.floor((callTime % 3600) / 60);
              item.callTime = `${minitue} 分 ${second} 秒`;
            } else if (callTime >= 3600) {
              const second = Math.floor((callTime % 3600) / 60) / 60;
              const minitue = Math.floor((callTime % 3600) / 60);
              const hour = Math.floor(callTime % 3600);
              item.callTime = `${hour} 時間 ${minitue} 分 ${second} 秒`;
            }
            // const callTime = voicexData[i].aggregate.time / 1000 / divideNum;
            // const callHour = Math.floor(callTime / 3600);
            // const callMin = Math.floor((callTime % 3600) / 60);
            // item.callTime = `${callHour}時間 ${callMin}分`;
            // worktime
            const workTime =
              ((scagentData[i].mainAndOther[0].value + scagentData[i].mainAndOther[1].value) /
                divideNumSc) *
              30;
            const hour = Math.floor(workTime / 3600);
            const min = Math.floor((workTime % 3600) / 60);
            item.workTime = `${hour}時間 ${min}分`;
            // mainAppTime
            const mainAppTime = (scagentData[i].mainAndOther[0].value / divideNumSc) * 30;
            const mainHour = Math.floor(mainAppTime / 3600);
            const mainMin = Math.floor((mainAppTime % 3600) / 60);
            item.mainAppTime = `${mainHour}時間 ${mainMin}分`;
            // キーボード平均
            let keyBoardNum: any = scagentData[i].totalKey ? scagentData[i].totalKey : 0;
            keyBoardNum /= divideNumSc;
            item.keyBoardNum = `${Math.floor(keyBoardNum)}回`;
          } else {
            // callTime
            const callTime = voicexData[i].aggregate.time / 1000 / this.divisionNumVoicex;
            if (callTime < 60) {
              // const second = Math.floor((callTime % 3600) / 60) / 60;
              item.callTime = `${Math.floor(callTime)} 秒`;
            } else if (callTime >= 60 && callTime < 3600) {
              const second = Math.floor((callTime % 3600) / 60) / 60;
              const minitue = Math.floor((callTime % 3600) / 60);
              item.callTime = `${minitue} 分 ${second} 秒`;
            } else if (callTime >= 3600) {
              const second = Math.floor((callTime % 3600) / 60) / 60;
              const minitue = Math.floor((callTime % 3600) / 60);
              const hour = Math.floor(callTime % 3600);
              item.callTime = `${hour} 時間 ${minitue} 分 ${second} 秒`;
            }
            // worktime
            const workTime =
              (scagentData[i].mainAndOther[0].value + scagentData[i].mainAndOther[1].value) * 30;
            const min = Math.floor((workTime % 3600) / 60);
            item.workTime = `${min}分`;
            // mainAppTime
            const mainAppTime = scagentData[i].mainAndOther[0].value * 30;
            const mainMin = Math.floor((mainAppTime % 3600) / 60);
            item.mainAppTime = `${mainMin}分`;
            // キーボード
            const keyBoardNum = scagentData[i].totalKey ? scagentData[i].totalKey : 0;
            item.keyBoardNum = `${keyBoardNum}回`;
          }
          // table用データにpush
          tableData.push(item);
        }
        self.totalTableData = tableData;
      }
    },
    async getVoiceXData() {
      const self = this;
      const { workspaceId } = this.userAttributes;
      self.date = dayjs(self.selectedDate).format('YYYYMMDD');
      let period = '';
      let dataObject = [];
      self.VoiceXCountMax = 0;
      if (self.selectedChartPeriod) {
        period = self.selectedChartPeriod;
      } else {
        period = self.selectedPeriod;
      }
      if (self.selectedTarget.selectedId === 'none') {
        dataObject = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else if (
        self.selectedTargetUser.selectedUser.voiceXs &&
        self.selectedTargetUser.selectedUser.voiceXs.length > 0
      ) {
        dataObject = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            phone: {
              kind: self.selectedPhoneType,
              no: self.selectedTargetUser.selectedUser.voiceXs[0].phoneNo,
            },
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else if (!Object.keys(self.selectedTargetUser.selectedUser).length) {
        // console.log(self.selectedTypeOfPhone);
        dataObject = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        dataObject = await DashboardService.getVoiceXData({
          callType: 0,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        for (let i = 0; i < dataObject.length; i += 1) {
          dataObject[i].aggregate.time = 0;
          dataObject[i].aggregate.count = 0;
        }
      }
      const dataObjectCopy = JSON.parse(JSON.stringify(dataObject));
      // indexNum追加
      const indexNumArray = [];
      const day = dayjs(self.date).day();
      const item = dayjs(self.date).subtract(day, 'day').format('YYYYMMDD');
      for (let i = 0; i < dataObjectCopy.length; i += 1) {
        if (self.selectedPeriod === 'week') {
          const pushItem = dayjs(item).add(i, 'day').date();
          indexNumArray.push(pushItem);
        } else if (self.selectedPeriod === 'month' || self.selectedPeriod === 'year') {
          indexNumArray.push(i + 1);
        } else {
          indexNumArray.push(i);
        }
      }
      //
      let voiceXDataMaxTime = 0;
      let voiceXDataMaxCount = 0;
      for (let i = 0; i < dataObjectCopy.length; i += 1) {
        if (voiceXDataMaxTime < dataObjectCopy[i].aggregate.time) {
          voiceXDataMaxTime = dataObjectCopy[i].aggregate.time;
        }
        if (voiceXDataMaxCount < dataObjectCopy[i].aggregate.count) {
          voiceXDataMaxCount = dataObjectCopy[i].aggregate.count;
        }
        dataObjectCopy[i].indexNum = indexNumArray[i];
        // 以下追加
        if (self.VoiceXTimeMax < dataObjectCopy[i].aggregate.time) {
          self.VoiceXTimeMax = dataObjectCopy[i].aggregate.time;
        }
        if (self.VoiceXCountMax < dataObjectCopy[i].aggregate.count) {
          self.VoiceXCountMax = dataObjectCopy[i].aggregate.count;
        }
      }
      // voiceXDataMaxTime new
      self.VoiceXDataMax = voiceXDataMaxTime;
      self.VoiceXData = dataObjectCopy;
      // table用データ取得
      if (self.selectedTarget.selectedId === 'none') {
        self.innerLineData = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        self.incomingData = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        self.transmissionData = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            projectId: 'main',
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      } else {
        self.innerLineData = await DashboardService.getVoiceXData({
          callType: 1,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        self.incomingData = await DashboardService.getVoiceXData({
          callType: 2,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
        self.transmissionData = await DashboardService.getVoiceXData({
          callType: 3,
          target: {
            [self.selectedTarget.selectedGroup]: self.selectedTarget.selectedId,
          },
          term: {
            date: self.date,
            kind: period,
          },
          workspaceId,
        });
      }
      this.finishFlg = true;
    },
    // windowHandler() {
    //   const self = this;
    //   return {
    //     addListener(target: any, type: any, listener: any, capture: any) {
    //       target.addEventListener(type, listener, capture);
    //       self.windowEvents[self.windowKey] = {
    //         capture,
    //         listener,
    //         target,
    //         type,
    //       };
    //       self.windowKey += 1;
    //       return self.windowKey;
    //     },
    //     removeListener(key: any) {
    //       if (key in self.windowEvents) {
    //         const e = self.windowEvents[key];
    //         e.target.removeEventListener(e.type, e.listener, e.capture);
    //       }
    //     },
    //   };
    // },
  },
});
