








import * as d3 from 'd3';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'MainAppPieGraph',
  props: {
    mainAppData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    mainAppDataMax: {
      type: Number,
      default: 0,
    },
  },
  data(): {
    width: number;
    height: number;
    radius: any;
    svg: any;
    path: any;
    color: any;
    pie: any;
    arcHover: any;
    arcGenerator: any;
    current: any;
    mainAppChartData: any;
    // mainAppChartDataMax: number;
    outerArc: any;
    labelArc: any;
    textArc: any;
    sliceOrigin: any;
    svgLegend: any;
    appliHoverTime: number;
  } {
    return {
      appliHoverTime: 0,
      arcGenerator: null,
      arcHover: null,
      color: null,
      current: null,
      height: 480,
      labelArc: null,
      mainAppChartData: [],
      // mainAppChartDataMax: 0,
      outerArc: null,
      path: null,
      pie: null,
      radius: null,
      sliceOrigin: null,
      svg: null,
      svgLegend: null,
      textArc: null,
      width: 853,
    };
  },
  computed: {
    returnAppliTime() {
      return function a(time: number) {
        const callTime = time * 30;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        return `${callTime}秒`;
      };
    },
  },
  watch: {
    mainAppData: {
      handler(newValue) {
        this.mainAppChartData = newValue;
        // this.drawGraph();
        this.firstGraph();
      },
      deep: true,
    },
    // mainAppDataMax: {
    //   handler(newValue) {
    //     console.log('check max', newValue);
    //     this.mainAppChartDataMax = newValue;
    //     // this.drawGraph();
    //     this.firstGraph();
    //   },
    // },
  },
  mounted() {
    // this.firstGraph();
  },
  methods: {
    arcTween(a: any) {
      const self = this;
      const i = d3.interpolate(self.current, a);
      self.current = i(0);
      return function f(t: any) {
        return self.arcGenerator(i(t));
      };
    },
    drawGraph() {
      const self = this;
      if (this.mainAppChartData.length > 0 && this.mainAppDataMax > 0) {
        const enterClockwise = {
          endAngle: 0,
          startAngle: 0,
        };
        self.path = self.sliceOrigin.selectAll('.pie').data(this.pie(self.mainAppChartData));
        self.path.exit().transition().duration(750).attrTween('d', self.arcTween).remove();
        self.path
          .enter()
          .append('g')
          .attr('class', 'pie')
          .append('path')
          .attr('fill', function a(d: any, i: number) {
            if (d.data.appliName === 'その他') {
              return '#F1F1F2';
            }
            const number: string = i.toString();
            return self.color(number);
          })
          .attr('d', self.arcGenerator(enterClockwise))
          // .on('mouseover', function (this: any, _: any, d: any) {
          //   d3.select(this).transition().attr('d', self.arcHover).duration(200);
          // })
          // Handling mouse out
          .on('mousemove', function a(this: any, event: any, data: any) {
            d3.select(this).transition().attr('d', self.arcHover).duration(100);
            // Adding tooltip
            d3.select('#tooltip-main-app-pie')
              .transition()
              .duration(0)
              .style('left', `${event.offsetX + 10}px`)
              .style('top', `${event.offsetY}px`)
              .style('opacity', 1);
            self.appliHoverTime = data.data.appliCount;
            // self.hoverCategory = data.data.key;
            // self.hoverCategoryTime = data.data.value;
            // self.hoverCategoryDetail = data.data.detail;
          })
          .on('mouseout', function a(this: any) {
            d3.select(this).transition().attr('d', self.arcGenerator).duration(100);
            d3.select('#tooltip-main-app-pie').style('opacity', 0);
          })
          .transition()
          .duration(750)
          .attrTween('d', self.arcTween);
        const textLabel = this.svg
          .selectAll('slices')
          .data(this.pie(this.mainAppChartData))
          .enter()
          .append('text')
          .text(function fn(d: any) {
            if (d.data.appliName === null) {
              return '名称無し';
            }
            return d.data.appliName;
          })
          .attr('transform', function fn(d: any) {
            if (self.mainAppChartData.length === 1) {
              return `translate(0,0)`;
            }
            return `translate(${self.arcGenerator.centroid(d)})`;
          })
          .style('text-anchor', 'middle')
          .style('font-size', 18);
        textLabel.attr('fill', function fn(d: any, i: number) {
          let textWidth = textLabel.nodes()[i].getBBox().width;
          if (textWidth > self.radius) {
            textWidth = self.radius;
          }
          if (self.textAppear(d.data) > textWidth) {
            return 'black';
          }
          return 'none';
        });
        // legend用g
        const legend = this.svgLegend
          .selectAll('legend')
          .data(self.mainAppChartData)
          .enter()
          .append('g');
        legend
          .append('rect')
          .attr('width', 20)
          .attr('height', 14)
          .attr('y', 1)
          .style('fill', function fn(d: any, i: number) {
            if (d.appliName === 'その他') {
              return '#F1F1F2';
            }
            const number: string = i.toString();
            return self.color(number);
          });
        const legendText = legend
          .append('text')
          .attr('x', 23)
          .attr('y', 14)
          .attr('font-size', '15px')
          .text(function (d: any, i: number) {
            if (d.otherFlg === true) {
              return 'その他';
            }
            return d.appliName;
          });
        // width array
        const widthArray: any = [];
        for (let i = 0; i < self.mainAppChartData.length; i += 1) {
          widthArray.push(legend.nodes()[i].getBBox().width);
        }
        const parentWidth = 853 - 20;
        let legendWidth = 0;
        let legendy = 0;
        let legentHeight = 1;
        legend.attr('transform', function (d: any, i: number) {
          if (i === 0) {
            return 'translate(0, 0)';
          }
          legendWidth += widthArray[i - 1] + 10;
          if (parentWidth < legendWidth || parentWidth < legendWidth + widthArray[i]) {
            legendy += 20;
            legendWidth = 0;
            legentHeight += 1;
          }
          return `translate(${legendWidth},${legendy})`;
        });
        this.svgLegend
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('viewBox', `0 0 ${self.width} ${legentHeight * 20}`);
        // const legend = this.svgLegend
        //   .selectAll('legend')
        //   .data(this.mainAppChartData)
        //   .enter()
        //   .append('g');
        // legend
        //   .append('rect')
        //   .attr('width', 10)
        //   .attr('height', 10)
        //   .style('fill', function (d: any, i: number) {
        //     if (d.category === 'その他') {
        //       return '#D3D3D3';
        //     }
        //     const number: string = i.toString();
        //     return self.color(number);
        //   });
        // const legendText = legend
        //   .append('text')
        //   .attr('x', 20)
        //   .attr('y', 10)
        //   .attr('dy', '.15em')
        //   .text(function (d: any, i: number) {
        //     return d.appliName;
        //   });
        // const padding = 20;
        // legend.attr('transform', function (d: any, i: number) {
        //   return `translate(${
        //     d3.sum(self.mainAppChartData, function (e: any, j: number) {
        //       if (j < i) {
        //         return legend.nodes()[j].getBBox().width;
        //       }
        //       return 0;
        //     }) +
        //     padding * i
        //   },0)`;
        // });
      }
    },
    firstGraph() {
      const self = this;
      // this.color = d3.scaleOrdinal().range(['#CCCCFF', '#FFBBFF', '#88FFFF', '#5D99FF', '#D3D3D3']);
      self.color = d3.scaleOrdinal().range(['#F8BCE6', '#B5D6FF', '#CCEDFF', '#E1DDFA', '#F1F1F2']);
      if (self.svg) {
        d3.select('#main-app-pie-chart').remove();
        d3.select('#main-app-pie-chart-legend').remove();
      }
      // const enterClockwise = {
      //   endAngle: 0,
      //   startAngle: 0,
      // };
      this.radius = Math.min(this.width, this.height) / 2;
      this.pie = d3
        .pie()
        .sort(null)
        .value(function a(d: any) {
          return d.appliCount;
        });
      this.arcHover = d3
        .arc()
        .innerRadius(0)
        .outerRadius(this.radius - 20);
      // Building arcs
      this.arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(this.radius - 30);
      this.svg = d3
        .select('#pie-chart-main')
        .append('svg')
        .attr('id', 'main-app-pie-chart')
        .attr('viewBox', `${-this.width / 2} ${-this.height / 2} ${this.width} ${this.height}`)
        .on('mousemove', function a() {
          d3.select('#tooltip-main-app-pie').style('opacity', 0);
        });
      self.svgLegend = d3
        .select('#pie-chart-main-legend')
        .append('svg')
        .attr('id', 'main-app-pie-chart-legend')
        .style('padding-left', 15)
        .style('padding-top', 10);
      self.sliceOrigin = self.svg.append('g').attr('class', 'slices');
      this.svg.append('g').attr('class', 'labels');
      this.svg.append('g').attr('class', 'lines');
      self.outerArc = d3
        .arc()
        .outerRadius(this.radius * 0.9)
        .innerRadius(this.radius * 0.9);
      self.labelArc = d3.arc().outerRadius(170).innerRadius(170);
      self.textArc = d3.arc().outerRadius(150).innerRadius(150);
      this.drawGraph();
    },
    // midAngle(d: any) {
    //   return d.startAngle + (d.endAngle - d.startAngle) / 2;
    // },
    textAppear(data: any) {
      // return Math.floor((this.radius - 30) * 3.14 * (data.appliCount / this.mainAppChartDataMax));
      // return Math.floor(0.9 * this.radius * 3.14 * (data.appliCount / this.mainAppChartDataMax));
      const result = Math.floor(1.4 * this.radius * 3.14 * (data.appliCount / this.mainAppDataMax));
      // if (result > this.radius) {
      //   result = this.radius;
      // }
      return result;
    },
  },
});
