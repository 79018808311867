












































































































































































































































































































































































































































































































































import { mdiChevronLeft, mdiChevronRight, mdiCloseCircleOutline, mdiSync } from '@mdi/js';
import dayjs from 'dayjs';
import Vue from 'vue';
// import { Route, NavigationGuardNext } from 'vue-router';
import DashboardSelectPageTab from '@/components/specific/DashboardSelectPageTab.vue';
import ScAgentDashboard from '@/components/specific/ScAgentDashboard.vue';
import TotalInfoDashboard from '@/components/specific/TotalInfoDashboard.vue';
import VoiceXDashboard from '@/components/specific/VoiceXDashboard.vue';
import ServiceFactory from '@/services/ui/ServiceFactory';
import { DomainAuthMapper } from '@/store/modules/domain/auth';
import { UICommonMapper } from '@/store/modules/ui/common';
// import { UIGoogleCalendarMapper } from '@/store/modules/ui/googlecalendar';

const DashboardService = ServiceFactory.get('dashboard');
const WorkspaceService = ServiceFactory.get('workspace');

// ユーザー
type User = {
  adminLevel?: number;
  agents?: {
    deviceId: string;
    installId: string;
  }[];
  chatUnread?: number;
  currentApp?: {
    appId: string;
    appImage: string;
    appName: string;
    appTitle: string;
    time: number;
  };
  departments: {
    departmentId: string;
    leader: number;
    departmentName: string;
  }[];
  email: string;
  emotion: number;
  image: string;
  kana: string;
  position: string;
  status: number;
  userId: string;
  userName: string;
  webPushToken?: string[];
  workspaceId: string;
  regDate?: string;
  voiceXs?: { phoneNo: string }[];
};

// ユーザーforext
type UserExt = {
  adminLevel?: number;
  agents?: {
    deviceId: string;
    installId: string;
  }[];
  chatUnread?: number;
  currentApp?: {
    appId: string;
    appImage: string;
    appName: string;
    appTitle: string;
    time: number;
  };
  departments: {
    departmentId: string;
    leader: number;
    departmentName: string;
  }[];
  email: string;
  emotion: number;
  image: string;
  kana: string;
  position: string;
  status: number;
  userId: string;
  userName: string;
  webPushToken?: string[];
  workspaceId: string;
  regDate?: string;
  voiceXs: { phoneNo: string }[];
};

type UserInformation = {
  _id: string;
  workspaceId: string;
  projectId: string;
  memberId: string;
  regDate: string;
};

export default Vue.extend({
  name: 'Dashboard',
  components: {
    DashboardSelectPageTab,
    ScAgentDashboard,
    TotalInfoDashboard,
    VoiceXDashboard,
  },
  data(): {
    beforeDate: string;
    calendarDate: string;
    calendarDateSub: any;
    departmentList: {
      _id?: string;
      workspaceId?: string;
      departmentId?: string;
      departmentName?: string;
      regDate?: string;
    }[];
    divisionNumScagent: number;
    divisionNumVoicex: number;
    divisionType: string;
    didList: string[];
    extList: User[];
    // extList: Record<string, string | number | object | undefined>[];
    groupTab: number;
    leftIcon: string;
    menu: boolean;
    menu2: boolean;
    menu3: boolean;
    menu4: boolean;
    period: string;
    rightIcon: string;
    projectList: {
      _id?: string;
      workspaceId?: string;
      projectId?: string;
      projectName?: string;
      regDate?: string;
    }[];
    selectedCalendarDate: string;
    selectedCalendarDateSub: number;
    selectedCalendarMonthSub: number;
    selectedCalendarYearSub: number;
    selectedGroup: string;
    selectedGroupSub: string;
    selectedId: string;
    // selectedPhone: string;
    // selectedPhoneUser: Record<string, string | number | object | undefined>;
    selectedPhoneUser: Record<string, unknown>;
    // selectedPhoneUser: UserExt;
    // selectedPhoneUserSub: Record<string, string | number | object | undefined>;
    selectedPhoneUserSub: Record<string, unknown>;
    // selectedPhoneUserSub: UserExt;
    // selectedUser: Record<string, string | number | object | undefined>;
    selectedUser: Record<string, unknown>;
    selectedUserId: string;
    selectedUserIdSub: string;
    // selectedUserSub: string;
    // selectedUserSub: Record<string, string | number | object | undefined>;
    selectedUserSub: Record<string, unknown>;
    tabType: number;
    targetObject: {
      selectedGroup: string;
      selectedId: string;
      selectedUser?: Record<string, unknown>;
      selectedDid?: string;
      gFlg: number;
    };
    targetObjectSub: {
      selectedGroup: string;
      selectedId: string;
      selectedUser?: Record<string, unknown>;
      selectedDid?: string;
      gFlg: number;
    };
    targetObjectUser: {
      selectedGroup: string;
      selectedId: string;
      selectedUser?: Record<string, unknown>;
      selectedDid?: string;
      gFlg: number;
    };
    // targetObjectUserSub: {
    //   selectedGroup: string;
    //   selectedId: string;
    //   selectedUser?: {};
    //   selectedDid?: string;
    //   gFlg: number;
    // };
    userLists: User[];
    search: string;
    weekFlg: boolean;
    monthFlg: boolean;
    selectPeriodWeekFlg: boolean;
    // selectedYear: string[];
    selectedDepartmentItem: number | string;
    selectedDidItem: number | string;
    selectedDidNo: string;
    selectedDidNoSub: string;
    selectedProjectItem: number | string;
    selectedExtItem: number | string;
    selectedExtNoSub: string;
    // selectedExtUserSub: string;
    selectedUserItem: number | string;
    // selectedUserItemSub: number | string;
    selectedYearItem: number | string;
    selectedYearItemSub: number | string;
    // selectedUserFlg: boolean;
    yearList: string[];
    yearFlg: boolean;
    calendarWeekSub: string;
    cancelUserIcon: string;
    updateIcon: string;
    usagePhoneUpdateFlg: boolean;
    usagePCUpdateFlg: boolean;
    telephoneLineTab: number;
    totalUpdateFlg: boolean;
    typeOfPhone: string;
    typeOfPhoneSub: string;
    updateButtonFlg: boolean;
    workspaceId: string;
  } {
    return {
      beforeDate: '',
      calendarDate: '',
      calendarDateSub: '',
      calendarWeekSub: '',
      cancelUserIcon: mdiCloseCircleOutline,
      departmentList: [],
      didList: [],
      divisionNumScagent: 0,
      divisionNumVoicex: 0,
      divisionType: '',
      extList: [],
      groupTab: 1,
      leftIcon: mdiChevronLeft,
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      monthFlg: false,
      period: 'day',
      projectList: [],
      rightIcon: mdiChevronRight,
      search: '',
      selectPeriodWeekFlg: false,
      selectedCalendarDate: '',
      selectedCalendarDateSub: 0,
      selectedCalendarMonthSub: 0,
      selectedCalendarYearSub: 0,
      selectedDepartmentItem: '',
      selectedDidItem: '',
      selectedDidNo: '',
      selectedDidNoSub: '',
      selectedExtItem: '',
      selectedExtNoSub: '',
      // selectedExtUserSub: '',
      selectedGroup: 'メインサークル',
      selectedGroupSub: '',
      selectedId: 'main',
      // selectedPhone: '',
      selectedPhoneUser: {},
      selectedPhoneUserSub: {},
      selectedProjectItem: '',
      selectedUser: {},
      // selectedUserFlg: false,
      selectedUserId: '',
      selectedUserIdSub: '',
      selectedUserItem: '',
      // selectedUserItemSub: '',
      selectedUserSub: {},
      // selectedYear: ['2022'],
      selectedYearItem: 20,
      selectedYearItemSub: 20,
      tabType: 0,
      targetObject: {
        gFlg: 0,
        selectedDid: '',
        selectedGroup: 'projectId',
        selectedId: 'main',
        selectedUser: {},
      },
      targetObjectSub: {
        gFlg: 0,
        selectedDid: '',
        selectedGroup: '',
        selectedId: '',
        selectedUser: {},
      },
      targetObjectUser: {
        gFlg: 0,
        selectedDid: '',
        selectedGroup: 'projectId',
        selectedId: 'main',
        selectedUser: {},
      },
      // targetObjectUserSub: {
      //   gFlg: 0,
      //   selectedDid: '',
      //   selectedGroup: '',
      //   selectedId: '',
      //   selectedUser: {},
      // },
      telephoneLineTab: 0,
      totalUpdateFlg: false,
      typeOfPhone: 'ext',
      typeOfPhoneSub: 'ext',
      updateButtonFlg: false,
      updateIcon: mdiSync,
      usagePCUpdateFlg: false,
      usagePhoneUpdateFlg: false,
      userLists: [],
      weekFlg: false,
      workspaceId: '',
      yearFlg: false,
      yearList: [],
    };
  },
  computed: {
    ...DomainAuthMapper.mapState(['userAttributes']),
    // ...UIGoogleCalendarMapper.mapState(['toastCheck']),
    ...UICommonMapper.mapState(['userList']),
    // answerA(this: any) {
    //   return this.divisionNumScagent;
    // },
    // answerB(this: any) {
    //   return this.divisionNumVoicex;
    // },
    dateRangeText(this: any) {
      if (this.period === 'day') {
        const checkDay = dayjs(this.calendarDate);
        return `${checkDay.year()}年${checkDay.month() + 1}月${checkDay.date()}日`;
      }
      if (this.period === 'week') {
        // const weekStart: string = dayjs(this.calendarDate[0]).format('YYYY年MM月DD日');
        // const weekEnd: string = dayjs(this.calendarDate[1]).format('YYYY年MM月DD日');
        const checkDate = dayjs(this.calendarDate);
        const day = checkDate.day();
        const weekStart: string = checkDate.subtract(day, 'day').format('YYYY年MM月DD日');
        const weekEnd: string = checkDate.add(6 - day, 'day').format('YYYY年MM月DD日');
        return [weekStart, weekEnd].join(' ~ ');
      }
      if (this.period === 'month') {
        return dayjs(this.calendarDate).format('YYYY年MM月');
      }
      if (this.period === 'year') {
        return dayjs(this.calendarDate).format('YYYY年');
      }
      return this.calendarDate;
    },
    returnFilterDidList(this: any): string[] {
      const self = this;
      if (self.search) {
        const result = self.didList.filter(function a(element: string) {
          return element.indexOf(self.search) !== -1;
        });
        self.setDidItem(result);
        return result;
      }
      self.setDidItem(self.didList);
      return self.didList;
    },
    returnFilterExtList(this: any): User[] {
      const self = this;
      if (self.search) {
        const result = self.extList.filter(function a(element: User) {
          const { kana } = element;
          const hiragana = self.kanaToHira(kana.replaceAll(/\s+/g, ''));
          const result1 = hiragana.indexOf(self.search) !== -1;
          const userName = element.userName.replaceAll(/\s+/g, '');
          const result2 = userName.indexOf(self.search) !== -1;
          let result3 = false;
          if (element.voiceXs && element.voiceXs[0]) {
            result3 = element.voiceXs[0].phoneNo.indexOf(self.search) !== -1;
          }
          return result1 || result2 || result3;
        });
        self.setExtItem(result);
        return result;
      }
      self.setExtItem(self.extList);
      return self.extList;
    },
    returnFilterUserList(this: any): User[] {
      const self = this;
      if (self.search) {
        const result = self.userLists.filter(function a(element: User) {
          const { kana } = element;
          const hiragana = self.kanaToHira(kana.replaceAll(/\s+/g, ''));
          const result1 = hiragana.indexOf(self.search) !== -1;
          const userName = element.userName.replaceAll(/\s+/g, '');
          const result2 = userName.indexOf(self.search) !== -1;
          return result1 || result2;
        });
        self.setUserItem(result);
        return result;
      }
      self.setUserItem(self.userLists);
      return self.userLists;
    },
    returnSelectedPhone(this: any) {
      if (this.typeOfPhone === 'ext') {
        if (this.selectedPhoneUser.userName) {
          return `${this.selectedPhoneUser.userName}(${this.selectedPhoneUser.voiceXs[0].phoneNo})`;
        }
        if (!this.selectedPhoneUser.userName && this.selectedPhoneUser.voiceXs) {
          return this.selectedPhoneUser.voiceXs[0].phoneNo;
        }
      }
      if (this.typeOfPhone === 'did' && this.selectedDidNo) {
        return this.selectedDidNo;
      }
      return '';
    },
    returnSelectedUser(this: any) {
      if (Object.keys(this.selectedUser).length) {
        return this.selectedUser.userName;
      }
      return '';
    },
  },
  watch: {
    // calendarDate() {
    //   if (this.period === 'week') {
    //     this.weekFlg = true;
    //   }
    //   if (this.period === 'month') {
    //     this.monthFlg = true;
    //   }
    //   if (this.period === 'year') {
    //     this.yearFlg = true;
    //   }
    // },
    calendarDateSub() {
      if (this.period === 'week') {
        this.weekFlg = true;
      }
      if (this.period === 'month') {
        this.monthFlg = true;
      }
      if (this.period === 'year') {
        this.yearFlg = true;
      }
    },
    menu() {
      const self = this;
      if (this.period === 'year') {
        if (self.selectedYearItem !== self.selectedYearItemSub) {
          self.selectedYearItem = self.selectedYearItemSub;
        }
        setTimeout(() => {
          // const check = document.getElementById(`year-list-${self.selectedCalendarYearSub}`);
          const check = document.getElementById(`year-list-${self.calendarDate}`);
          if (check) {
            check.scrollIntoView(true);
          }
        }, 200);
      }
      // this.$nextTick().then(() => {
      //   const check = document.getElementById('year-list-2022');
      //   if (check) {
      //     check.scrollIntoView(true);
      //   }
      // });
    },
    // menu2() {
    // },
    menu3() {
      const self = this;
      if (!Object.keys(self.selectedUser).length) {
        // if (self.selectedUser === '') {
        self.selectedUserItem = '';
      }
      if (self.selectedUserItem === '' || self.selectedUserItem === undefined) {
        setTimeout(() => {
          ((self.$refs.scroll2 as Vue).$el as SVGElement).scrollTop = 0;
        }, 1);
      } else {
        setTimeout(() => {
          const check = document.getElementById(`user-list-${self.selectedUserId}`);
          if (check) {
            check.scrollIntoView(true);
          }
        }, 200);
      }
    },
    menu4() {
      const self = this;
      if (!Object.keys(self.selectedUser).length) {
        // if (self.selectedUser === '') {
        self.selectedUserItem = '';
      }
      if (self.selectedUserItem === '' || self.selectedUserItem === undefined) {
        setTimeout(() => {
          ((self.$refs.scroll2 as Vue).$el as SVGElement).scrollTop = 0;
        }, 1);
      } else {
        setTimeout(() => {
          const check = document.getElementById(`user-list-${self.selectedUserId}`);
          if (check) {
            check.scrollIntoView(true);
          }
        }, 200);
      }
    },
    monthFlg() {
      const self = this;
      if (self.monthFlg) {
        self.selectedCalendarMonthSub = dayjs(self.calendarDate).month();
        setTimeout(() => {
          self.monthFlg = false;
        }, 0);
      }
    },
    selectedDepartmentItem() {
      if (this.selectedDepartmentItem === undefined) {
        this.selectedGroupSub = '';
      }
    },
    selectedDidItem() {
      if (this.selectedDidItem === undefined) {
        this.selectedDidNoSub = '';
      }
    },
    selectedExtItem() {
      if (this.selectedExtItem === undefined) {
        this.selectedExtNoSub = '';
        this.selectedPhoneUserSub = {};
      }
    },
    selectedProjectItem() {
      if (this.selectedProjectItem === undefined) {
        this.selectedGroupSub = '';
      }
    },
    selectedUserItem() {
      if (this.selectedUserItem === undefined) {
        // this.selectedUserSub = '';
        this.selectedUserSub = {};
        this.selectedUserId = '';
        this.selectedUserIdSub = '';
      }
    },
    weekFlg() {
      const self = this;
      if (self.weekFlg) {
        let checkDate;
        if (typeof self.calendarDateSub === 'string' && self.calendarDateSub) {
          // self.selectedCalendarMonthSub = dayjs(self.calendarDate).month();
          // self.selectedCalendarDateSub = dayjs(self.calendarDate).date();
          // self.selectedCalendarMonthSub = dayjs(self.calendarDateSub).month();
          // self.selectedCalendarDateSub = dayjs(self.calendarDateSub).date();
          checkDate = dayjs(self.calendarDateSub);
        }
        if (typeof self.calendarDateSub === 'object') {
          // self.selectedCalendarMonthSub = dayjs(self.calendarDate).month();
          // self.selectedCalendarDateSub = dayjs(self.calendarDate).date();
          // checkDate = dayjs(self.calendarDate[0]);
          // self.selectedCalendarMonthSub = dayjs(self.calendarDateSub[0]).month();
          // self.selectedCalendarDateSub = dayjs(self.calendarDateSub[0]).date();
          checkDate = dayjs(self.calendarDateSub[0]);
        }
        if (self.calendarDateSub === '') {
          checkDate = dayjs(self.calendarDate);
        }
        if (checkDate) {
          self.calendarWeekSub = checkDate.format('YYYY-MM-DD');
          const day = checkDate.day();
          const weekStart: string = checkDate.subtract(day, 'day').format('YYYY-MM-DD');
          const weekEnd: string = checkDate.add(6 - day, 'day').format('YYYY-MM-DD');
          self.calendarDateSub = [weekStart, weekEnd];
          setTimeout(() => {
            self.weekFlg = false;
          }, 0);
        }
      }
    },
    // yearFlg() {
    //   const self = this;
    //   if (self.yearFlg) {
    //     self.selectedCalendarYearSub = dayjs(self.calendarDate).year();
    //     setTimeout(() => {
    //       self.yearFlg = false;
    //     }, 0);
    //   }
    // },
  },
  // async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
  //   // console.log('checkcheck to hoge', to);
  //   // console.log('checkcheck from hoge', from);
  //   next(() => {
  //     // vm.setToastCheck(true)
  //     console.log('checkcheck to hoge', to);
  //     console.log('checkcheck from hoge', from);
  //     next({ name: 'Dashboard' });
  //     // vm.setToastCheck(false)
  //   });
  //   // next({ name: 'Dashboard' });
  // },
  mounted() {
    const { workspaceId } = this.userAttributes;
    this.workspaceId = workspaceId;
    // resizeイベント追加
    window.addEventListener('resize', this.getElementHeight);
    // targetObjeSubにセット
    this.targetObjectSub = JSON.parse(JSON.stringify(this.targetObject));
    this.calendarDate = dayjs().format('YYYY-MM-DD');
    this.selectedCalendarYearSub = dayjs(this.calendarDate).year();
    this.selectedCalendarMonthSub = dayjs(this.calendarDate).month();
    this.selectedCalendarDateSub = dayjs(this.calendarDate).date();
    this.selectedCalendarDate = dayjs().format('YYYY-MM-DD');
    const now = dayjs().format('YYYYMMDD');
    const beforeDay = dayjs(now).subtract(1, 'day').format('YYYYMMDD');
    this.beforeDate = beforeDay;
    // this.getAllUser();
    this.getExtList();
    this.getDepartmentList();
    this.getProjectList();
    this.getWorkspaceInformation();
    this.setYearList();
    this.setGroupData(true);
    setTimeout(this.getElementHeight, 0);
  },
  created() {
    this.hideAllPopup();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getElementHeight);
  },
  methods: {
    ...UICommonMapper.mapActions([
      'setNavigating',
      'setMessage',
      'setErrorMessage',
      'hideAllPopup',
    ]),
    // ...UIGoogleCalendarMapper.mapActions(['setToastCheck']),
    cancelDate() {
      this.menu = false;
    },
    cancelGroup() {
      this.menu2 = false;
    },
    cancelPhone() {
      this.menu3 = false;
    },
    cancelUser() {
      this.menu4 = false;
    },
    changeGroupTab(value: number) {
      this.groupTab = value;
      let checkFlg = false;
      if (this.groupTab === 0) {
        for (let i = 0; i < this.departmentList.length; i += 1) {
          if (this.departmentList[i].departmentName === this.selectedGroup) {
            this.selectedDepartmentItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          this.selectedDepartmentItem = '';
        }
      } else {
        for (let i = 0; i < this.projectList.length; i += 1) {
          if (this.projectList[i].projectName === this.selectedGroup) {
            this.selectedProjectItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          this.selectedProjectItem = '';
        }
      }
    },
    changeTab(value: number) {
      this.tabType = value;
    },
    changeTelephoneLineTab(value: number) {
      this.telephoneLineTab = value;
      // 以下追加
      let checkFlg = false;
      if (this.telephoneLineTab === 0) {
        this.typeOfPhoneSub = 'ext';
        if (this.selectedDidNo) {
          this.selectedDidNoSub = '';
          this.selectedDidItem = '';
        }
        if (Object.keys(this.selectedPhoneUser).length) {
          this.selectedPhoneUserSub = this.selectedPhoneUser;
        }
        const copyExtlist = JSON.parse(JSON.stringify(this.extList));
        for (let i = 0; i < copyExtlist.length; i += 1) {
          if (
            Array.isArray(this.selectedPhoneUser.voiceXs) &&
            Array.isArray(this.extList[i].voiceXs) &&
            copyExtlist[i].voiceXs[0].phoneNo === this.selectedPhoneUser.voiceXs[0].phoneNo
          ) {
            this.selectedExtItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          // this.selectedDepartmentItem = '';
          this.selectedExtItem = '';
        }
      } else {
        this.typeOfPhoneSub = 'did';
        if (Object.keys(this.selectedPhoneUser).length) {
          this.selectedPhoneUserSub = this.selectedPhoneUser;
          this.selectedExtItem = '';
        }
        if (this.selectedDidNo) {
          this.selectedDidNoSub = this.selectedDidNo;
        }
        this.selectedUserSub = {};
        for (let i = 0; i < this.didList.length; i += 1) {
          if (this.didList[i] === this.selectedDidNo) {
            this.selectedDidItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          // this.selectedProjectItem = '';
          this.selectedDidItem = '';
        }
      }
    },
    clickDate() {
      this.calendarDateSub = this.calendarDate;
    },
    clickGroup() {
      this.selectedGroupSub = this.selectedGroup;
      let checkFlg = false;
      if (this.groupTab === 0) {
        for (let i = 0; i < this.departmentList.length; i += 1) {
          if (this.departmentList[i].departmentName === this.selectedGroup) {
            this.selectedDepartmentItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          this.selectedDepartmentItem = '';
        }
      } else {
        for (let i = 0; i < this.projectList.length; i += 1) {
          if (this.projectList[i].projectName === this.selectedGroup) {
            this.selectedProjectItem = i;
            checkFlg = true;
          }
        }
        if (!checkFlg) {
          this.selectedProjectItem = '';
        }
      }
    },
    clickPhone() {
      // this.selectedNumberSub = this.selectedNumber;
      // this.selectedPhoneSub = this.selectedPhone;
      if (this.typeOfPhone === 'ext') {
        this.selectedPhoneUserSub = this.selectedPhoneUser;
      } else {
        this.selectedDidNoSub = this.selectedDidNo;
      }
    },
    clickUser() {
      // this.selectedUserSub = this.selectedUser;
    },
    createUserListObject(array: string[]): UserExt[] {
      const { workspaceId } = this.userAttributes;
      const returnArray: UserExt[] = [];
      array.forEach((item, i) => {
        returnArray.push({
          adminLevel: 0,
          currentApp: {
            appId: '0',
            appImage: '',
            appName: '',
            appTitle: '',
            time: 0,
          },
          departments: [{ departmentId: '', departmentName: '', leader: 0 }],
          email: '',
          emotion: 0,
          image: '',
          kana: '',
          position: '',
          regDate: '',
          status: 0,
          // updDate: '',
          userId: `none-${i}`,
          userName: '',
          voiceXs: [{ phoneNo: item }],
          webPushToken: [''],
          workspaceId,
        });
      });
      return returnArray;
    },
    deleteNumber() {
      this.selectedPhoneUser = {};
      this.selectedDidNo = '';
      this.targetObject.selectedUser = {};
      this.targetObject.selectedDid = '';
      this.selectedExtItem = '';
      this.selectedDidItem = '';
      // 選択されているユーザーも空にする
      this.selectedUser = {};
      this.selectedUserId = '';
      this.targetObjectUser.selectedUser = {};
    },
    deleteUser() {
      this.selectedUser = {};
      this.selectedUserId = '';
      this.selectedUserIdSub = '';
      this.targetObjectUser.selectedUser = {};
      if (
        this.targetObjectUser.selectedGroup === 'projectId' &&
        this.targetObject.selectedId === 'none'
      ) {
        this.divisionNumScagent = this.projectList.length;
        this.divisionNumVoicex = this.projectList.length;
      } else if (
        this.targetObjectUser.selectedGroup === 'departmentId' &&
        this.targetObject.selectedId !== 'none'
      ) {
        this.divisionNumScagent = this.departmentList.length;
        this.divisionNumVoicex = this.departmentList.length;
      } else {
        this.divisionNumScagent = this.userLists.length;
        this.divisionNumVoicex = this.extList.length;
      }
      // 選択されている電話番号は消す
      this.selectedPhoneUser = {};
      this.selectedDidNo = '';
      this.targetObject.selectedUser = {};
      // this.targetObject = JSON.parse(JSON.stringify(this.targetObjectSub));
    },
    filterExtList(extList: UserExt[]) {
      const self = this;
      if (extList.length > 0) {
        return self.userLists.filter(function a(element) {
          return extList.some(function b(element2) {
            return element.userId === element2.userId;
          });
        });
      }
      return [];
    },
    filterUserList(userLists: UserInformation[]) {
      // filterUserList(userLists: string[]) {
      const self = this;
      const returnUserList = self.userList.filter(function a(element) {
        return userLists.some(function b(element2) {
          return element.userId === element2.memberId;
        });
      });
      return returnUserList;
    },
    // async getAllUser() {
    //   const { workspaceId } = this.userAttributes;
    //   let userLists = [];
    //   // let notConnectExtentionLists = [];
    //   if (this.targetObject.selectedGroup === 'projectId') {
    //     userLists = await DashboardService.getProjectMemberList({
    //       projectId: this.targetObject.selectedId,
    //       workspaceId,
    //     });
    //   } else if (this.targetObject.selectedGroup === 'departmentId') {
    //     userLists = await DashboardService.getDepartmentMemberList({
    //       departmentId: this.targetObject.selectedId,
    //       workspaceId,
    //     });
    //   }
    //   this.userLists = this.filterUserList(userLists);
    // },
    async getDepartmentList() {
      const { workspaceId } = this.userAttributes;
      const departmentList = await DashboardService.getDepartmentList({
        workspaceId,
      });
      departmentList.unshift({
        _id: 'none',
        departmentId: 'none',
        departmentName: '全体',
        regDate: 'none',
        workspaceId,
      });
      this.departmentList = departmentList;
      // if (this.targetObject.selectedGroup === 'departmentId') {
      //   this.divisionNumVoicex = departmentList.length;
      //   this.divisionNumScagent = departmentList.length;
      //   // this.divisionType = 'department';
      // }
    },
    getElementHeight() {
      const elementParent = document.querySelector('div.dashboard');
      const element = document.querySelector<HTMLElement>(
        'div.dashboard > div.dashboard-body > div.ps'
      );
      if (elementParent && element) {
        const parentSize = elementParent.getBoundingClientRect();
        // const size = element.getBoundingClientRect();
        element.style.height = `${parentSize.height - 155}px`;
      }
    },
    async getExtList() {
      const self = this;
      self.extList = [];
      const resultGetExtentionList = await DashboardService.getExtentionList({
        workspaceId: self.workspaceId,
      });
      const resultGetExtentionAList = await DashboardService.getExtentionAList({
        state: 'A',
        workspaceId: self.workspaceId,
      });
      const notConnectExtentionLists = this.createUserListObject(
        resultGetExtentionAList.extensions
      );
      const filterExtList = this.filterExtList(resultGetExtentionList);
      self.extList = filterExtList.concat(notConnectExtentionLists);
      // 全体を表示しているときは内線の数で平均を出さない
      if (this.divisionType !== 'group') {
        self.divisionNumVoicex = self.extList.length;
      }
    },
    async getProjectList() {
      const { workspaceId } = this.userAttributes;
      const projectList = await DashboardService.getProjectList({
        workspaceId,
      });
      projectList.unshift({
        _id: 'none',
        projectId: 'none',
        projectName: '全体',
        regDate: 'none',
        workspaceId,
      });
      this.projectList = projectList;
      // if (this.targetObject.selectedGroup === 'projectId') {
      //   this.divisionNumVoicex = projectList.length;
      //   this.divisionNumScagent = projectList.length;
      // }
    },
    async getWorkspaceInformation() {
      const result = await WorkspaceService.getWorkspace(this.workspaceId);
      this.didList = result.voiceXDID;
    },
    kanaToHira(str: string) {
      return str.replace(/[\u30a1-\u30f6]/g, function fn(match) {
        const chr = match.charCodeAt(0) - 0x60;
        return String.fromCharCode(chr);
      });
    },
    nextPeriod() {
      if (this.period === 'day') {
        this.calendarDate = dayjs(this.calendarDate).add(1, 'day').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYYMMDD');
      } else if (this.period === 'week') {
        this.calendarDate = dayjs(this.calendarDate).add(1, 'week').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'week').format('YYYYMMDD');
      } else if (this.period === 'month') {
        this.calendarDate = dayjs(this.calendarDate).add(1, 'month').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'month').format('YYYYMMDD');
      } else if (this.period === 'year') {
        this.calendarDate = dayjs(this.calendarDate).add(1, 'year').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'year').format('YYYYMMDD');
      }
      this.selectedCalendarDate = this.calendarDate;
    },
    previousPeriod() {
      if (this.period === 'day') {
        this.calendarDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYYMMDD');
      } else if (this.period === 'week') {
        this.calendarDate = dayjs(this.calendarDate).subtract(1, 'week').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'week').format('YYYYMMDD');
      } else if (this.period === 'month') {
        this.calendarDate = dayjs(this.calendarDate).subtract(1, 'month').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'month').format('YYYYMMDD');
      } else if (this.period === 'year') {
        this.calendarDate = dayjs(this.calendarDate).subtract(1, 'year').format('YYYY-MM-DD');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'year').format('YYYYMMDD');
      }
      this.selectedCalendarDate = this.calendarDate;
    },
    selectDay() {
      this.menu = false;
      // 押した瞬間Subは更新
      if (this.period === 'day') {
        this.selectedCalendarYearSub = dayjs(this.calendarDateSub).year();
        this.selectedCalendarMonthSub = dayjs(this.calendarDateSub).month();
        this.selectedCalendarDateSub = dayjs(this.calendarDateSub).date();
        // let checkDate = dayjs().year(this.selectedCalendarYearSub);
        // checkDate = checkDate.month(this.selectedCalendarMonthSub);
        // checkDate = checkDate.date(this.selectedCalendarDateSub);
        // this.calendarDate = checkDate.format('YYYY-MM-DD');
        // this.beforeDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYYMMDD');
      } else if (this.period === 'week') {
        this.selectedCalendarYearSub = dayjs(this.calendarWeekSub).year();
        this.selectedCalendarMonthSub = dayjs(this.calendarWeekSub).month();
        this.selectedCalendarDateSub = dayjs(this.calendarWeekSub).date();
        // let checkDate = dayjs().year(this.selectedCalendarYearSub);
        // checkDate = checkDate.month(this.selectedCalendarMonthSub);
        // checkDate = checkDate.date(this.selectedCalendarDateSub);
        // this.calendarDate = checkDate.format('YYYY-MM-DD');
        // this.beforeDate = dayjs(this.calendarDate).subtract(1, 'week').format('YYYYMMDD');
      } else if (this.period === 'month') {
        this.selectedCalendarYearSub = dayjs(this.calendarDateSub).year();
        this.selectedCalendarMonthSub = dayjs(this.calendarDateSub).month();
        // let checkDate = dayjs().year(this.selectedCalendarYearSub);
        // checkDate = checkDate.month(this.selectedCalendarMonthSub);
        // checkDate = checkDate.date(this.selectedCalendarDateSub);
        // this.calendarDate = checkDate.format('YYYY-MM-DD');
        // this.beforeDate = dayjs(this.calendarDate).subtract(1, 'month').format('YYYYMMDD');
      } else if (this.period === 'year') {
        this.selectedCalendarYearSub = dayjs(this.calendarDateSub).year();
        // let checkDate = dayjs().year(this.selectedCalendarYearSub);
        // checkDate = checkDate.month(this.selectedCalendarMonthSub);
        // checkDate = checkDate.date(this.selectedCalendarDateSub);
        // this.calendarDate = checkDate.format('YYYY-MM-DD');
        // this.selectedYearItemSub = this.selectedYearItem;
        // this.beforeDate = dayjs(this.calendarDate).subtract(1, 'year').format('YYYYMMDD');
      }
      let checkDate = dayjs().year(this.selectedCalendarYearSub);
      checkDate = checkDate.month(this.selectedCalendarMonthSub);
      checkDate = checkDate.date(this.selectedCalendarDateSub);
      this.calendarDate = checkDate.format('YYYY-MM-DD');
      this.beforeDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYYMMDD');
      this.selectedCalendarDate = this.calendarDate;
    },
    selectDid(value: string) {
      // this.typeOfPhoneSub = 'did';
      this.selectedDidNoSub = value;
    },
    selectExt(value: User) {
      if (value.voiceXs) {
        // this.typeOfPhoneSub = 'ext';
        this.selectedPhoneUserSub = value;
        this.selectedExtNoSub = value.voiceXs[0].phoneNo;
      }
    },
    selectGroup1(value: {
      _id?: string;
      workspaceId?: string | undefined;
      departmentId?: string | undefined;
      departmentName?: string | undefined;
      regDate?: string | undefined;
    }) {
      this.selectedUserItem = '';
      // if (this.groupTab === 0) {
      if (value.departmentId && value.departmentName) {
        this.selectedId = value.departmentId;
        this.selectedGroupSub = value.departmentName;
      }
      // }
      // else {
      //   this.selectedId = value.projectId;
      //   this.selectedGroupSub = value.projectName;
      // }
    },
    selectGroup2(value: {
      _id?: string | undefined;
      workspaceId?: string | undefined;
      projectId?: string | undefined;
      projectName?: string | undefined;
      regDate?: string | undefined;
    }) {
      this.selectedUserItem = '';
      if (value.projectId && value.projectName) {
        this.selectedId = value.projectId;
        this.selectedGroupSub = value.projectName;
      }
    },
    selectPeriod(value: string) {
      // periodを変更したとき、calendarDateが週のものだったら、Copyしていたものにする。
      // if (typeof this.calendarDate !== 'string') {
      this.period = value;
      let setDate;
      if (this.selectPeriodWeekFlg) {
        // setDate = dayjs(this.calendarDate[0]).month(this.selectedCalendarMonthSub);
        setDate = dayjs(this.calendarDate).month(this.selectedCalendarMonthSub);
        this.selectPeriodWeekFlg = false;
      } else {
        setDate = dayjs(this.calendarDate).month(this.selectedCalendarMonthSub);
      }
      this.calendarDate = setDate.date(this.selectedCalendarDateSub).format('YYYY-MM-DD');
      if (value === 'day') {
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'day').format('YYYYMMDD');
      } else if (value === 'week') {
        // this.selectedCalendarYearSub = dayjs(this.calendarDate).year();
        this.selectedCalendarMonthSub = dayjs(this.calendarDate).month();
        this.selectedCalendarDateSub = dayjs(this.calendarDate).date();
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'week').format('YYYYMMDD');
        this.weekFlg = true;
        this.selectPeriodWeekFlg = true;
      } else if (value === 'month') {
        this.selectedCalendarMonthSub = dayjs(this.calendarDate).month();
        this.selectedCalendarDateSub = dayjs(this.calendarDate).date();
        this.calendarDate = dayjs(this.calendarDate).format('YYYY-MM');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'month').format('YYYYMMDD');
      } else if (value === 'year') {
        this.calendarDate = dayjs(this.calendarDate).format('YYYY');
        this.beforeDate = dayjs(this.calendarDate).subtract(1, 'year').format('YYYYMMDD');
      }
    },
    selectUser(value: User) {
      // this.selectedUserSub = value.userName;
      this.selectedUserSub = value;
      this.selectedUserIdSub = value.userId;
    },
    selectYear(value: string) {
      this.calendarDateSub = value;
    },
    setDidItem(didList: string[]) {
      if (didList.length > 0) {
        for (let i = 0; i < didList.length; i += 1) {
          if (didList[i] && didList[i] === this.selectedDidNoSub) {
            this.selectedDidItem = i;
            return;
          }
          this.selectedDidItem = '';
        }
      } else {
        this.selectedDidItem = '';
      }
    },
    setExtItem(extList: UserExt[]) {
      if (extList.length > 0) {
        for (let i = 0; i < extList.length; i += 1) {
          if (extList[i] && extList[i].voiceXs[0].phoneNo === this.selectedExtNoSub) {
            this.selectedExtItem = i;
            return;
          }
          this.selectedExtItem = '';
        }
      } else {
        this.selectedExtItem = '';
      }
    },
    async setGroupData(first?: boolean) {
      const { workspaceId } = this.userAttributes;
      let userLists = [];
      if (first) {
        userLists = await DashboardService.getProjectMemberList({
          projectId: this.selectedId,
          workspaceId,
        });
        this.userLists = this.filterUserList(userLists);
        this.divisionNumScagent = this.userLists.length;
        this.divisionType = 'user';
        return;
      }
      if (this.selectedGroupSub) {
        this.selectedGroup = this.selectedGroupSub;
      } else {
        this.selectedGroup = '全体';
        this.selectedId = 'none';
      }
      if (this.groupTab === 0 && this.selectedId !== 'none') {
        // 選択した条件でtargetObjectに情報を入れる
        this.targetObject.selectedGroup = 'departmentId';
        this.targetObject.selectedId = this.selectedId;
        this.targetObject.gFlg = 1;
        this.targetObject.selectedUser = {};
        userLists = await DashboardService.getDepartmentMemberList({
          departmentId: this.selectedId,
          workspaceId,
        });
        // 選択した条件でtargetObjectUserに情報を入れる
        this.targetObjectUser.selectedGroup = 'departmentId';
        this.targetObjectUser.selectedId = this.selectedId;
        this.targetObjectUser.gFlg = 1;
        this.targetObjectUser.selectedUser = {};
        // 平均に使う数字を入れる
        this.divisionNumScagent = userLists.length;
        this.divisionType = 'user';
      } else if (this.groupTab === 1 && this.selectedId !== 'none') {
        this.targetObject.selectedGroup = 'projectId';
        this.targetObject.selectedId = this.selectedId;
        this.targetObject.gFlg = 0;
        this.targetObject.selectedUser = {};
        userLists = await DashboardService.getProjectMemberList({
          projectId: this.selectedId,
          workspaceId,
        });
        // 選択した条件でtargetObjectUserに情報を入れる
        this.targetObjectUser.selectedGroup = 'projectId';
        this.targetObjectUser.selectedId = this.selectedId;
        this.targetObjectUser.gFlg = 0;
        this.targetObjectUser.selectedUser = {};
        // 平均に使う数字を入れる
        this.divisionNumScagent = userLists.length;
        this.divisionType = 'user';
        // 全体を選ばれた場合（部署）
      } else if (this.groupTab === 0 && this.selectedId === 'none') {
        this.targetObject.selectedGroup = 'departmentId';
        // this.targetObject.selectedGroup = 'none';
        this.targetObject.selectedId = 'none';
        this.targetObject.gFlg = 1;
        this.targetObject.selectedUser = {};
        userLists = await DashboardService.getProjectMemberList({
          projectId: 'main',
          workspaceId,
        });
        // 選択した条件でtargetObjectUserに情報を入れる
        this.targetObjectUser.selectedGroup = 'departmentId';
        this.targetObjectUser.selectedId = 'none';
        this.targetObjectUser.gFlg = 1;
        this.targetObjectUser.selectedUser = {};
        // 平均に使う数字を入れる
        this.divisionNumScagent = this.departmentList.length - 1;
        this.divisionNumVoicex = this.departmentList.length - 1;
        this.divisionType = 'group';
        // 全体を選ばれた場合（プロジェクト）
      } else if (this.groupTab === 1 && this.selectedId === 'none') {
        this.targetObject.selectedGroup = 'projectId';
        // this.targetObject.selectedGroup = 'none';
        this.targetObject.selectedId = 'none';
        this.targetObject.gFlg = 0;
        this.targetObject.selectedUser = {};
        userLists = await DashboardService.getProjectMemberList({
          projectId: 'main',
          workspaceId,
        });
        // 選択した条件でtargetObjectUserに情報を入れる
        this.targetObjectUser.selectedGroup = 'projectId';
        this.targetObjectUser.selectedId = 'none';
        this.targetObjectUser.gFlg = 0;
        this.targetObjectUser.selectedUser = {};
        // 平均に使う数字を入れる
        this.divisionNumScagent = this.projectList.length - 1;
        this.divisionNumVoicex = this.projectList.length - 1;
        this.divisionType = 'group';
      }
      // 選択されている電話番号は空にする
      // this.targetObject.selectedUser = {};
      this.selectedPhoneUser = {};
      this.selectedDidNo = '';
      // 現在のtargetObjectをコピーしておく。
      this.targetObjectSub = JSON.parse(JSON.stringify(this.targetObject));
      this.userLists = this.filterUserList(userLists);
      this.getExtList();
      this.selectedUser = {};
      this.selectedUserId = '';
      this.menu2 = false;
    },
    setPhoneData() {
      // if (this.typeOfPhoneSub === 'ext') {
      if (this.telephoneLineTab === 0) {
        this.typeOfPhone = this.typeOfPhoneSub;
        if (Object.keys(this.selectedPhoneUserSub).length) {
          // targetObjectにselectedUserを設定する
          this.targetObject.selectedUser = JSON.parse(JSON.stringify(this.selectedPhoneUserSub));
          // targetObjectUserに同じUserを設定する
          this.targetObjectUser.selectedUser = JSON.parse(
            JSON.stringify(this.selectedPhoneUserSub)
          );
          // returnFilterSelect用のデータを設定
          this.selectedPhoneUser = JSON.parse(JSON.stringify(this.selectedPhoneUserSub));
          // 表示ユーザーにも同じユーザーを設定する
          const setForUser = JSON.parse(JSON.stringify(this.selectedPhoneUserSub));
          this.selectedUser = setForUser;
          this.selectedUserIdSub = setForUser.userId;
          this.selectedUserId = setForUser.userId;
          // divisionNumも設定
          this.divisionType = 'ext';
          this.divisionNumScagent = 1;
          this.divisionNumVoicex = 1;
        } else {
          this.targetObject.selectedUser = {};
          this.selectedPhoneUser = {};
          // 表示ユーザーに設定されているユーザーも消しておく。
          this.selectedUser = {};
          this.selectedUserIdSub = '';
          this.selectedUserId = '';
          // divisionNumの設定
          // 全体の時
          if (this.targetObject.selectedId === 'none') {
            this.divisionType = 'group';
            // ここは後で修正する
            this.divisionNumScagent = this.projectList.length;
            // プロジェクトの時
          } else if (this.targetObject.selectedGroup === 'projectId') {
            this.divisionType = 'group';
            this.divisionNumScagent = this.projectList.length;
            // 部署の時
          } else if (this.targetObject.selectedGroup === 'departmentId') {
            this.divisionType = 'group';
            this.divisionNumScagent = this.departmentList.length;
          }
        }
        // Did情報消しておく
        this.selectedDidNoSub = '';
        this.selectedDidNo = '';
        this.targetObject.selectedDid = '';
        // targetObjectSubに現時点でのtargetObjectを保存しておく
        this.targetObjectSub = JSON.parse(JSON.stringify(this.targetObject));
        // } else if (this.typeOfPhoneSub === 'did') {
      } else if (this.telephoneLineTab === 1) {
        this.typeOfPhone = this.typeOfPhoneSub;
        if (this.selectedDidNoSub) {
          this.selectedDidNo = this.selectedDidNoSub;
          this.targetObject.selectedDid = this.selectedDidNoSub;
        } else {
          this.targetObject.selectedDid = '';
        }
        // 外線とユーザーは紐づかないので、ユーザー情報は消しておく（外線に紐づく内線を取得することならできそうだが、、）
        this.selectedUser = {};
        this.selectedUserIdSub = '';
        this.selectedUserId = '';
        // selectedPhoneUser情報消しておく
        this.selectedPhoneUser = {};
        this.selectedPhoneUserSub = {};
        this.targetObject.selectedUser = {};
        // targetObjectSubに現時点でのtargetObjectを保存しておく
        this.targetObjectSub = JSON.parse(JSON.stringify(this.targetObject));
      }
      this.menu3 = false;
    },
    setUser() {
      if (this.selectedUserSub) {
        // 表示ユーザー用の情報を設定
        this.selectedUser = this.selectedUserSub;
        this.selectedUserId = this.selectedUserIdSub;
        // もともとはこっち
        // this.targetObject.selectedGroup = 'userId';
        // this.targetObject.selectedId = this.selectedId;
        // 変更後
        this.targetObjectUser.selectedUser = JSON.parse(JSON.stringify(this.selectedUserSub));
        // // divisionNumは１に
        this.divisionNumScagent = 1;
        this.divisionNumVoicex = 1;
        this.typeOfPhone = 'ext';
        // 表示番号の情報を設定
        if (this.typeOfPhone === 'ext') {
          const findItem = this.extList.find((el) => {
            return el.userId === this.selectedUserId;
          });
          if (findItem) {
            // targetObjectにselectedUserを設定する
            this.targetObject.selectedUser = JSON.parse(JSON.stringify(findItem));
            // returnFilterSelect用のデータを設定
            this.selectedPhoneUser = JSON.parse(JSON.stringify(findItem));
          } else {
            // targetObjectにselectedUserを設定する
            this.targetObject.selectedUser = {};
            // returnFilterSelect用のデータを設定
            this.selectedPhoneUser = {};
          }
        }
      } else {
        this.selectedUser = {};
        this.selectedUserId = '';
        this.targetObject = JSON.parse(JSON.stringify(this.targetObjectSub));
      }
      this.menu4 = false;
    },
    setUserItem(userLists: User[]) {
      if (userLists.length > 0) {
        for (let i = 0; i < userLists.length; i += 1) {
          if (userLists[i].userId && userLists[i].userId === this.selectedUserIdSub) {
            this.selectedUserItem = i;
            return;
          }
          this.selectedUserItem = '';
        }
      } else {
        this.selectedUserItem = '';
      }
    },
    setYearList() {
      const year = dayjs().year();
      const minitues = [...Array(60).keys()].map((i) => i - 20);
      this.yearList = minitues.map((i) => (year + i).toString());
      // this.selectedYearItem = '20';
    },
    updateDashboardInfo(value: boolean) {
      this.updateButtonFlg = true;
      if (this.tabType === 0) {
        this.usagePhoneUpdateFlg = value;
      } else if (this.tabType === 1) {
        this.usagePCUpdateFlg = value;
      } else {
        this.totalUpdateFlg = value;
      }
    },
  },

  validations() {
    return {};
  },
});
