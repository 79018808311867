







import * as d3 from 'd3';
import svgz from 'svg-z-order';
import Vue, { PropType } from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'MainAppStackBarGraph',
  props: {
    mainAppArray: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    mainAppData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    mainAppDataMax: {
      type: Number,
      default: 0,
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
  },
  data(): {
    svg: any;
    svgLegend: any;
    svgG: any;
    svgGG: any;
    svgPath: any;
    xAxis: any;
    xAxisEl: any;
    xScale: any;
    yAxis: any;
    yAxisEl: any;
    yScale: any;
    color: any;
    margin: any;
    height: number;
    width: number;
    mainAppChartArray: any[];
    mainAppChartArrayFlg: boolean;
    mainAppChartData: any[];
    mainAppChartDataFlg: boolean;
    // mainAppChartDataMax: number;
    // mainAppChartDataMaxFlg: boolean;
    barMaxHeight: number;
    barHeightNum: number;
    xLabel: string;
    colorGrey1: string;
  } {
    return {
      barHeightNum: 0,
      barMaxHeight: 0,
      color: null,
      colorGrey1: '#AAAAAA',
      height: 0,
      mainAppChartArray: [],
      mainAppChartArrayFlg: false,
      mainAppChartData: [],
      mainAppChartDataFlg: false,
      // mainAppChartDataMax: 0,
      // mainAppChartDataMaxFlg: false,
      margin: {},
      svg: null,
      svgG: null,
      svgGG: null,
      svgLegend: null,
      svgPath: null,
      width: 0,
      xAxis: null,
      xAxisEl: null,
      xLabel: '',
      xScale: null,
      yAxis: null,
      yAxisEl: null,
      yScale: null,
    };
  },
  watch: {
    mainAppArray(newValue) {
      if (newValue) {
        this.mainAppChartArray = newValue;
        this.mainAppChartArrayFlg = true;
        this.drawChart2();
      }
    },
    mainAppData(newValue) {
      if (newValue) {
        this.mainAppChartData = newValue;
        this.mainAppChartDataFlg = true;
        this.drawChart2();
      }
    },
    mainAppDataMax(newValue) {
      if (newValue) {
        // this.mainAppChartDataMax = newValue;
        // this.mainAppChartDataMaxFlg = true;
        // this.drawChart2();
      }
    },
  },
  mounted() {
    // this.drawChart2();
    const self = this;
    if (self.selectedTarget.selectedGroup === 'userId') {
      self.height = 372;
    } else {
      self.height = 300;
    }
    self.svg = d3
      .select('.hhh')
      .append('svg')
      .attr('viewBox', `0 0 ${850} ${self.height + 30}`)
      .attr('id', 'main-app-stack-bar-chart');
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    drawBar(x: number, y: number, w: number, h: number, r: number, f: any) {
      // console.log('x', x);
      // console.log('y', y);
      // console.log('w', w);
      // console.log('h', h);
      // console.log('r', r);
      // Flag for sweep:
      let f1: number;
      if (f === undefined) {
        f1 = 1;
      } else {
        f1 = f;
      }
      // x coordinates of top of arcs
      const x0 = x + r;
      const x1 = x + w - r;
      const y0 = y - h + r;
      const l = 'L';
      const a = 'A';
      let parts: any[];
      if (h > 0) {
        parts = [
          'M',
          x,
          y,
          l,
          x,
          y0,
          a,
          r,
          r,
          0,
          0,
          f1,
          x0,
          y - h,
          l,
          x1,
          y - h,
          a,
          r,
          r,
          0,
          0,
          f1,
          x + w,
          y0,
          l,
          x + w,
          y,
          'Z',
        ];
      } else {
        const newx0 = x + w;
        const newy0 = y - h;
        parts = ['M', x, y, l, x, newy0, l, newx0, y - h, l, x + w, y, 'Z'];
      }
      return parts.join(' ');
    },
    drawChart2() {
      const self = this;
      // if (self.svg) {
      //   d3.select('#main-app-stack-bar-chart').remove();
      //   d3.select('#main-stack-bar-legend').remove();
      // }
      // if (self.svg) {
      //   d3.select('#main-app-stack-bar-chart').remove();
      //   d3.select('#main-stack-bar-legend').remove();
      // }
      // if (self.selectedTarget.selectedGroup === 'userId') {
      //   self.height = 372;
      // } else {
      //   self.height = 300;
      // }
      // self.svg = d3
      //   .select('.hhh')
      //   .append('svg')
      //   .attr('viewBox', `0 0 ${850} ${self.height + 30}`)
      //   .attr('id', 'main-app-stack-bar-chart');
      // if (self.mainAppChartData.length > 0 && self.mainAppChartArray.length > 0) {
      if (self.mainAppChartData.length > 0) {
        if (self.svg) {
          d3.select('#main-app-stack-bar-chart').remove();
          d3.select('#main-stack-bar-legend').remove();
        }
        if (self.selectedTarget.selectedGroup === 'userId') {
          self.height = 372;
        } else {
          self.height = 300;
        }
        self.svg = d3
          .select('.hhh')
          .append('svg')
          .attr('viewBox', `0 0 ${850} ${self.height + 30}`)
          .attr('id', 'main-app-stack-bar-chart');
        let data = [];
        data = JSON.parse(JSON.stringify(self.mainAppChartData));
        self.margin = { bottom: 20, left: 50, right: 30, top: 10 };
        // if (self.selectedTarget.selectedGroup === 'userId') {
        //   self.height = 372;
        // } else {
        //   self.height = 300;
        // }
        self.width = 853;
        self.color = d3
          .scaleOrdinal()
          .range(['#F8BCE6', '#B5D6FF', '#CCEDFF', '#E1DDFA', '#F1F1F2']);
        // self.svg = d3
        //   .select('.hhh')
        //   .append('svg')
        //   .attr('viewBox', `0 0 ${850} ${self.height + 30}`)
        //   .attr('id', 'main-app-stack-bar-chart');
        self.svgLegend = d3
          .select('#main-app-stacked-bar-chart-legend')
          .append('svg')
          .attr('id', 'main-stack-bar-legend')
          .style('padding-left', 15)
          .style('padding-top', 10);
        // legend設置
        const legend = self.svgLegend
          .selectAll('legend')
          .data(self.mainAppChartArray)
          .enter()
          .append('g');
        legend
          .append('rect')
          .attr('width', 20)
          .attr('height', 14)
          .attr('y', 1)
          .style('fill', function fn(d: any, i: number) {
            if (d.key === 'その他') {
              return '#DCDCDC';
            }
            const number: string = i.toString();
            return self.color(number);
          });
        const legendText = legend
          .append('text')
          .attr('x', 23)
          .attr('y', 14)
          // .attr('dy', '.15em')
          .attr('font-size', '15px')
          .text(function fn(d: any) {
            return d.key;
          });
        // width array
        const widthArray: any = [];
        for (let i = 0; i < self.mainAppChartArray.length; i += 1) {
          widthArray.push(legend.nodes()[i].getBBox().width);
        }
        const parentWidth = 853 - 20;
        let legendWidth = 0;
        let legendy = 0;
        let legentHeight = 1;
        legend.attr('transform', function fn(d: any, i: number) {
          if (i === 0) {
            return 'translate(0, 0)';
          }
          // legendWidth += widthArray[i - 1];
          legendWidth += widthArray[i - 1] + 10;
          if (parentWidth < legendWidth || parentWidth < legendWidth + widthArray[i]) {
            legendy += 20;
            legendWidth = 0;
            legentHeight += 1;
          }
          return `translate(${legendWidth},${legendy})`;
        });
        this.svgLegend
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('viewBox', `0 0 ${self.width} ${legentHeight * 20}`);
        // svgG
        this.svgG = this.svg.append('g').attr('transform', `translate(${0},${0})`);
        let x0Domain: string[] = [];
        if (data) {
          x0Domain = data.map(function fn(d: any) {
            return d.aggregate[0].indexNum.toString();
          });
        }
        // Y軸の時間算出
        // let mainAppChartDataMax = self.mainAppChartDataMax * 30;
        let mainAppChartDataMax = self.mainAppDataMax * 30;
        let y1Label = '';
        if (mainAppChartDataMax < 60) {
          y1Label = '(秒)';
        } else if (mainAppChartDataMax >= 60 && mainAppChartDataMax < 3600) {
          y1Label = '(分)';
          const callRem = Math.floor(mainAppChartDataMax % 60) / 60;
          mainAppChartDataMax = Math.floor((mainAppChartDataMax % 3600) / 60) + callRem;
        } else if (mainAppChartDataMax >= 3600) {
          y1Label = '(時間)';
          const callRem = Math.floor(mainAppChartDataMax % 60) / 60 / 360;
          const callMin = Math.floor((mainAppChartDataMax % 3600) / 60) / 60;
          mainAppChartDataMax = Math.floor(mainAppChartDataMax / 3600) + callMin + callRem;
        }
        // Max時間の小数点繰り上げ
        mainAppChartDataMax = Math.ceil(mainAppChartDataMax);
        if (mainAppChartDataMax === 0) {
          mainAppChartDataMax = 1;
        }
        // グラフの左側の軸の幅を取得
        let mainAppChartDataMaxLength =
          mainAppChartDataMax.toString().length === 1 ? 2 : mainAppChartDataMax.toString().length;
        mainAppChartDataMaxLength = mainAppChartDataMaxLength * 8 + 20;
        self.xScale = d3
          .scaleBand()
          // X軸の左側、と長さを設定
          .range([mainAppChartDataMaxLength, self.width - 40])
          .domain(x0Domain);
        self.yScale = d3
          .scaleLinear()
          .range([self.height - 10, 15])
          .domain([0, mainAppChartDataMax]);
        self.xAxis = d3.axisBottom(self.xScale);
        self.yAxis = d3
          .axisLeft(self.yScale)
          .ticks(5)
          .tickSize(-self.width + mainAppChartDataMaxLength + 15);
        self.yAxisEl = self.svgG.append('g').attr('class', 'y1 axis').call(self.yAxis);
        self.yAxisEl.attr('transform', `translate(${mainAppChartDataMaxLength}, ${10})`);
        self.yAxisEl
          .append('text')
          .attr('x', -25)
          .attr('y', 5)
          .attr('fill', self.colorGrey1)
          .attr('text-anchor', 'start')
          .attr('font-size', '12px')
          .attr('font-family', 'Noto Sans JP')
          .text(y1Label);
        // y1 axis 枠線消す
        d3.select('.y1.axis').select('.domain').remove();
        // 横線少しずらす
        d3.select('.y1.axis').selectAll('.tick').select('line').attr('transform', 'translate(5,0)');
        // y1 axis color change
        d3.select('.y1.axis').selectAll('.tick').select('line').style('stroke', self.colorGrey1);
        self.xAxisEl = self.svgG
          .append('g')
          .attr('class', 'x axis')
          .attr('transform', `translate(5,${self.height})`);
        self.svgGG = self.svgG.selectAll(null).data(data).enter().append('g');
        self.svgPath = self.svgGG
          .selectAll('path')
          .data(function fn(d: any) {
            return d.aggregate;
          })
          .enter()
          .append('path')
          .attr('class', function fn(d: any, i: number) {
            return `stacked-bar-${d.indexNum}-${i}`;
          })
          .attr('d', function fn(d: any, i: number) {
            self.barMaxHeight = self.yScale(0);
            // 追加分
            let mainAppChartData = d.appliCount * 30;
            if (y1Label === '(分)') {
              const callRem = Math.floor(mainAppChartData % 60) / 60;
              mainAppChartData = Math.floor((mainAppChartData % 3600) / 60) + callRem;
            } else if (y1Label === '(時間)') {
              const callRem = Math.floor(mainAppChartData % 60) / 60 / 360;
              const callMin = Math.floor((mainAppChartData % 3600) / 60) / 60;
              mainAppChartData = Math.floor(mainAppChartData / 3600) + callMin + callRem;
            }
            if (i === 0) {
              self.barHeightNum = self.barMaxHeight - self.yScale(mainAppChartData);
            } else {
              self.barHeightNum += self.barMaxHeight - self.yScale(mainAppChartData);
            }
            return self.drawBar(
              self.xScale(d.indexNum.toString()) + self.xScale.bandwidth() / 4 + 5,
              // self.barMaxHeight,
              self.barMaxHeight + 10,
              self.xScale.bandwidth() / 2,
              self.barHeightNum,
              5,
              1
            );
          })
          .attr('fill', function fn(d: any, i: number) {
            if (d.appliName === 'その他') {
              return '#DCDCDC';
            }
            const index = self.mainAppChartArray.findIndex(function fn1(element) {
              return element.key === d.appliName;
            });
            if (index === 0) {
              return '#F8BCE6';
            }
            if (index === 1) {
              return '#B5D6FF';
            }
            if (index === 2) {
              return '#CCEDFF';
            }
            if (index === 3) {
              return '#E1DDFA';
            }
            if (index === 4) {
              return '#F1F1F2';
            }
            if (index === -1) {
              return '#F1F1F2';
            }
            return '#F1F1F2';
          });
        for (let i = 0; i < x0Domain.length; i += 1) {
          for (let j = 1; j < 10; j += 1) {
            if (document.querySelector(`.stacked-bar-${x0Domain[i]}-${j}`)) {
              const hoge = d3.select(`.stacked-bar-${x0Domain[i]}-${j}`);
              svgz.element(hoge.node()).toBottom();
            }
          }
        }
        this.updateChart();
      }
    },
    updateChart() {
      this.setNavigating({ navigating: false });
      const self = this;
      self.xAxisEl.call(self.xAxis);
      // x axis 枠線消す
      d3.select('.x.axis').select('.domain').remove();
      d3.select('.x.axis').selectAll('.tick').select('line').remove();
      if (self.selectedPeriod === 'day') {
        self.xLabel = '(時)';
      } else if (self.selectedPeriod === 'week' || self.selectedPeriod === 'month') {
        self.xLabel = '(日)';
      } else if (self.selectedPeriod === 'year') {
        self.xLabel = '(月)';
      }
      let yHeight = 0;
      if (self.selectedTarget.selectedGroup === 'userId') {
        yHeight = 390;
      } else {
        yHeight = 318;
      }
      const xLabelText = this.svgG
        .append('text')
        .attr('x', 825)
        .attr('y', yHeight)
        .attr('fill', self.colorGrey1)
        .attr('text-anchor', 'start')
        .attr('font-size', '12px')
        .attr('font-family', 'Noto Sans JP')
        .text(self.xLabel);
      // self.mainAppChartDataFlg = false;
      // self.mainAppChartArrayFlg = false;
      // self.mainAppChartDataMaxFlg = false;
    },
  },
});
