
















import Vue from 'vue';

export default Vue.extend({
  name: 'DashboardSelectPageTab',
  data() {
    return {};
  },
  computed: {},
  methods: {
    changeTabItem(value: number) {
      this.$emit('select-tab-type', value);
    },
  },
});
