



















import * as d3 from 'd3';
import dayjs from 'dayjs';
import Vue, { PropType } from 'vue';
import { UICommonMapper } from '@/store/modules/ui/common';

export default Vue.extend({
  name: 'TotalBubbleAndLineGraph',
  props: {
    divisionNumSc: {
      type: Number,
      default: 0,
    },
    divisionNumVoicex: {
      type: Number,
      default: 0,
    },
    finishFlg: {
      type: Boolean,
    },
    scagentData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    scagentDataMax: {
      type: Number,
      default: 0,
    },
    selectedDate: {
      type: String,
      default: '',
    },
    selectedPeriod: {
      type: String,
      default: '',
    },
    // selectedTarget: {
    //   type: Object,
    //   default: () => ({
    //     gFlg: 0,
    //   }),
    // },
    selectedTargetUser: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    voicexData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    voicexDataMax: {
      type: Number,
      default: 0,
    },
  },
  data(): {
    colorBlue: string;
    colorGrey1: string;
    colorGrey2: string;
    margin: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
    svg: any;
    svgG: any;
    svgGG: any;
    xAxis: any;
    // y: any;
    yAxis: any;
    regressionLinePlot: any[];
    line: any;
    svgPath: any;
    width: number;
    xScale: any;
    yScale: any;
    xAxisEl: any;
    SCAgentData: any;
    SCAgentDataMax: any;
    VoiceXData: any;
    VoiceXDataMax: any;
    scAgentDataFlg: boolean;
    scAgentDataMaxFlg: boolean;
    voiceXDataFlg: boolean;
    // voiceXDataMaxFlg: boolean;
    d3YMaxLength: number;
    d3XMaxLength: number;
    hoverTimeScagent: number;
    hoverTimeVoicex: number;
    hoverDate: number;
    y1Label: string;
    xLabel: string;
    labelNotes: string;
    height: number;
  } {
    return {
      SCAgentData: [],
      SCAgentDataMax: null,
      VoiceXData: [],
      VoiceXDataMax: null,
      colorBlue: '#8EC7F2',
      colorGrey1: '#CCCCCC',
      colorGrey2: '#AAAAAA',
      d3XMaxLength: 0,
      d3YMaxLength: 0,
      height: 413,
      hoverDate: 0,
      hoverTimeScagent: 0,
      hoverTimeVoicex: 0,
      labelNotes: '',
      line: null,
      margin: { bottom: 30, left: 50, right: 20, top: 10 },
      regressionLinePlot: [],
      scAgentDataFlg: false,
      scAgentDataMaxFlg: false,
      svg: null,
      svgG: null,
      svgGG: null,
      svgPath: null,
      // voiceXDataMaxFlg: false,
      voiceXDataFlg: false,
      width: 853,
      xAxis: null,
      xAxisEl: null,
      xLabel: '',
      xScale: null,
      // y: null,
      y1Label: '',
      yAxis: null,
      yScale: null,
    };
  },
  computed: {
    returnAppliDay() {
      const self = this;
      const day = dayjs(self.selectedDate);
      return function fn(time: number) {
        if (self.selectedPeriod === 'day') {
          return `${time}時`;
        }
        if (self.selectedPeriod === 'week') {
          return `${day.year()}年${day.month() + 1}月${time}日`;
        }
        if (self.selectedPeriod === 'month') {
          return `${day.year()}年${day.month() + 1}月${time}日`;
        }
        if (self.selectedPeriod === 'year') {
          return `${day.year()}年${time}月`;
        }
        return '';
      };
    },
    returnAppliTimeScagent() {
      const self = this;
      return function fn(time: number) {
        if (self.xLabel === '(秒)PC利用状況') {
          const minitue = Math.floor(time);
          return `${minitue}秒`;
        }
        if (self.xLabel === '(分)PC利用状況') {
          const minitue = Math.floor(time);
          if (String(time).split('.').length === 2) {
            let second: any = String(time).split('.')[1];
            second = `0.${second}`;
            second = Math.ceil(Number(second) * 60);
            return `${minitue}分${second}秒`;
          }
          return `${minitue}分`;
        }
        if (self.xLabel === '(時間)PC利用状況') {
          const hour = Math.floor(time);
          if (String(time).split('.').length === 2) {
            let minitue: any = String(time).split('.')[1];
            minitue = `0.${minitue}`;
            minitue = Math.ceil(Number(minitue) * 60);
            return `${hour}時間${minitue}分`;
          }
          return `${hour}時間`;
        }
        return '';
      };
    },
    returnAppliTimeVoicex() {
      const self = this;
      return function fn(time: number) {
        if (self.y1Label === '(秒)電話利用状況') {
          if (time > 0 && time < 1) {
            return `${Math.round(time * 10) / 10}秒`;
          }
          const minitue = Math.floor(time);
          return `${minitue}秒`;
        }
        if (self.y1Label === '(分)電話利用状況') {
          const minitue = Math.floor(time);
          if (String(time).split('.').length === 2) {
            let second: any = String(time).split('.')[1];
            second = `0.${second}`;
            second = Math.ceil(Number(second) * 60);
            return `${minitue}分${second}秒`;
          }
          return `${minitue}分`;
        }
        if (self.y1Label === '(時間)電話利用状況') {
          const hour = Math.floor(time);
          if (String(time).split('.').length === 2) {
            let minitue: any = String(time).split('.')[1];
            minitue = `0.${minitue}`;
            minitue = Math.ceil(Number(minitue) * 60);
            minitue = Math.floor(Number(String(time).split('.')[1]) * 60);
            return `${hour}時間${minitue}分`;
          }
          return `${hour}時間`;
        }
        return '';
      };
    },
  },
  watch: {
    finishFlg(newValue) {
      if (newValue) {
        this.drawChart();
      }
    },
    scagentData(newValue) {
      if (newValue) {
        this.SCAgentData = newValue;
        // this.scAgentDataFlg = true;
        // this.drawChart();
      }
    },
    scagentDataMax(newValue) {
      if (newValue || newValue === 0) {
        this.SCAgentDataMax = newValue;
        // this.scAgentDataMaxFlg = true;
        // this.drawChart();
      }
    },
    voicexData(newValue) {
      if (newValue) {
        this.VoiceXData = newValue;
        // this.voiceXDataFlg = true;
        // this.drawChart();
      }
    },
    voicexDataMax(newValue) {
      if (newValue) {
        this.VoiceXDataMax = newValue;
        // this.voiceXDataMaxFlg = true;
        // this.drawChart();
      }
    },
  },
  mounted() {
    // this.drawChart();
    this.svg = d3
      .select('.kkk')
      .append('svg')
      .attr('viewBox', `0 0 ${this.width} ${this.height}`)
      .attr('id', 'total-bubble-and-line');
  },
  methods: {
    ...UICommonMapper.mapActions(['setNavigating', 'setMessage', 'setErrorMessage']),
    addGraph(width: number) {
      // const margin = { bottom: 30, left: 50, right: 20, top: 10 };
      const self = this;
      // this.svg;
      // .attr("width", width - margin.left - margin.right);
      // .attr('width', width + margin.right + margin.left);
      // Set new range for
      // this.xScale.range([0, width - margin.right]);
      this.xScale.range([self.d3YMaxLength, width - self.d3YMaxLength - self.d3XMaxLength - 20]);
      // give the x axis the resized scale
      this.xAxis.scale(this.xScale);
      // draw the new xAxis
      this.xAxisEl.call(this.xAxis);
      // x axis 枠線消す
      d3.select('.x.axis.bubble').selectAll('g').select('line').remove();
      d3.select('.x.axis.bubble').select('.domain').remove();
      // specify new properties for the line
      this.svgGG.attr('cx', function fn(d: any) {
        return self.xScale(d.scagent) + self.d3YMaxLength;
      });
      this.line
        .x(function a(d: any) {
          return self.xScale(d.x) + self.d3YMaxLength;
        })
        .y(function a(d: any) {
          return self.yScale(d.y);
        });
      this.svgPath.attr('d', self.line(self.regressionLinePlot));
      self.scAgentDataFlg = false;
      self.scAgentDataMaxFlg = false;
      self.voiceXDataFlg = false;
      // self.voiceXDataMaxFlg = false;
      self.regressionLinePlot = [];
    },
    drawChart() {
      const self = this;
      // if (self.scAgentDataFlg && self.scAgentDataMaxFlg && self.voiceXDataFlg) {
      if (self.finishFlg) {
        // 既にsvgデータがあれば削除しておく
        if (self.svg) {
          d3.select('#total-legend').remove();
          d3.select('#bubble-legend').remove();
          d3.select('#bubble-legend-2').remove();
          d3.select('#total-bubble-and-line').remove();
        }
        if (!Object.keys(self.selectedTargetUser.selectedUser).length) {
          // if (self.selectedTarget.selectedGroup !== 'userId') {
          // bubbleDataArrayの中身は{ voicex: 0, scagent: 0 }
          const bubbleDataArray = [];
          for (let i = 0; i < self.SCAgentData.length; i += 1) {
            bubbleDataArray.push({
              indexNum: self.VoiceXData[i].indexNum,
              scagent: 0,
              voicex: 0,
            });
            // let maxNum = 0;
            for (let j = 0; j < self.SCAgentData[i].mainAndOther.length; j += 1) {
              bubbleDataArray[i].scagent += self.SCAgentData[i].mainAndOther[j].value;
            }
            bubbleDataArray[i].voicex += self.VoiceXData[i].aggregate.time;
          }
          const sortBubbleDataArray: any[] = bubbleDataArray.sort(function (a, b) {
            return a.scagent < b.scagent ? -1 : 1;
          });
          self.margin = { bottom: 30, left: 50, right: 20, top: 10 };
          // self.width = 853 - self.margin.left - self.margin.right;
          self.width = 853;
          // const height = 425;
          const height = 413;
          this.xScale = d3.scaleLinear();
          this.yScale = d3.scaleLinear().range([height - 55, 20]);
          // create a line
          this.line = d3.line();
          // Add the svg canvas
          this.svg = d3
            .select('.kkk')
            .append('svg')
            // .attr('height', height + margin.top + margin.bottom)
            // .attr('id', 'total-legend');
            .attr('viewBox', `0 0 ${self.width} ${height}`)
            .attr('id', 'total-bubble-and-line');
          this.svgG = this.svg
            .append('g')
            // .attr('transform', `translate(${margin.left},${margin.top})`);
            // 追加
            .attr('transform', `translate(${0},${0})`);
          // 追加
          let d3YMax = self.voicexDataMax / 1000 / this.divisionNumVoicex;
          self.y1Label = '';
          self.xLabel = '';
          if (d3YMax < 60) {
            self.y1Label = '(秒)電話利用状況';
          } else if (d3YMax >= 60 && d3YMax < 3600) {
            self.y1Label = '(分)電話利用状況';
            const callRem = Math.floor(d3YMax % 60) / 60;
            d3YMax = Math.floor((d3YMax % 3600) / 60) + callRem;
          } else if (d3YMax >= 3600) {
            self.y1Label = '(時間)電話利用状況';
            const callRem = Math.floor(d3YMax % 60) / 60 / 360;
            const callMin = Math.floor((d3YMax % 3600) / 60) / 60;
            d3YMax = Math.floor(d3YMax / 3600) + callMin + callRem;
          }
          // ここでsortBubbleArrayのvoicexの数字を加工してあげる
          for (let j = 0; j < sortBubbleDataArray.length; j += 1) {
            const voicexNum = sortBubbleDataArray[j].voicex / 1000 / this.divisionNumVoicex;
            if (self.y1Label === '(秒)電話利用状況') {
              sortBubbleDataArray[j].voicex = voicexNum;
            } else if (self.y1Label === '(分)電話利用状況') {
              const callRem = Math.floor(voicexNum % 60) / 60;
              sortBubbleDataArray[j].voicex = Math.floor((voicexNum % 3600) / 60) + callRem;
            } else if (self.y1Label === '(時間)電話利用状況') {
              const callRem = Math.floor(voicexNum % 60) / 60 / 360;
              const callMin = Math.floor((voicexNum % 3600) / 60) / 60;
              sortBubbleDataArray[j].voicex = Math.floor(voicexNum / 3600) + callMin + callRem;
            }
          }
          // 追加分
          // Max時間の小数点繰り上げ
          // if (d3YMax > 1) {
          d3YMax = Math.ceil(d3YMax);
          // }
          if (d3YMax === 0) {
            d3YMax = 1;
          }
          // グラフの左側の軸の幅を取得
          self.d3YMaxLength = d3YMax.toString().length === 1 ? 2 : d3YMax.toString().length;
          self.d3YMaxLength = self.d3YMaxLength * 8 + 20;
          //
          let d3XMax = (self.scagentDataMax * 30) / this.divisionNumSc;
          // let d3XMax = self.scagentDataMax * 30;
          if (d3XMax < 60) {
            self.xLabel = '(秒)PC利用状況';
          } else if (d3XMax >= 60 && d3XMax < 3600) {
            self.xLabel = '(分)PC利用状況';
            const callRem = Math.floor(d3XMax % 60) / 60;
            d3XMax = Math.floor((d3XMax % 3600) / 60) + callRem;
          } else if (d3XMax >= 3600) {
            self.xLabel = '(時間)PC利用状況';
            const callRem = Math.floor(d3XMax % 60) / 60 / 360;
            const callMin = Math.floor((d3XMax % 3600) / 60) / 60;
            d3XMax = Math.floor(d3XMax / 3600) + callMin + callRem;
          }
          // ここでsortBubbleArrayのvoicexの数字を加工してあげる
          for (let j = 0; j < sortBubbleDataArray.length; j += 1) {
            const scagentNum = (sortBubbleDataArray[j].scagent * 30) / this.divisionNumSc;
            // const scagentNum = sortBubbleDataArray[j].scagent * 30;
            if (self.xLabel === '(秒)PC利用状況') {
              sortBubbleDataArray[j].scagent = scagentNum;
            } else if (self.xLabel === '(分)PC利用状況') {
              const callRem = Math.floor(scagentNum % 60) / 60;
              sortBubbleDataArray[j].scagent = Math.floor((scagentNum % 3600) / 60) + callRem;
            } else if (self.xLabel === '(時間)PC利用状況') {
              const callRem = Math.floor(scagentNum % 60) / 60 / 360;
              const callMin = Math.floor((scagentNum % 3600) / 60) / 60;
              sortBubbleDataArray[j].scagent = Math.floor(scagentNum / 3600) + callMin + callRem;
            }
          }
          // 追加分
          // Max時間の小数点繰り上げ
          if (d3XMax > 1) {
            d3XMax = Math.ceil(d3XMax);
          }
          // グラフの左側の軸の幅を取得
          self.d3XMaxLength = d3XMax.toString().length;
          self.d3XMaxLength = self.d3XMaxLength * 8 + 15;
          // Define the axes
          this.xAxis = d3.axisBottom(self.xScale);
          this.yAxis = d3
            .axisLeft(self.yScale)
            .ticks(5)
            .tickSize(-self.width + self.d3YMaxLength + 20);
          this.xScale.domain([0, d3XMax]);
          this.yScale.domain([0, d3YMax]);
          // path追加
          this.svgPath = this.svgG
            .append('path')
            .data(sortBubbleDataArray)
            .attr('fill', 'none')
            .attr('stroke', self.colorBlue)
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3);
          // Add the X Axis
          this.xAxisEl = this.svgG
            .append('g')
            .attr('class', 'x axis bubble')
            // .attr('transform', `translate(0,${height - 10})`);
            .attr('transform', `translate(${self.d3YMaxLength},${height - 45})`);
          d3.select('.x.axis.bubble').selectAll('g').select('line').remove();
          // hoverlinelabel
          // create a line
          const hoverLine = d3.line();
          hoverLine
            .x(function (d: any) {
              return d[0];
            })
            .y(function (d: any) {
              return d[1];
            });
          const hoverLinePath1 = this.svgG
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', 'currentColor')
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3)
            .style('stroke-dasharray', '3, 3');
          const hoverLinePath2 = this.svgG
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', 'currentColor')
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3)
            .style('stroke-dasharray', '3, 3');
          // Add the Y Axis
          const yAxisEl = this.svgG.append('g').attr('class', 'y1 axis bubble').call(self.yAxis);
          // 追加
          yAxisEl.attr('transform', `translate(${self.d3YMaxLength}, 10)`);
          // y1 axis 枠線消す
          d3.select('.y1.axis.bubble').select('.domain').remove();
          //
          // y1 axis color change
          d3.select('.y1.axis.bubble')
            .selectAll('.tick')
            .select('line')
            .style('stroke', self.colorGrey1);
          yAxisEl
            .append('text')
            .attr('x', -23)
            .attr('y', 5)
            .attr('fill', self.colorGrey1)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // append text xlabel
          const xLabelText = this.svgG
            .append('text')
            .attr('x', 840)
            .attr('y', 400)
            .attr('fill', self.colorGrey2)
            .attr('text-anchor', 'end')
            .attr('font-size', '12px')
            .text(self.xLabel);
          this.svgGG = this.svgG
            .append('g')
            .selectAll('dot')
            .data(sortBubbleDataArray)
            .enter()
            .append('circle')
            .attr('cy', function (d: any) {
              // return self.yScale(d.voicex);
              return self.yScale(d.voicex) + 10;
            })
            .attr('r', function (d: any) {
              return 10;
            })
            .style('fill', '#FF72B8')
            .attr('stroke', 'black')
            .on('mouseover', function (this: any, event: any, data: any) {
              self.hoverDate = data.indexNum;
              self.hoverTimeVoicex = data.voicex;
              self.hoverTimeScagent = data.scagent;
              let positionLeft = 0;
              if (data.scagent > d3XMax * 0.7) {
                positionLeft = event.offsetX - 80;
              } else {
                positionLeft = event.offsetX - 50;
              }
              d3.select('#tooltip-bubble')
                .transition()
                .duration(0)
                // .style('left', `${event.offsetX - 50}px`)
                .style('left', `${positionLeft}px`)
                .style('top', `${event.offsetY - 20}px`)
                .style('opacity', 1);
              // 縦ライン
              hoverLinePath1.attr(
                'd',
                hoverLine([
                  // [self.xScale(data.scagent) + self.d3YMaxLength, 405],
                  [self.xScale(data.scagent) + self.d3YMaxLength, 370],
                  [self.xScale(data.scagent) + self.d3YMaxLength, self.yScale(data.voicex) + 10],
                ])
              );
              // 横ライン
              hoverLinePath2.attr(
                'd',
                hoverLine([
                  [self.d3YMaxLength, self.yScale(data.voicex) + 10],
                  [
                    self.xScale(data.scagent) + self.d3YMaxLength - 10,
                    self.yScale(data.voicex) + 10,
                  ],
                ])
              );
            })
            .on('mouseout', function (v: any, i: number, nodes: any) {
              d3.select('#tooltip-bubble')
                .style('left', `${0}px`)
                .style('top', `${0}px`)
                .style('opacity', 0);
              hoverLinePath1.attr(
                'd',
                hoverLine([
                  [0, 0],
                  [0, 0],
                ])
              );
              hoverLinePath2.attr(
                'd',
                hoverLine([
                  [0, 0],
                  [0, 0],
                ])
              );
            });
          // 回帰直線
          let sx = 0;
          let sy = 0;
          let sxy = 0;
          let sxsq = 0;
          let xmean = 0;
          let ymean = 0;
          let alpha = 0;
          let beta = 0;
          let n = 0;
          sortBubbleDataArray.forEach(function (val: any) {
            sx += val.scagent;
            sy += val.voicex;
            sxy += val.scagent * val.voicex;
            sxsq += val.scagent ** 2;
          });
          n = sortBubbleDataArray.length;
          xmean = sx / n;
          ymean = sy / n;
          beta = (n * sxy - sx * sy) / (n * sxsq - sx ** 2); // 傾き
          alpha = ymean - beta * xmean;
          // 回帰式より、回帰直線描画用データを作成
          const regressionLinePlot: any[] = [];
          sortBubbleDataArray.forEach(function (val: any) {
            regressionLinePlot.push({ x: val.scagent, y: alpha + beta * val.scagent });
          });
          self.regressionLinePlot = regressionLinePlot.filter(function a(value) {
            return value.x >= 0 && value.y >= 0;
          });
          this.addGraph(self.width);
          const legendVals = ['月', '回帰直線'];
          const legend = d3
            .select('.legend')
            .append('svg')
            .style('padding-left', 0)
            .style('padding-top', 5)
            .attr('id', 'bubble-legend')
            .attr('viewBox', `0 0 ${840} ${20}`);
          // 隣のグラフとの高さ合わせ
          const legend2 = d3
            .select('.legend2')
            .append('svg')
            .attr('id', 'bubble-legend-2')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legendG = legend
            .selectAll('hoge')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function (_: any, i: number) {
              return `legend-${i}`;
            });
          const legendG0 = d3
            .select('.legend-0')
            .append('circle')
            .attr('cx', 10)
            .attr('cy', 10)
            .attr('r', 7)
            .attr('fill', '#FF72B8');
          const legendG1 = d3
            .select('.legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', -5)
            .attr('y', 6)
            .attr('width', 23)
            .attr('height', 8)
            .attr('fill', self.colorBlue);
          legendG
            .append('text')
            .attr('x', 20)
            .attr('y', 14)
            .attr('dy', '.15em')
            .text(function (d: any, i: number) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15)
            .style('font-family', 'Noto Sans JP');
          const padding = 20;
          legendG.attr('transform', function (d: any, i: number) {
            return `translate(${
              d3.sum(legendVals, function (e: any, j: number) {
                if (j < i) {
                  return legendG.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          // 追加分
          if (self.selectedPeriod === 'day') {
            self.labelNotes = '※1日当たりの平均稼働時間';
          } else if (self.selectedPeriod === 'week') {
            self.labelNotes = '※1週当たりの平均稼働時間';
          } else if (self.selectedPeriod === 'month') {
            self.labelNotes = '※1月当たりの平均稼働時間';
          } else if (self.selectedPeriod === 'year') {
            self.labelNotes = '※1年当たりの平均稼働時間';
          }
          legend
            .append('text')
            .attr('x', 670)
            .attr('y', 15)
            .attr('fill', 'grey')
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.labelNotes);
        } else {
          // bubbleDataArrayの中身は{ voicex: 0, scagent: 0 }
          const bubbleDataArray = [];
          for (let i = 0; i < self.SCAgentData.length; i += 1) {
            bubbleDataArray.push({
              indexNum: self.VoiceXData[i].indexNum,
              scagent: 0,
              voicex: 0,
            });
            // let maxNum = 0;
            for (let j = 0; j < self.SCAgentData[i].mainAndOther.length; j += 1) {
              bubbleDataArray[i].scagent += self.SCAgentData[i].mainAndOther[j].value;
            }
            bubbleDataArray[i].voicex += self.VoiceXData[i].aggregate.time;
          }
          const sortBubbleDataArray: any[] = bubbleDataArray.sort(function (a, b) {
            return a.scagent < b.scagent ? -1 : 1;
          });
          self.margin = { bottom: 30, left: 50, right: 20, top: 10 };
          // self.width = 853 - self.margin.left - self.margin.right;
          self.width = 853;
          // const height = 425;
          const height = 413;
          this.xScale = d3.scaleLinear();
          this.yScale = d3.scaleLinear().range([height - 55, 20]);
          // create a line
          this.line = d3.line();
          // Add the svg canvas
          this.svg = d3
            .select('.kkk')
            .append('svg')
            // .attr('height', height + margin.top + margin.bottom)
            // .attr('id', 'total-legend');
            .attr('viewBox', `0 0 ${self.width} ${height}`)
            .attr('id', 'total-bubble-and-line');
          this.svgG = this.svg
            .append('g')
            // .attr('transform', `translate(${margin.left},${margin.top})`);
            // 追加
            .attr('transform', `translate(${0},${0})`);
          // 追加
          // let d3YMax = self.voicexDataMax / 1000 / this.divisionNum;
          let d3YMax = self.voicexDataMax / 1000;
          self.y1Label = '';
          self.xLabel = '';
          if (d3YMax < 60) {
            self.y1Label = '(秒)電話利用状況';
          } else if (d3YMax >= 60 && d3YMax < 3600) {
            self.y1Label = '(分)電話利用状況';
            const callRem = Math.floor(d3YMax % 60) / 60;
            d3YMax = Math.floor((d3YMax % 3600) / 60) + callRem;
          } else if (d3YMax >= 3600) {
            self.y1Label = '(時間)電話利用状況';
            const callRem = Math.floor(d3YMax % 60) / 60 / 360;
            const callMin = Math.floor((d3YMax % 3600) / 60) / 60;
            d3YMax = Math.floor(d3YMax / 3600) + callMin + callRem;
          }
          // ここでsortBubbleArrayのvoicexの数字を加工してあげる
          for (let j = 0; j < sortBubbleDataArray.length; j += 1) {
            // const voicexNum = sortBubbleDataArray[j].voicex / 1000 / this.divisionNum;
            const voicexNum = sortBubbleDataArray[j].voicex / 1000;
            if (self.y1Label === '(秒)電話利用状況') {
              sortBubbleDataArray[j].voicex = voicexNum;
            } else if (self.y1Label === '(分)電話利用状況') {
              const callRem = Math.floor(voicexNum % 60) / 60;
              sortBubbleDataArray[j].voicex = Math.floor((voicexNum % 3600) / 60) + callRem;
            } else if (self.y1Label === '(時間)電話利用状況') {
              const callRem = Math.floor(voicexNum % 60) / 60 / 360;
              const callMin = Math.floor((voicexNum % 3600) / 60) / 60;
              sortBubbleDataArray[j].voicex = Math.floor(voicexNum / 3600) + callMin + callRem;
            }
          }
          // 追加分
          // Max時間の小数点繰り上げ
          // if (d3YMax > 1) {
          d3YMax = Math.ceil(d3YMax);
          // }
          if (d3YMax === 0) {
            d3YMax = 1;
          }
          // グラフの左側の軸の幅を取得
          self.d3YMaxLength = d3YMax.toString().length === 1 ? 2 : d3YMax.toString().length;
          self.d3YMaxLength = self.d3YMaxLength * 8 + 20;
          //
          // let d3XMax = (self.scagentDataMax * 30) / this.divisionNum;
          let d3XMax = self.scagentDataMax * 30;
          if (d3XMax < 60) {
            self.xLabel = '(秒)PC利用状況';
          } else if (d3XMax >= 60 && d3XMax < 3600) {
            self.xLabel = '(分)PC利用状況';
            const callRem = Math.floor(d3XMax % 60) / 60;
            d3XMax = Math.floor((d3XMax % 3600) / 60) + callRem;
          } else if (d3XMax >= 3600) {
            self.xLabel = '(時間)PC利用状況';
            const callRem = Math.floor(d3XMax % 60) / 60 / 360;
            const callMin = Math.floor((d3XMax % 3600) / 60) / 60;
            d3XMax = Math.floor(d3XMax / 3600) + callMin + callRem;
          }
          // ここでsortBubbleArrayのvoicexの数字を加工してあげる
          for (let j = 0; j < sortBubbleDataArray.length; j += 1) {
            // const scagentNum = (sortBubbleDataArray[j].scagent * 30) / this.divisionNum;
            const scagentNum = sortBubbleDataArray[j].scagent * 30;
            if (self.xLabel === '(秒)PC利用状況') {
              sortBubbleDataArray[j].scagent = scagentNum;
            } else if (self.xLabel === '(分)PC利用状況') {
              const callRem = Math.floor(scagentNum % 60) / 60;
              sortBubbleDataArray[j].scagent = Math.floor((scagentNum % 3600) / 60) + callRem;
            } else if (self.xLabel === '(時間)PC利用状況') {
              const callRem = Math.floor(scagentNum % 60) / 60 / 360;
              const callMin = Math.floor((scagentNum % 3600) / 60) / 60;
              sortBubbleDataArray[j].scagent = Math.floor(scagentNum / 3600) + callMin + callRem;
            }
          }
          // 追加分
          // Max時間の小数点繰り上げ
          if (d3XMax > 1) {
            d3XMax = Math.ceil(d3XMax);
          }
          // グラフの左側の軸の幅を取得
          // self.d3XMaxLength = d3XMax.toString().length === 1 ? 2 : d3XMax.toString().length;
          self.d3XMaxLength = d3XMax.toString().length;
          self.d3XMaxLength = self.d3XMaxLength * 8 + 15;
          // Define the axes
          this.xAxis = d3.axisBottom(self.xScale);
          this.yAxis = d3
            .axisLeft(self.yScale)
            .ticks(5)
            .tickSize(-self.width + self.d3YMaxLength + 20);
          this.xScale.domain([0, d3XMax]);
          this.yScale.domain([0, d3YMax]);
          // path追加
          this.svgPath = this.svgG
            .append('path')
            .data(sortBubbleDataArray)
            .attr('fill', 'none')
            .attr('stroke', self.colorBlue)
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3);
          // Add the X Axis
          this.xAxisEl = this.svgG
            .append('g')
            .attr('class', 'x axis bubble')
            // .attr('transform', `translate(0,${height - 10})`);
            .attr('transform', `translate(${self.d3YMaxLength},${height - 45})`);
          // hoverlinelabel
          // create a line
          const hoverLine = d3.line();
          hoverLine
            .x(function (d: any) {
              return d[0];
            })
            .y(function (d: any) {
              return d[1];
            });
          const hoverLinePath1 = this.svgG
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', 'currentColor')
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3)
            .style('stroke-dasharray', '3, 3');
          const hoverLinePath2 = this.svgG
            .append('path')
            .attr('fill', 'none')
            .attr('stroke', 'currentColor')
            .attr('stroke-miterlimit', 1)
            .attr('stroke-width', 3)
            .style('stroke-dasharray', '3, 3');
          // Add the Y Axis
          const yAxisEl = this.svgG.append('g').attr('class', 'y1 axis bubble').call(self.yAxis);
          // 追加
          yAxisEl.attr('transform', `translate(${self.d3YMaxLength}, 10)`);
          // y1 axis 枠線消す
          d3.select('.y1.axis.bubble').select('.domain').remove();
          //
          // 横線少しずらす
          d3.select('.y1.axis.bubble')
            .selectAll('.tick')
            .select('line')
            .attr('transform', 'translate(10,0)');
          // y1 axis color change
          d3.select('.y1.axis.bubble')
            .selectAll('.tick')
            .select('line')
            .style('stroke', self.colorGrey1);
          yAxisEl
            .append('text')
            .attr('x', -23)
            .attr('y', 5)
            .attr('fill', self.colorGrey2)
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.y1Label);
          // append text xlabel
          const xLabelText = this.svgG
            .append('text')
            .attr('x', 840)
            .attr('y', 400)
            .attr('fill', self.colorGrey2)
            .attr('text-anchor', 'end')
            .attr('font-size', '12px')
            .text(self.xLabel);
          this.svgGG = this.svgG
            .append('g')
            .selectAll('dot')
            .data(sortBubbleDataArray)
            .enter()
            .append('circle')
            .attr('cy', function (d: any) {
              // return self.yScale(d.voicex);
              return self.yScale(d.voicex) + 10;
            })
            .attr('r', function (d: any) {
              return 10;
            })
            .style('fill', '#FF72B8')
            .attr('stroke', 'black')
            .on('mouseover', function (this: any, event: any, data: any) {
              self.hoverDate = data.indexNum;
              self.hoverTimeVoicex = data.voicex;
              self.hoverTimeScagent = data.scagent;
              let positionLeft = 0;
              if (data.scagent > d3XMax * 0.7) {
                positionLeft = event.offsetX - 80;
              } else {
                positionLeft = event.offsetX - 50;
              }
              d3.select('#tooltip-bubble')
                .transition()
                .duration(0)
                // .style('left', `${event.offsetX - 50}px`)
                .style('left', `${positionLeft}px`)
                .style('top', `${event.offsetY - 50}px`)
                .style('opacity', 1);
              // 縦ライン
              hoverLinePath1.attr(
                'd',
                hoverLine([
                  [self.xScale(data.scagent) + self.d3YMaxLength, 370],
                  [self.xScale(data.scagent) + self.d3YMaxLength, self.yScale(data.voicex) + 10],
                ])
              );
              // 横ライン
              hoverLinePath2.attr(
                'd',
                hoverLine([
                  [self.d3YMaxLength, self.yScale(data.voicex) + 10],
                  [
                    self.xScale(data.scagent) + self.d3YMaxLength - 10,
                    self.yScale(data.voicex) + 10,
                  ],
                ])
              );
            })
            .on('mouseout', function (v: any, i: number, nodes: any) {
              d3.select('#tooltip-bubble')
                .style('left', `${0}px`)
                .style('top', `${0}px`)
                .style('opacity', 0);
              hoverLinePath1.attr(
                'd',
                hoverLine([
                  [0, 0],
                  [0, 0],
                ])
              );
              hoverLinePath2.attr(
                'd',
                hoverLine([
                  [0, 0],
                  [0, 0],
                ])
              );
            });
          // 回帰直線
          let sx = 0;
          let sy = 0;
          let sxy = 0;
          let sxsq = 0;
          let xmean = 0;
          let ymean = 0;
          let alpha = 0;
          let beta = 0;
          let n = 0;
          sortBubbleDataArray.forEach(function (val: any) {
            sx += val.scagent;
            sy += val.voicex;
            sxy += val.scagent * val.voicex;
            sxsq += val.scagent ** 2;
          });
          n = sortBubbleDataArray.length;
          xmean = sx / n;
          ymean = sy / n;
          beta = (n * sxy - sx * sy) / (n * sxsq - sx ** 2); // 傾き
          alpha = ymean - beta * xmean;
          // 回帰式より、回帰直線描画用データを作成
          const regressionLinePlot: any[] = [];
          sortBubbleDataArray.forEach(function (val: any) {
            regressionLinePlot.push({ x: val.scagent, y: alpha + beta * val.scagent });
          });
          self.regressionLinePlot = regressionLinePlot.filter(function (value) {
            return value.x >= 0 && value.y >= 0;
          });
          this.addGraph(self.width);
          const legendVals = ['月', '回帰直線'];
          const legend = d3
            .select('.legend')
            .append('svg')
            .style('padding-left', 0)
            .style('padding-top', 5)
            .attr('id', 'bubble-legend')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legend2 = d3
            .select('.legend2')
            .append('svg')
            .attr('id', 'bubble-legend-2')
            .attr('viewBox', `0 0 ${840} ${20}`);
          const legendG = legend
            .selectAll('hoge')
            .data(legendVals)
            .enter()
            .append('g')
            .attr('class', function (_: any, i: number) {
              return `legend-${i}`;
            });
          const legendG0 = d3
            .select('.legend-0')
            .append('circle')
            .attr('cx', 10)
            .attr('cy', 10)
            .attr('r', 7)
            .attr('fill', '#FF72B8');
          const legendG1 = d3
            .select('.legend-1')
            .append('rect')
            .attr('rx', 6)
            .attr('ry', 6)
            .attr('x', -5)
            .attr('y', 6)
            .attr('width', 23)
            .attr('height', 8)
            .attr('fill', self.colorBlue);
          legendG
            .append('text')
            .attr('x', 20)
            .attr('y', 14)
            .attr('dy', '.15em')
            .text(function fn(d: any, i: number) {
              return d;
            })
            .attr('class', 'textselected')
            .style('text-anchor', 'start')
            .style('font-size', 15)
            .style('font-family', 'Noto Sans JP');
          const padding = 20;
          legendG.attr('transform', function fn(d: any, i: number) {
            return `translate(${
              d3.sum(legendVals, function fn1(e: any, j: number) {
                if (j < i) {
                  return legendG.nodes()[j].getBBox().width;
                }
                return 0;
              }) +
              padding * i
            },0)`;
          });
          // 追加分
          if (self.selectedPeriod === 'day') {
            self.labelNotes = '※1日当たりの稼働時間';
          } else if (self.selectedPeriod === 'week') {
            self.labelNotes = '※1週当たりの稼働時間';
          } else if (self.selectedPeriod === 'month') {
            self.labelNotes = '※1月当たりの稼働時間';
          } else if (self.selectedPeriod === 'year') {
            self.labelNotes = '※1年当たりの稼働時間';
          }
          legend
            .append('text')
            .attr('x', 670)
            .attr('y', 15)
            .attr('fill', 'grey')
            .attr('text-anchor', 'start')
            .attr('font-size', '12px')
            .text(self.labelNotes);
        }
      }
      this.setNavigating({ navigating: false });
    },
  },
});
