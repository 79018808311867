











import * as d3 from 'd3';
import svgz from 'svg-z-order';
import Vue, { PropType } from 'vue';

type GraphData = {
  aggregates: {
    appliCount: number;
    appliName: string;
    category: string;
    keyCount: number;
    userIdNum?: string;
    departmentId?: string;
    projectId?: string;
  }[];
  indexNum: number;
  totalNum: number;
  userId: string;
  image?: string;
  departmentId?: string;
  departmentName?: string;
  projectId?: string;
  projectName?: string;
};

type GraphDataAggregates = {
  appliCount: number;
  appliName: string;
  category: string;
  keyCount: number;
  userIdNum?: string;
  departmentId?: string;
  projectId?: string;
};

export default Vue.extend({
  name: 'MainAppHorizontalBarGraph',
  props: {
    mainappArray: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    // mainappObj: {
    //   type: Object,
    //   default: () => ({}),
    // },
    mainappUsageAppHorizontalData: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    mainappUsageAppHorizontalDataMax: {
      type: Number,
      default: 0,
    },
    selectedTarget: {
      type: Object,
      default: () => ({
        gFlg: 0,
      }),
    },
    selectedUserLists: {
      type: Array,
      default: () => [],
    },
    // mainappUsageAppHorizontalWidth: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data(): {
    stackKey: string[];
    svg: any;
    svgLegend: any;
    margin: {
      top: number;
      right: number;
      bottom: number;
      left: number;
    };
    xScale: any;
    yScale: any;
    color: any;
    // xAxis: any;
    yAxis: any;
    svgG: any;
    // svgGG: any;
    stack: any;
    layers: any;
    defaultWidth: number;
    layer: any;
    layerPath: any;
    mainAppHorizontalData: GraphData[];
    mainAppHorizontalDataFlg: boolean;
    // mainAppHorizontalDataMax: number;
    widthGraph: number;
    widthNum: number;
    userIdArray: string[];
    mainAppChartArray: {
      key: string;
      value: number;
    }[];
    hoverTime: number;
    projectIdArray: (string | undefined)[];
    departmentIdArray: (string | undefined)[];
    hoverGroupName: string | undefined;
    // mainAppChartObj: any;
  } {
    return {
      color: null,
      defaultWidth: 850,
      departmentIdArray: [],
      hoverGroupName: '',
      hoverTime: 0,
      layer: null,
      layerPath: null,
      layers: null,
      mainAppChartArray: [],
      // mainAppChartObj: {},
      mainAppHorizontalData: [],
      mainAppHorizontalDataFlg: false,
      // mainAppHorizontalDataMax: 0,
      margin: { bottom: 30, left: 50, right: 20, top: 20 },
      projectIdArray: [],
      stack: null,
      stackKey: ['wounds', 'other', 'disease'],
      svg: null,
      svgG: null,
      // svgGG: null,
      svgLegend: null,
      userIdArray: [],
      widthGraph: 863,
      widthNum: 0,
      // xAxis: null,
      xScale: null,
      yAxis: null,
      yScale: null,
    };
  },
  computed: {
    returnAppliTime() {
      return function fn(time: number) {
        const callTime = time * 30;
        const callHour = Math.floor(callTime / 3600);
        const callMin = Math.floor((callTime % 3600) / 60);
        const callRem = Math.floor(callTime % 60);
        if (callHour > 0) {
          return `${callHour}時間 ${callMin}分`;
        }
        if (callHour === 0 && callMin > 0) {
          return `${callMin}分 ${callRem}秒`;
        }
        return `${callTime}秒`;
      };
    },
  },
  watch: {
    mainappArray(newValue) {
      if (newValue) {
        this.mainAppChartArray = newValue;
      }
    },
    // mainAppArray: {
    //   handler(newValue) {
    //     if (newValue) {
    //       this.mainAppChartArray = newValue;
    //     }
    //   },
    //   deep: true,
    // },
    mainappUsageAppHorizontalData(newValue) {
      if (newValue) {
        this.mainAppHorizontalData = newValue;
        this.mainAppHorizontalDataFlg = true;
        this.drawGraph2();
      }
    },
    // mainappUsageAppHorizontalDataMax(newValue) {
    //   if (newValue) {
    //     this.mainAppHorizontalDataMax = newValue;
    //     this.drawGraph2();
    //   }
    // },
    selectedTarget: {
      handler(newValue) {
        if (newValue) {
          this.drawGraph2();
        }
      },
      deep: true,
    },
    // mainappUsageAppHorizontalWidth(newValue) {
    //   if (newValue) {
    //     this.widthGraph = newValue;
    //     this.addGraph2();
    //   }
    // },
  },
  mounted() {
    // this.drawGraph2();
  },
  methods: {
    drawGraph2() {
      const self = this;
      if (self.svg) {
        d3.select('#main-app-horizontal-bar').remove();
        d3.select('#main-app-horizontal-bar-legend').remove();
      }
      if (
        self.mainAppHorizontalData &&
        self.mainappUsageAppHorizontalDataMax > 0 &&
        self.widthGraph > 0
      ) {
        const width = self.defaultWidth - self.margin.left - self.margin.right;
        const height = self.mainAppHorizontalData.length * 75;
        // self.mainAppHorizontalData.length * 75 - self.margin.top - self.margin.bottom;
        self.color = d3
          .scaleOrdinal()
          .range(['#F8BCE6', '#B5D6FF', '#CCEDFF', '#E1DDFA', '#F1F1F2']);
        self.yScale = d3.scaleBand().range([height, 0]).padding(0.1);
        self.yAxis = d3.axisLeft<Date>(self.yScale).tickFormat(d3.timeFormat('%b'));
        // self.xScale = d3
        //   .scaleLinear()
        //   .rangeRound([0, width])
        //   // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 4]);
        //   .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 1.3]);
        self.svg = d3
          .select('.ggg')
          .append('svg')
          .attr('id', 'main-app-horizontal-bar')
          .attr('viewBox', `0 0 ${width} ${height}`)
          .on('mousemove', function fn(this: any) {
            d3.select('#tooltip-mainapp').style('opacity', 0);
            d3.select('#tooltip-mainapp-name').style('opacity', 0);
          });
        // legend用svg
        this.svgLegend = d3
          .select('#main-horizontal-bar-legend')
          .append('svg')
          .attr('id', 'main-app-horizontal-bar-legend')
          .style('padding-left', 15)
          .style('padding-top', 10);
        // legend用g
        const legend = this.svgLegend
          .selectAll('legend')
          .data(self.mainappArray)
          .enter()
          .append('g');
        legend
          .append('rect')
          .attr('width', 20)
          .attr('height', 14)
          .attr('y', 1)
          .style(
            'fill',
            function fn(d: { key: string; value: number; otherFlg?: boolean }, i: number) {
              if (d.otherFlg === true) {
                return '#F1F1F2';
              }
              const number: string = i.toString();
              return self.color(number);
            }
          );
        // legentText
        legend
          .append('text')
          .attr('x', 23)
          .attr('y', 14)
          // .attr('dy', '.15em')
          .attr('font-size', '15px')
          .text(function fn(d: { key: string; value: number; otherFlg?: boolean }) {
            if (d.otherFlg === true) {
              return 'その他';
            }
            return d.key;
          });
        // width array
        const widthArray: number[] = [];
        for (let i = 0; i < self.mainappArray.length; i += 1) {
          widthArray.push(legend.nodes()[i].getBBox().width);
        }
        const parentWidth = 853 - 20;
        let legendWidth = 0;
        let legendy = 0;
        let legentHeight = 1;
        legend.attr('transform', function fn(_: undefined, i: number) {
          if (i === 0) {
            return 'translate(0, 0)';
          }
          legendWidth += widthArray[i - 1] + 10;
          if (parentWidth < legendWidth || parentWidth < legendWidth + widthArray[i]) {
            legendy += 20;
            legendWidth = 0;
            legentHeight += 1;
          }
          return `translate(${legendWidth},${legendy})`;
        });
        this.svgLegend
          .attr('width', '100%')
          .attr('height', '100%')
          .attr('viewBox', `0 0 ${853} ${legentHeight * 20}`);
        // .attr('viewBox', `0 0 ${width} ${legentHeight * 20}`);
        self.svgG = self.svg.append('g').attr(
          'transform',
          // `translate(${self.margin.left}, ${self.mainAppHorizontalData.length * 20 + 10})`
          `translate(${self.margin.left}, ${40})`
        );
        // これ使ってなくね
        self.stack = d3.stack().keys(self.stackKey).offset(d3.stackOffsetNone);
        self.layers = self.stack(self.mainAppHorizontalData);
        //
        if (self.selectedTarget.selectedId !== 'none') {
          self.xScale = d3
            .scaleLinear()
            .rangeRound([0, width])
            // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 4]);
            .domain([
              0,
              self.mainappUsageAppHorizontalDataMax + self.mainappUsageAppHorizontalDataMax / 4,
            ]);
          self.userIdArray = self.mainAppHorizontalData
            .map(function fn(value) {
              return value.userId;
            })
            .reverse();
          self.yScale.domain(self.userIdArray);
          self.layer = self.svgG
            .selectAll('.layer')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('g')
            .attr('class', 'layer');
          self.layerPath = self.layer
            .selectAll('path')
            .data(function fn(d: GraphData) {
              return d.aggregates;
            })
            .enter()
            .append('path')
            .attr('class', function fn(d: GraphDataAggregates, i: number) {
              return `horizontal-bar-${d.userIdNum}-${i}`;
            })
            .attr('y', function fn(d: GraphDataAggregates) {
              return self.yScale(d.userIdNum);
            })
            .attr('fill', function fn(d: GraphDataAggregates) {
              const index = self.mainAppChartArray.findIndex(function fn1(element) {
                return element.key === d.appliName;
              });
              // '#F8BCE6', '#B5D6FF', '#CCEDFF', '#E1DDFA', '#F1F1F2'
              if (index === 0) {
                return '#F8BCE6';
              }
              if (index === 1) {
                return '#B5D6FF';
              }
              if (index === 2) {
                return '#CCEDFF';
              }
              if (index === 3) {
                return '#E1DDFA';
              }
              if (index === 4) {
                return '#F1F1F2';
              }
              if (index === -1) {
                return '#F1F1F2';
              }
              return '#F1F1F2';
            })
            .attr('d', function fn(d: GraphDataAggregates, i: number) {
              if (i === 0) {
                self.widthNum = 0;
              }
              const mathParam = self.yScale(d.userIdNum);
              self.widthNum += self.xScale(d.appliCount);
              let radius = 9;
              if (self.widthNum < 9) {
                radius = self.widthNum * 0.8;
              }
              return self.rightRoundedRect(
                10,
                Math.floor(mathParam) - 30,
                self.widthNum,
                20,
                radius
              );
            })
            .on('mousemove', function fn(this: any, event: any, data: GraphDataAggregates) {
              d3.select(this).style('opacity', 0.8);
              d3.select('#tooltip-mainapp')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX - 40}px`)
                .style('top', `${event.offsetY - 0}px`)
                .style('opacity', 1);
              self.hoverTime = data.appliCount;
            })
            .on('mouseout', function fn(this: any) {
              d3.select(this).style('opacity', 1);
              d3.select('#tooltip-mainapp').style('opacity', 0);
            });
          // pathの順番を入れ替え
          for (let i = 0; i < self.userIdArray.length; i += 1) {
            for (let j = 1; j < 5; j += 1) {
              // if (d3.select(`.horizontal-bar-${self.userIdArray[i]}-${j}`)) {
              if (document.querySelector(`.horizontal-bar-${self.userIdArray[i]}-${j}`)) {
                const hoge = d3.select(`.horizontal-bar-${self.userIdArray[i]}-${j}`);
                svgz.element(hoge.node()).toBottom();
              }
            }
          }
          // text貼り付け
          self.svgG
            .selectAll('text')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('text')
            .attr('x', function fn(d: GraphData) {
              return self.xScale(d.totalNum) + 10;
            })
            .attr('y', function fn(d: GraphData) {
              return self.yScale(d.userId) - 15;
            })
            .attr('height', self.yScale.bandwidth())
            .text(function fn(d: GraphData) {
              if (d.totalNum > 0) {
                const time = d.totalNum * 30;
                const hour = Math.floor(time / 3600);
                const min = Math.floor((time % 3600) / 60);
                return `${hour}時間${min}分`;
              }
              if (d.totalNum === 0) {
                return '';
              }
              return '';
            });
          // y軸を設定し、画像も表示
          self.svgG
            .append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(0,0)')
            .call(self.yAxis);
          self.svgG.select('.axis--y').selectAll('text').remove();
          self.svgG.select('.axis--y').selectAll('path').remove();
          self.svgG.select('.axis--y').selectAll('line').remove();
          // img add
          const reverseData = JSON.parse(JSON.stringify(self.mainAppHorizontalData)).reverse();
          // axisY
          self.svgG
            .select('.axis--y')
            .selectAll('.tick')
            .data(reverseData)
            .append('defs')
            .append('clipPath')
            .attr('id', function fn(d: GraphData) {
              return `clippath${d.userId}`;
            })
            .append('rect')
            .attr('width', 40)
            .attr('height', 40)
            .attr('x', -40)
            .attr('y', -72)
            .attr('rx', 20);
          // img add2
          self.svgG
            .select('.axis--y')
            .selectAll('.tick')
            .data(reverseData)
            .append(function fn(this: any, d: GraphData) {
              if (d.image) {
                return document.createElementNS('http://www.w3.org/2000/svg', 'svg:image');
              }
              // 画像がなかった場合
              // サークルを生成
              d3.select(this)
                .append('circle')
                .attr('cx', -20)
                .attr('cy', -55)
                .attr('r', 20)
                .attr('fill', 'white')
                .attr('stroke', 'black')
                .attr('stroke-width', 1);
              // 名前を表示する要素を追加
              const element = document.createElementNS(
                'http://www.w3.org/2000/svg',
                'foreignObject'
              );
              const element1 = document.createElement('body');
              element1.setAttribute('xlmns', 'http://www.w3.org/2000/svg');
              element1.setAttribute('style', 'text-align: center');
              const element2 = document.createElement('div');
              element2.setAttribute('style', 'width: 40px; font-size: 12px;');
              element2.innerHTML = self.getUserName(d.userId);
              element1.append(element2);
              element.appendChild(element1);
              return element;
            })
            .attr('xlink:href', function fn(d: GraphData) {
              if (d.image) {
                return d.image;
              }
              return '';
            })
            .attr('clip-path', function fn(d: GraphData) {
              if (d.image) {
                return `url(#clippath${d.userId})`;
              }
              return '';
            })
            .attr('width', function fn(d: GraphData) {
              if (d.image) {
                return 40;
              }
              return 40;
            })
            .attr('height', function fn(d: GraphData) {
              if (d.image) {
                return 40;
              }
              return 40;
            })
            .attr('x', function fn(d: GraphData) {
              if (d.image) {
                return -40;
              }
              return -40;
            })
            .attr('y', function fn(d: GraphData) {
              if (d.image) {
                return -72;
              }
              return -75;
            });
        } else if (self.selectedTarget.selectedId === 'none' && self.selectedTarget.gFlg === 0) {
          self.xScale = d3
            .scaleLinear()
            .rangeRound([0, width])
            // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 4]);
            // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 1.3]);
            .domain([
              0,
              self.mainappUsageAppHorizontalDataMax + self.mainappUsageAppHorizontalDataMax / 1.3,
            ]);
          self.projectIdArray = self.mainAppHorizontalData
            .map(function fn(value) {
              return value.projectId;
            })
            .reverse();
          self.yScale.domain(self.projectIdArray);
          self.layer = self.svgG
            .selectAll('.layer')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('g')
            .attr('class', 'layer');
          self.layerPath = self.layer
            .selectAll('path')
            .data(function fn(d: GraphData) {
              return d.aggregates;
            })
            .enter()
            .append('path')
            .attr('class', function fn(d: GraphDataAggregates, i: number) {
              return `horizontal-bar-${d.projectId}-${i}`;
            })
            .attr('y', function fn(d: GraphDataAggregates) {
              return self.yScale(d.projectId);
            })
            .attr('fill', function fn(d: GraphDataAggregates) {
              const index = self.mainAppChartArray.findIndex(function fn1(element) {
                return element.key === d.appliName;
              });
              if (index === 0) {
                return '#F8BCE6';
              }
              if (index === 1) {
                return '#B5D6FF';
              }
              if (index === 2) {
                return '#CCEDFF';
              }
              if (index === 3) {
                return '#E1DDFA';
              }
              if (index === 4) {
                return '#F1F1F2';
              }
              if (index === -1) {
                return '#F1F1F2';
              }
              return '#F1F1F2';
            })
            .attr('d', function fn(d: GraphDataAggregates, i: number) {
              if (i === 0) {
                self.widthNum = 0;
              }
              const mathParam = self.yScale(d.projectId);
              self.widthNum += self.xScale(d.appliCount);
              let radius = 9;
              if (self.widthNum < 9) {
                radius = self.widthNum * 0.8;
              }
              return self.rightRoundedRect(
                // 10,
                150,
                Math.floor(mathParam) - 30,
                self.widthNum,
                20,
                radius
              );
            })
            .on('mousemove', function fn(this: any, event: any, data: GraphDataAggregates) {
              d3.select(this).style('opacity', 0.8);
              d3.select('#tooltip-mainapp')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX - 40}px`)
                .style('top', `${event.offsetY - 20}px`)
                .style('opacity', 1);
              self.hoverTime = data.appliCount;
            })
            .on('mouseout', function fn(this: any) {
              d3.select(this).style('opacity', 1);
              d3.select('#tooltip-mainapp').style('opacity', 0);
            });
          // pathの順番を入れ替え
          for (let i = 0; i < self.projectIdArray.length; i += 1) {
            for (let j = 1; j < 5; j += 1) {
              if (document.querySelector(`.horizontal-bar-${self.projectIdArray[i]}-${j}`)) {
                const hoge = d3.select(`.horizontal-bar-${self.projectIdArray[i]}-${j}`);
                svgz.element(hoge.node()).toBottom();
              }
            }
          }
          // text貼り付け
          self.svgG
            .selectAll('text')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('text')
            .attr('x', function fn(d: GraphData) {
              return self.xScale(d.totalNum) + 150;
            })
            .attr('y', function fn(d: GraphData) {
              // return self.yScale(d.userId) - 15;
              return self.yScale(d.projectId) - 15;
            })
            .attr('height', self.yScale.bandwidth())
            .text(function fn(d: GraphData) {
              if (d.totalNum > 0) {
                const time = d.totalNum * 30;
                const hour = Math.floor(time / 3600);
                const min = Math.floor((time % 3600) / 60);
                return `${hour}時間${min}分`;
              }
              if (d.totalNum === 0) {
                return '';
              }
              return '';
            });
          // y軸を設定し、画像も表示
          self.svgG
            .append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(0,0)')
            .call(self.yAxis);
          self.svgG.select('.axis--y').selectAll('text').remove();
          self.svgG.select('.axis--y').selectAll('path').remove();
          self.svgG.select('.axis--y').selectAll('line').remove();
          // img add
          const reverseData = JSON.parse(JSON.stringify(self.mainAppHorizontalData)).reverse();
          // 部署名とか表示するバージョン axisY
          self.svgG
            .select('.axis--y')
            .selectAll('.tick')
            .data(reverseData)
            .append('foreignObject')
            .attr('x', -30)
            .attr('y', -62)
            .attr('width', '180')
            .attr('height', '20')
            .append('xhtml:body')
            .attr('style', 'text-align: center;')
            .append('div')
            .attr('id', function fn(d: GraphData) {
              return `axis-y-label-parent-${d.indexNum}`;
            })
            .on('mouseover', function fn(this: any, event: any, data: GraphData) {
              let parentWidth1: any = d3.select(`#axis-y-label-parent-${data.indexNum}`).node();
              const parentBoundingClient = parentWidth1.getBoundingClientRect();
              parentWidth1 = parentBoundingClient.width;
              let width1: any = d3.select(`.axis-y-label-${data.indexNum}`).node();
              const widthBoundingClient = width1.getBoundingClientRect();
              width1 = widthBoundingClient.width;
              if (parentWidth1 < width1) {
                const parentBoundingClientHeight = parentBoundingClient.height;
                d3.select('#tooltip-mainapp-name')
                  .transition()
                  .duration(0)
                  .style('left', `${self.xScale(0) + 2}px`)
                  .style('top', function fn1() {
                    const defaultFirstNum = 40;
                    const defaultNextNum = 82;
                    const defaultHeight = 17.83;
                    const setNum = parentBoundingClientHeight / defaultHeight;
                    const setFirstNum = defaultFirstNum * setNum;
                    const setNextNum = defaultNextNum * setNum;
                    if (data.indexNum === 0) {
                      return `${setFirstNum}px`;
                    }
                    return `${setFirstNum + setNextNum * data.indexNum}px`;
                  })
                  .style('opacity', 1);
                self.hoverGroupName = data.projectName;
              }
            })
            .on('mouseout', function fn(this: any, event: any) {
              d3.select('#tooltip-mainapp-name').style('opacity', 0);
            })
            .attr('style', 'font-size: 16px; width: 190px; white-space: nowrap')
            .append('span')
            .attr('class', function fn(d: GraphData) {
              return `axis-y-label-${d.indexNum}`;
            })
            .text(function fn(d: GraphData) {
              return `${d.projectName}`;
            });
        } else if (self.selectedTarget.selectedId === 'none' && self.selectedTarget.gFlg === 1) {
          self.xScale = d3
            .scaleLinear()
            .rangeRound([0, width])
            // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 4]);
            // .domain([0, self.mainAppHorizontalDataMax + self.mainAppHorizontalDataMax / 1.3]);
            .domain([
              0,
              self.mainappUsageAppHorizontalDataMax + self.mainappUsageAppHorizontalDataMax / 1.3,
            ]);
          self.departmentIdArray = self.mainAppHorizontalData
            .map(function fn(value) {
              return value.departmentId;
            })
            .reverse();
          self.yScale.domain(self.departmentIdArray);
          self.layer = self.svgG
            .selectAll('.layer')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('g')
            .attr('class', 'layer');
          self.layerPath = self.layer
            .selectAll('path')
            .data(function fn(d: GraphData) {
              return d.aggregates;
            })
            .enter()
            .append('path')
            .attr('class', function fn(d: GraphDataAggregates, i: number) {
              return `horizontal-bar-${d.departmentId}-${i}`;
            })
            .attr('y', function fn(d: GraphDataAggregates) {
              return self.yScale(d.departmentId);
            })
            .attr('fill', function fn(d: GraphDataAggregates) {
              const index = self.mainAppChartArray.findIndex(function fn1(element) {
                return element.key === d.appliName;
              });
              if (index === 0) {
                return '#F8BCE6';
              }
              if (index === 1) {
                return '#B5D6FF';
              }
              if (index === 2) {
                return '#CCEDFF';
              }
              if (index === 3) {
                return '#E1DDFA';
              }
              if (index === 4) {
                return '#F1F1F2';
              }
              if (index === -1) {
                return '#F1F1F2';
              }
              return '#F1F1F2';
            })
            .attr('d', function fn(d: GraphDataAggregates, i: number) {
              if (i === 0) {
                self.widthNum = 0;
              }
              const mathParam = self.yScale(d.departmentId);
              self.widthNum += self.xScale(d.appliCount);
              let radius = 9;
              if (self.widthNum < 9) {
                radius = self.widthNum * 0.8;
              }
              return self.rightRoundedRect(
                150,
                Math.floor(mathParam) - 30,
                self.widthNum,
                20,
                radius
              );
            })
            .on('mousemove', function fn(this: any, event: any, data: GraphDataAggregates) {
              d3.select(this).style('opacity', 0.8);
              d3.select('#tooltip-mainapp')
                .transition()
                .duration(0)
                .style('left', `${event.offsetX - 40}px`)
                .style('top', `${event.offsetY - 20}px`)
                .style('opacity', 1);
              self.hoverTime = data.appliCount;
            })
            .on('mouseout', function fn(this: any) {
              d3.select(this).style('opacity', 1);
              d3.select('#tooltip-mainapp').style('opacity', 0);
            });
          // pathの順番を入れ替え
          for (let i = 0; i < self.departmentIdArray.length; i += 1) {
            for (let j = 1; j < 5; j += 1) {
              if (document.querySelector(`.horizontal-bar-${self.departmentIdArray[i]}-${j}`)) {
                const hoge = d3.select(`.horizontal-bar-${self.departmentIdArray[i]}-${j}`);
                svgz.element(hoge.node()).toBottom();
              }
            }
          }
          // text貼り付け
          self.svgG
            .selectAll('text')
            .data(self.mainAppHorizontalData)
            .enter()
            .append('text')
            .attr('x', function fn(d: GraphData) {
              return self.xScale(d.totalNum) + 150;
            })
            .attr('y', function fn(d: GraphData) {
              return self.yScale(d.departmentId) - 15;
            })
            .attr('height', self.yScale.bandwidth())
            .text(function fn(d: GraphData) {
              if (d.totalNum > 0) {
                const time = d.totalNum * 30;
                const hour = Math.floor(time / 3600);
                const min = Math.floor((time % 3600) / 60);
                return `${hour}時間${min}分`;
              }
              if (d.totalNum === 0) {
                return '';
              }
              return '';
            });
          // y軸を設定し、画像も表示
          self.svgG
            .append('g')
            .attr('class', 'axis axis--y')
            .attr('transform', 'translate(0,0)')
            .call(self.yAxis);
          self.svgG.select('.axis--y').selectAll('text').remove();
          self.svgG.select('.axis--y').selectAll('path').remove();
          self.svgG.select('.axis--y').selectAll('line').remove();
          // img add
          const reverseData = JSON.parse(JSON.stringify(self.mainAppHorizontalData)).reverse();
          // 部署名とか表示するバージョン asixY
          self.svgG
            .select('.axis--y')
            .selectAll('.tick')
            .data(reverseData)
            .append('foreignObject')
            .attr('x', -30)
            .attr('y', -62)
            .attr('width', '180')
            .attr('height', '20')
            .append('xhtml:body')
            .attr('style', 'text-align: center;')
            .append('div')
            .attr('id', function fn(d: GraphData) {
              return `axis-y-label-parent-${d.indexNum}`;
            })
            .on('mouseover', function fn(this: any, event: any, data: GraphData) {
              let parentWidth1: any = d3.select(`#axis-y-label-parent-${data.indexNum}`).node();
              const parentBoundingClient = parentWidth1.getBoundingClientRect();
              parentWidth1 = parentBoundingClient.width;
              let width1: any = d3.select(`.axis-y-label-${data.indexNum}`).node();
              const widthBoundingClient = width1.getBoundingClientRect();
              width1 = widthBoundingClient.width;
              if (parentWidth1 < width1) {
                const parentBoundingClientHeight = parentBoundingClient.height;
                d3.select('#tooltip-mainapp-name')
                  .transition()
                  .duration(0)
                  .style('left', `${self.xScale(0) + 2}px`)
                  .style('top', function fn1() {
                    const defaultFirstNum = 40;
                    const defaultNextNum = 82;
                    const defaultHeight = 17.83;
                    const setNum = parentBoundingClientHeight / defaultHeight;
                    const setFirstNum = defaultFirstNum * setNum;
                    const setNextNum = defaultNextNum * setNum;
                    if (data.indexNum === 0) {
                      return `${setFirstNum}px`;
                    }
                    return `${setFirstNum + setNextNum * data.indexNum}px`;
                  })
                  .style('opacity', 1);
                self.hoverGroupName = data.departmentName;
              }
            })
            .on('mouseout', function fn(this: any) {
              d3.select('#tooltip-mainapp-name').style('opacity', 0);
            })
            .attr('style', 'font-size: 16px; width: 190px; white-space: nowrap')
            .append('span')
            .attr('class', function fn(d: GraphData) {
              return `axis-y-label-${d.indexNum}`;
            })
            .text(function fn(d: GraphData) {
              return `${d.departmentName}`;
            });
        }
        self.mainAppHorizontalDataFlg = false;
      }
    },
    getUserName(userId: string) {
      try {
        const user: any = this.selectedUserLists.find((element: any) => element.userId === userId);
        let returnResult = user.userName;
        returnResult = returnResult.replaceAll(/\s+/g, '').substr(0, 2);
        return returnResult;
      } catch (error) {
        return '削除';
      }
    },
    rightRoundedRect(x: any, y: any, width: any, height: any, radius: any) {
      return `M ${x},${y}
                h ${width - radius}
                q ${radius},0 ${radius},${radius}
                v ${height - 2 * radius}
                q 0,${radius} -${radius},${radius}
                h -${width - radius}
                z`;
    },
  },
});
